import { Deserializer } from "@/core/shared/api/domain/serializers";
import { Accreditation } from "@/core/accreditation/domain/accreditation";
import {
  accreditationDeserializer,
  AccreditationDto,
} from "@/core/accreditation/infrastructure/accreditationDeserializer";

export interface AccreditationsDto {
  credentials: AccreditationDto[];
}

export const accreditationsDeserializer: Deserializer<Accreditation[], AccreditationsDto> = {
  parse: accreditationsDto =>
    accreditationsDto.credentials
      .filter(accreditationDto => ["qr", "embedding"].includes(accreditationDto.credential_type))
      .map(accreditationDto => accreditationDeserializer.parse(accreditationDto)),
};
