import { Module } from "vuex";
import state, { UserSearchState } from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import RootState from "@/legacy/store/rootState";

const portalModule: Module<UserSearchState, RootState> = {
  actions,
  state,
  getters,
  mutations,
};

export default portalModule;
