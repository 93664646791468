import Vue from "vue";
import { Dictionary } from "vue-router/types/router";
import router, { getOnboardingRoute, RouteNames } from "@/ui/_router";
import { createPortrayal } from "../onboarding/models/identity-portrayal.models";
import { startOnboarding } from "@/legacy/features/onboarding/models/onboarding.models";

export async function redirectUser() {
  const enrolled = Vue.prototype.$appStore.getters.userEnrolled;
  if (Vue.prototype.$container.authRepository.get()?.claims) {
    if (enrolled) {
      router.push({ name: RouteNames.MyAccreditations });
    } else {
      router.push({ name: RouteNames.Landing });
    }
    return;
  }

  if (Vue.prototype.$appStore.getters.isUser && !enrolled) {
    await startOnboarding();
    if (!Vue.prototype.$appStore.getters.identityProofing) {
      const userId = Vue.prototype.$appStore.getters.userId;
      await createPortrayal("none", "xx", userId);
    }
    goToOnboarding();
  } else if (Vue.prototype.$appStore.getters.isVisitor && enrolled) {
    router.push({ name: RouteNames.MyVisits });
  } else if (Vue.prototype.$appStore.getters.isUser && enrolled) {
    router.push({ name: RouteNames.UserHome });
  } else {
    router.push({ name: RouteNames.PortalManagement });
  }
}

export const goToOnboarding = (query?: Dictionary<string | (string | null)[]>) => {
  router.push({ name: getOnboardingRoute(), ...(query ? { query } : {}) });
};
