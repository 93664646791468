export enum AccreditationType {
  Qr = "qr",
  Face = "face",
  Proof = "proof", //pdf
}

interface BaseAccreditation {
  id: string;
}

export interface QrAccreditation extends BaseAccreditation {
  type: AccreditationType.Qr;
  startsAt: Date;
  endsAt: Date;
}

export interface FaceAccreditation extends BaseAccreditation {
  type: AccreditationType.Face;
  createdAt: Date;
}

export interface ProofAccreditation extends BaseAccreditation {
  type: AccreditationType.Proof;
}

export type Accreditation<Type extends AccreditationType = AccreditationType> = {
  [AccreditationType.Qr]: QrAccreditation;
  [AccreditationType.Face]: FaceAccreditation;
  [AccreditationType.Proof]: ProofAccreditation;
}[Type];

export interface AccreditationRequest<Type extends AccreditationType = AccreditationType> {
  userId: string;
  location: string;
  type: Type;
  portrayalId?: string;
}

export const isAccreditationValid = <Type extends AccreditationType>(accreditation: Accreditation<Type>) =>
  validators[accreditation.type as Type](accreditation);
const validators: { [Type in AccreditationType]: (accreditation: Accreditation<Type>) => boolean } = {
  [AccreditationType.Qr]: (qrAccreditation: QrAccreditation) => {
    const now = new Date();
    return qrAccreditation.endsAt > now;
  },
  [AccreditationType.Face]: () => true,
  [AccreditationType.Proof]: () => true,
};

export const getAccreditationByType = <Type extends AccreditationType>(accreditations: Accreditation[], type: Type) =>
  accreditations.find(accreditation => accreditation.type == type) as Accreditation<Type> | undefined;
