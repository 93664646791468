import { RegisterMode, UserRepository } from "../domain/userRepository";
import { AuthRepository, Roles } from "../domain/auth";
import { UserDetail } from "../domain/user";

export interface UserUseCases {
  enroll: () => Promise<void>;
  getUserDetail: () => Promise<UserDetail>;
  getUserSelfie: () => Promise<string>;
}

export const userUseCases = ({
  userRepository,
  authRepository,
}: {
  userRepository: UserRepository;
  authRepository: AuthRepository;
}): UserUseCases => ({
  enroll: async () => {
    const auth = authRepository.get()!;
    const userData = flattenObjectArray(auth.claims?.map(claim => ({ [claim]: auth[claim] })) ?? []);

    await userRepository.enroll(
      userData,
      auth.roles.includes(Roles.PostOnboardingsAuto) ? RegisterMode.Auto : RegisterMode.Assisted
    );

    authRepository.update({ enrolled: true });
  },
  getUserDetail: async () => {
    const auth = authRepository.get()!;

    return (await userRepository.getUserDetail(auth.userId)).data;
  },
  getUserSelfie: async () => {
    const auth = authRepository.get()!;

    return (await userRepository.getUserSelfie(auth.userId)).data;
  },
});

const flattenObjectArray = (array: object[]) => Object.assign({}, ...array);
