import { ActionContext, ActionTree } from "vuex";
import { SettingsActionsType, SettingsMutationsType } from "@/legacy/store/settings/contract";
import RootState from "@/legacy/store/rootState";
import { TenantSettings } from "@/legacy/models/settings";
import { SettingsState } from "@/legacy/store/settings/state";

const actions: ActionTree<SettingsState, RootState> = {};

actions[SettingsActionsType.SetSettings] = (
  store: ActionContext<SettingsState, RootState>,
  settings: TenantSettings
) => {
  store.commit(SettingsMutationsType.SetSettings, settings);
};

export default actions;
