import moment from "moment";
import i18n from "@/legacy/infrastructure/i18n";

export const formatter = {
  toDateString: (date: Date) => moment.utc(date).format(i18n.t("tenant.dateFormat") as string),
  toTimeString: (date: Date) => moment(date).format(i18n.t("tenant.timeFormat") as string),
  toDateHourString: (date: Date) => moment(date).format(i18n.t("tenant.dateHourFormat") as string),
  toISOUTCDateString: (date: Date) => moment.utc(date).format("YYYY-MM-DD"),
  todayISODateString: () => moment().format("YYYY-MM-DD"),
};
