import { GetterTree } from "vuex";
import { OnboardingState } from "@/legacy/features/onboarding/store/state";
import RootState from "@/legacy/store/rootState";
import { OnboardingGettersType } from "@/legacy/features/onboarding/store/contract";
import { RegisterType } from "@/legacy/models/shared";
import Vue from "vue";

const getters: GetterTree<OnboardingState, RootState> = {};
getters[OnboardingGettersType.OnboardingId] = (onboardingState: OnboardingState) => onboardingState.onboardingId;
getters[OnboardingGettersType.LifeProofing] = (onboardingState: OnboardingState) => onboardingState.lifeProofing;
getters[OnboardingGettersType.BiometricQR] = (onboardingState: OnboardingState) => onboardingState.biometricQR;
getters[OnboardingGettersType.SupportedDocs] = (onboardingState: OnboardingState) => onboardingState.supportedDocs;
getters[OnboardingGettersType.AssistedUserId] = (onboardingState: OnboardingState) => onboardingState.assistedUserId;
getters[OnboardingGettersType.AssistedEmail] = (onboardingState: OnboardingState) => onboardingState.assistedEmail;
getters[OnboardingGettersType.IdentityPortrayalId] = (onboardingState: OnboardingState) =>
  onboardingState.identityPortrayalId;
getters[OnboardingGettersType.DocumentType] = (onboardingState: OnboardingState) => onboardingState.documentType;
getters[OnboardingGettersType.DocumentCountry] = (onboardingState: OnboardingState) => onboardingState.documentCountry;
getters[OnboardingGettersType.GetRegisterType] = (onboardingState: OnboardingState) => onboardingState.registerType;
getters[OnboardingGettersType.GetRegisterMode] = () => {
  const instance = new Vue();
  return instance.$appStore.getters.isAssistant ? "assisted" : "auto";
};
getters[OnboardingGettersType.GetConfirmData] = (onboardingState: OnboardingState) => onboardingState.confirmData;

getters[OnboardingGettersType.IdentityProofing] = (onboardingState: OnboardingState) =>
  onboardingState.registerType === RegisterType.WithIdentityProofing;

export default getters;
