import Vue from "vue";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import { App } from "@/ui/App";
import router, { initRouter, requireAuthenticationGuard } from "@/ui/_router";
import store from "@/legacy/store";
import { BrowserSettings, TenantSettings } from "@/legacy/models/settings";
import { ConfigurationService, getTenant } from "@/legacy/services/configuration.service";
import { headerInterceptor } from "@/legacy/interceptors/axios-interceptor-header";
import i18n, { initI18n } from "@/legacy/infrastructure/i18n";
import { servicesPlugin } from "@/legacy/plugins/services.plugin";
import { Services } from "@/legacy/services/services";
import { HttpOnboardingService } from "@/legacy/features/onboarding/services/http-onboarding.service";
import { HttpIdentityPortrayalService } from "@/legacy/features/onboarding/services/http-identity-portrayal.service";
import { appStorePlugin } from "@/legacy/plugins/appStore.plugin";
import { NotificationService } from "@/legacy/services/notification.service";
import { ErrorsService } from "@/legacy/services/error.service";
import { CssVariableColorService } from "@/legacy/infrastructure/css-variable-color-service";
import { VeridasDocumentScanService } from "@/legacy/features/onboarding/services/veridas-document-scan.service";
import { VeridasTakeSelfieService } from "@/legacy/features/onboarding/services/veridas-take-selfie.service";
import { VeridasTakeSelfieAliveService } from "@/legacy/features/onboarding/services/veridas-take-selfie-alive.service";
import { HttpUserService } from "@/legacy/features/portal/services/http-user-service";
import { HttpVisitsService } from "./legacy/features/visits/services/http-visits-service";
import { SettingsActionsType } from "./legacy/store/settings/contract";
import { HttpDelegatedOnboardingService } from "./legacy/features/portal/services/http-delegated-onboarding.service";
import { BrowserValidationService } from "@/legacy/infrastructure/browser-validation.service";
import { HttpTenantManagementService } from "./legacy/features/portal/services/http-tenant-management-service";
import AuthenticationService from "./legacy/features/authentication/authentication.service";
import { RequestsService } from "@/legacy/services/request.service";
import { HttpAccessesService } from "./legacy/features/portal/services/http-accesses-service";
import { BaseAssetsUrl } from "@/legacy/models/constants";
import { resourceProviderPlugin } from "@/ui/_plugins/resourceProvider.plugin";
import { init as initUikit } from "@dasgate/uikit";
import { dependencyInjectionPlugin } from "@/ui/_plugins/dependencyInjection.plugin";
import { useCaseExecutorPlugin } from "@/ui/_plugins/useCaseExecutor.plugin";
import { formatterPlugin } from "@/ui/_plugins/formatter.plugin";
import { modules } from "./_di/modules";

async function initApp(settings: TenantSettings, browserSettings: BrowserSettings): Promise<void> {
  Vue.config.productionTip = false;
  Vue.config.performance = true;

  const services: Services = {
    authentication: new AuthenticationService(),
    onboardingService: new HttpOnboardingService(settings),
    identityPortrayalService: new HttpIdentityPortrayalService(),
    notificationService: new NotificationService(),
    errorsService: new ErrorsService(),
    colorService: new CssVariableColorService(),
    documentScanService: new VeridasDocumentScanService(),
    takeSelfieService: new VeridasTakeSelfieService(),
    takeSelfieAliveService: new VeridasTakeSelfieAliveService(),
    userService: new HttpUserService(),
    accessesService: new HttpAccessesService(),
    visitsService: new HttpVisitsService(),
    delegatedOnboardingService: new HttpDelegatedOnboardingService(),
    browserValidationService: new BrowserValidationService(browserSettings),
    tenantManagementService: new HttpTenantManagementService(),
    requestService: new RequestsService(),
  };

  Vue.use(servicesPlugin, services);
  Vue.use(appStorePlugin, store);
  Vue.use(resourceProviderPlugin);
  Vue.use(useCaseExecutorPlugin);
  Vue.use(formatterPlugin);
  Vue.use(VueMoment, {
    moment,
  });

  initUikit();
  await initI18n(i18n);

  headerInterceptor();
  router.beforeEach(Vue.prototype.$container.guardFactory(requireAuthenticationGuard));

  Vue.config.errorHandler = error => services.errorsService.onError(error);
  Vue.config.warnHandler = warning => services.errorsService.onWarning(warning);

  new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
  }).$mount("#app");
}

async function init(): Promise<void> {
  const tenant = getTenant();
  const assetsUrl = `${BaseAssetsUrl}${tenant}`;
  const themeStyle = document.createElement("link");
  themeStyle.setAttribute("rel", "stylesheet");
  themeStyle.setAttribute("href", assetsUrl + "/theme/theme.css");
  document.head.appendChild(themeStyle);

  const configurationService = new ConfigurationService();
  const settings = await configurationService.getConfig();
  const browserSettings = await configurationService.getBrowserSettings();
  store.dispatch(SettingsActionsType.SetSettings, settings);

  Vue.use(dependencyInjectionPlugin, modules);
  initRouter();
  await initApp(settings, browserSettings);
}

init();
