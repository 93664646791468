export interface DocumentScanOptions {
  container: Element;
  containerSelector: string;
  primaryColor: string;
  documentType: string;
  documentCountry: string;
  onObverse: (image: string) => void;
  onReverse: (image: string) => void;
  onStart: () => void;
  onFinish: () => void;
  onError: (fatal: boolean) => void;
  onObverseTypeDetected: (type: string) => void;
  onReverseTypeDetected: (type: string) => void;
}

export interface DocumentScanService {
  scan(options: DocumentScanOptions): void;
  cancel(): void;
}

export function veridasDocumentGroup(documentType: string, documentCountry: string) {
  let countryCode = documentCountry !== "ANY" ? documentCountry : "";
  let documentCode = "";
  if (documentCountry === "ES") {
    countryCode = "ES2";
  }
  if (documentCountry === "MX") {
    countryCode = "MX2";
  }
  if (documentType === "id_card") {
    documentCode = "ID";
  }
  if (documentType === "driving_license") {
    documentCode = "DL";
  }
  if (documentType === "any") {
    return [countryCode];
  }

  return countryCode ? [countryCode + "_" + documentCode] : ["XX"];
}
