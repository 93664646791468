import { Serializer } from "@/core/shared/api/domain/serializers";
import { AccreditationRequest, AccreditationType } from "@/core/accreditation/domain/accreditation";
import { isDefined } from "@/core/shared/utils";

export interface AccreditationRequestDto {
  subject: string;
  object: string;
  action: string;
  claims?: { portrayal_id: string };
}

export const accreditationRequestSerializer: Serializer<AccreditationRequest, AccreditationRequestDto> = {
  encode: accreditationRequest => ({
    subject: accreditationRequest.userId,
    object: accreditationRequest.location,
    action: actionMap[accreditationRequest.type],
    ...(isDefined(accreditationRequest.portrayalId)
      ? { claims: { portrayal_id: accreditationRequest.portrayalId } }
      : {}),
  }),
};

const actionMap: Record<AccreditationType, string> = {
  [AccreditationType.Qr]: "access_qr",
  [AccreditationType.Face]: "access_face",
  [AccreditationType.Proof]: "access_proof",
};
