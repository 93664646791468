import { MutationTree } from "vuex";
import { RootMutations } from "./enums";
import RootState from "./rootState";

const mutations: MutationTree<RootState> = {};

mutations[RootMutations.SetRequestLoading] = (state: RootState, loadingState: boolean) => {
  state.requestLoading = loadingState;
};
mutations[RootMutations.SetRedirect] = (state: RootState, redirect: string) => {
  state.redirect = redirect;
};

export default mutations;
