import { ActionContext, ActionTree } from "vuex";
import { OnboardingActionsType, OnboardingMutationsType } from "@/legacy/features/onboarding/store/contract";
import { OnboardingState } from "@/legacy/features/onboarding/store/state";
import RootState from "@/legacy/store/rootState";
import { RegisterMode, RegisterType } from "@/legacy/models/shared";

const actions: ActionTree<OnboardingState, RootState> = {};

actions[OnboardingActionsType.StartOnboarding] = (
  store: ActionContext<OnboardingState, RootState>,
  payload: { onboardingId: string; registerType: RegisterType }
) => {
  store.commit(OnboardingMutationsType.StartOnboarding, payload);
};

actions[OnboardingActionsType.SetRegisterMode] = (
  store: ActionContext<OnboardingState, RootState>,
  registerMode: RegisterMode
) => {
  store.commit(OnboardingMutationsType.SetRegisterMode, registerMode);
};

actions[OnboardingActionsType.ResetOnboarding] = (store: ActionContext<OnboardingState, RootState>) => {
  store.commit(OnboardingMutationsType.ResetOnboarding);
};

actions[OnboardingActionsType.StartIdentityPortrayal] = (
  store: ActionContext<OnboardingState, RootState>,
  payload: {
    identityPortrayalId: string;
    documentType: string;
    documentCountry: string;
  }
) => {
  store.commit(OnboardingMutationsType.StartIdentityPortrayal, payload);
};

actions[OnboardingActionsType.CancelIdentityPortrayal] = (store: ActionContext<OnboardingState, RootState>) => {
  store.commit(OnboardingMutationsType.CancelIdentityPortrayal);
};

actions[OnboardingActionsType.SetConfirmData] = (
  store: ActionContext<OnboardingState, RootState>,
  confirmData: any
) => {
  store.commit(OnboardingMutationsType.SetConfirmData, confirmData);
};

export default actions;
