export enum RouteNames {
  PrivacyOnboarding = "privacy-onboarding",
  RegisterDocuments = "register-documents",
  DocumentScan = "document-scan",
  RegisterSelfie = "register-selfie",
  TakeSelfie = "take-selfie",
  TakeSelfieAlive = "take-selfie-alive",
  ReviewRegister = "review-register",
  FinishRegister = "finish-register",
  FinishRegisterWithError = "finish-register-error/:errorCode",
  FinishRegisterWithQr = "finish-register/:userId/:qrId",
  PortalAccounts = "accounts",
  PortalUsers = "users",
  PortalUserDetail = "user-detail",
  SingleRegistration = "new-user-credential",
  BatchRegistration = "new-batch-register",
  RegisterForm = "register-form",
  DelegatedFinishRegister = "delegated-finish-register",
  BatchRegistrationResult = "finish-batch-register/:batchId",
  DeviceDetect = "device-detect",
  Callback = "callback",
  Silent = "silent",
  PortalManagement = "portal-management",
  UserHome = "home",
  DeleteAccount = "delete-account",
  ResetCredentials = "reset-credentials",
  UserProfile = "profile",
  AuditAccesses = "audit-accesses",
  VisitList = "visits",
  MyVisits = "my-visits",
  ProcessVisit = "process-visit",
  NewVisit = "new-visit/:userId?",
  VisitDetail = "visit-detail",
  VisitConfirmation = "confirmation/:token/:visitId",
  ActivateUsers = "activate-users",
  NextSteps = "next-steps",
  IdentityProofingStep = "id-proofing-step",
  CheckInterdictions = "check-interdictions",
  UploadWatchlist = "upload-watchlist",
  UploadWatchlistResult = "upload-watchlist-result",
  AssistantAccountCancellation = "delete-account-assistant",
  Landing = "landing",
  MyAccreditations = "my-accreditations",
  AccreditationDetail = "accreditation-detail/:accreditationId",
  AccreditFace = "accredit-face",
  AccreditProof = "accredit-proof",
  Error = "error/:link/:reason",
}

export const publicRoutes = [RouteNames.Callback, RouteNames.Silent, RouteNames.VisitConfirmation];

export type RouteName = keyof typeof RouteNames;
