import { getHttpClient } from "@/legacy/features/authentication/auth-http-client.service";
import { DelegatedOnboardingService } from "../interfaces/delegated-onboarding-service";
import {
  BatchOnboardingRequest,
  BatchOnboardingResponse,
  BatchOnboardingResponseErrorCode,
  BatchStatusResponse,
  DelegatedOnboardingRequest,
  DelegatedOnboardingResponse,
  DelegatedOnboardingResponseErrorCode,
  GetAcsidStatusRequest,
  GetBatchStatusRequest,
  GetEmailStatusRequest,
} from "../models/delegated-onboarding.models";
import { IdentityPortrayalType } from "@/legacy/models/shared";
import Vue from "vue";
import EventBus from "@/legacy/services/bus.service";

interface ConfirmOnboardingRawRequestWithoutDocumentWithId {
  username: string;
  acs_id: string;
  acs_id_schema: string;
  email: string;
  portrayal_type: IdentityPortrayalType.selfie;
  selfie: string;
  deliver_by_email: boolean;
}

interface ConfirmSingleDynamicDocumentData {
  [key: string]: string | boolean | number;
}

type ConfirmOnboardingRawRequest = ConfirmOnboardingRawRequestWithoutDocumentWithId | ConfirmSingleDynamicDocumentData;

interface ConfirmDelegatedOnboardingRawResponse {
  onboarding_id: string;
  mode: "auto" | "assisted";
  status: "initialized" | "running" | "completed" | "cancelled" | "failed" | "finished";
  result?: {
    outcome: "accepted" | "rejected";
    reason:
      | "selfie_not_provided"
      | "embedding_generation_failed"
      | "ocr_incomplete"
      | "identity_verification_below_th"
      | "ocr_adaptations_not_enabled"
      | "validation_failed"
      | "acs_id_already_in_use"
      | "qr_generation_failed";
  };
}

interface BatchOnboardingRawResponse {
  batch_id: string;
}

interface BatchRawProgress {
  total: number;
  failed: number;
  success: number;
  completed: number;
  rejected: number;
}

interface BatchOnboarding {
  onboarding_id: string;
  user_id: string;
  username: string;
  acs_id: string;
  portrayal_type: "selfie";
  status: "initialized" | "running" | "completed" | "cancelled" | "failed" | "finished";
  result?: {
    outcome: "accepted" | "rejected";
    reason:
      | "selfie_not_provided"
      | "ocr_incomplete"
      | "identity_verification_below_th"
      | "ocr_adaptations_not_enabled"
      | "validation_failed"
      | "embedding_generation_failed"
      | "acs_id_already_in_use"
      | "email_required"
      | "invalid_email"
      | "selfie_size_under_threshold"
      | "selfie_size_over_threshold"
      | "metadata_missing_required_fields"
      | "data_integrity_error_selfie"
      | "email_already_exists";
  };
}

interface BatchStatusRawResponse {
  batch_id: string;
  stats: BatchRawProgress;
  status: "initialized" | "running" | "completed" | "cancelled" | "failed" | "finished";
  onboardings: BatchOnboarding[];
}

export class HttpDelegatedOnboardingService extends Vue implements DelegatedOnboardingService {
  public async acsidExists(request: GetAcsidStatusRequest): Promise<boolean> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.get(`/public/credentials?acs_id=` + request.acsid, {
      params: { silent: true },
      validateStatus: status => {
        return status === 200 || status === 404;
      },
    });
    if (rawResponse.status === 200) {
      return true;
    }
    return false;
  }

  public async emailExists(request: GetEmailStatusRequest): Promise<boolean> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.get(`/internal/accounts?email=` + request.email, {
      params: { silent: true },
      validateStatus: status => {
        return status === 200 || status === 404;
      },
    });
    if (rawResponse.status === 200) {
      return true;
    }
    return false;
  }

  public async single(request: DelegatedOnboardingRequest): Promise<DelegatedOnboardingResponse> {
    const httpClient = getHttpClient();
    let rawRequest: ConfirmOnboardingRawRequest;

    if ("fields" in request.data) {
      rawRequest = request.data.fields as ConfirmSingleDynamicDocumentData;
      if (request.data.email !== "") {
        rawRequest.email = request.data.email;
      }
      rawRequest.acs_id_schema = request.acsIdSchema;
      rawRequest.portrayal_type = request.type;
      rawRequest.deliver_by_email = request.data.deliver_by_mail;
      rawRequest.selfie = request.data.selfie;
    } else {
      rawRequest = {
        username: request.data.username,
        acs_id: request.data.acs_id,
        acs_id_schema: request.acsIdSchema,
        email: request.data.email,
        selfie: request.data.image,
        portrayal_type: request.type,
        deliver_by_email: request.data.deliver_by_mail,
      } as ConfirmOnboardingRawRequest;
    }

    const rawResponse = await httpClient.post<ConfirmDelegatedOnboardingRawResponse>(`/public/singles`, rawRequest);

    if (rawResponse.data.result?.outcome === "accepted") {
      return {
        success: true,
      };
    }

    let errorCode = DelegatedOnboardingResponseErrorCode.Unknown;
    switch (rawResponse.data.result?.reason) {
      case "selfie_not_provided":
        errorCode = DelegatedOnboardingResponseErrorCode.DocumentExpired;
        break;
      case "embedding_generation_failed":
        errorCode = DelegatedOnboardingResponseErrorCode.EmbeddingFailed;
        break;
      case "acs_id_already_in_use":
        errorCode = DelegatedOnboardingResponseErrorCode.IdAlreadyUsed;
        break;
      case "qr_generation_failed":
        errorCode = DelegatedOnboardingResponseErrorCode.QrGenerationFailed;
        break;
    }

    return {
      success: false,
      code: errorCode,
    };
  }
  public async batch(request: BatchOnboardingRequest): Promise<BatchOnboardingResponse> {
    const formData = new FormData();

    const type = request.onboardingFile.type.includes("json") ? "application/json" : "text/csv";
    formData.append("metadata", new Blob([request.onboardingFile], { type }));
    formData.append("media", new Blob([request.mediaFile], { type: "application/zip" }));
    formData.append("acs_id_schema", request.acsIdSchema);
    formData.append("deliver_by_email", String(request.deliverByEmail));

    const httpClient = getHttpClient();
    const rawResponse = await httpClient.post<BatchOnboardingRawResponse>("/public/batches", formData, {
      onUploadProgress: (progress: ProgressEvent) => {
        const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
        EventBus.$emit("batchUploadProgress", percentCompleted);
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (rawResponse.status === 201) {
      return {
        batchId: rawResponse.data.batch_id,
        success: true,
      };
    }
    return {
      error: BatchOnboardingResponseErrorCode.Unknown,
      success: false,
    };
  }

  public async getBatchStatus(request: GetBatchStatusRequest): Promise<BatchStatusResponse> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.get<BatchStatusRawResponse>(`/public/batches/${request.batchId}`);
    return {
      batchProgress: rawResponse.data.stats,
      success: rawResponse.data.status === "finished",
      failedOnboardings: rawResponse.data.onboardings.map(o => {
        return {
          username: o.username,
          acs_id: o.acs_id,
          reason: getBatchOnboardingResponseError(o),
        };
      }),
    };
  }
}

export const getBatchOnboardingResponseError = (param: BatchOnboarding): BatchOnboardingResponseErrorCode => {
  if (param?.result?.reason === "selfie_not_provided") {
    return BatchOnboardingResponseErrorCode.SelfieNotProvided;
  }
  if (param?.result?.reason === "validation_failed") {
    return BatchOnboardingResponseErrorCode.ValidationFailed;
  }
  if (param?.result?.reason === "embedding_generation_failed") {
    return BatchOnboardingResponseErrorCode.EmbeddingFailed;
  }
  if (param?.result?.reason === "acs_id_already_in_use") {
    return BatchOnboardingResponseErrorCode.ExistingAcsId;
  }
  if (param?.result?.reason === "email_required") {
    return BatchOnboardingResponseErrorCode.EmailRequired;
  }
  if (param?.result?.reason === "invalid_email") {
    return BatchOnboardingResponseErrorCode.InvalidEmail;
  }
  if (param?.result?.reason === "selfie_size_under_threshold") {
    return BatchOnboardingResponseErrorCode.SelfieTooSmall;
  }
  if (param?.result?.reason === "selfie_size_over_threshold") {
    return BatchOnboardingResponseErrorCode.SelfieTooBig;
  }
  if (param?.result?.reason === "metadata_missing_required_fields") {
    return BatchOnboardingResponseErrorCode.MissingData;
  }
  if (param?.result?.reason === "data_integrity_error_selfie") {
    return BatchOnboardingResponseErrorCode.MissingSelfie;
  }
  if (param?.result?.reason === "email_already_exists") {
    return BatchOnboardingResponseErrorCode.EmailInUse;
  }
  return BatchOnboardingResponseErrorCode.Unknown;
};
