import { Deserializer } from "@/core/shared/api/domain/serializers";
import { Visit, VisitStatus } from "../domain/visit";

export interface VisitDTO {
  id: string;
  title: string;
  visitor: string;
  expected_at: string;
  leaving_at: string;
  host: string;
  location: string;
  meetings: string[];
  history: VisitEventDto[];
  status: VisitStatus;
}

interface VisitEventDto {
  datetime: string;
  action: string;
  message: string;
  result: string;
}

export const visitDeserializer: Deserializer<Visit, VisitDTO> = {
  parse: data => ({
    id: data.id,
    title: data.title,
    visitor: data.visitor,
    expectedAt: data.expected_at,
    leavingAt: data.leaving_at,
    host: data.host,
    location: data.location,
    meetings: data.meetings,
    history: data.history,
    status: data.status as VisitStatus,
  }),
};
