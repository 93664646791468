import { AxiosResponse } from "axios";
import { Log } from "@/legacy/infrastructure/logger";
import i18n from "@/legacy/infrastructure/i18n";
import { emitServerErrors } from "./bus.service";
import { IRequestsService } from "@/legacy/interfaces/request.service";
import Vue from "vue";

export class RequestsService extends Vue implements IRequestsService {
  private validationErrors: Record<string, string> = {};

  public syncErrors(response?: AxiosResponse) {
    if (response && response.data && response.data.detail) {
      Object.keys(response.data.detail).forEach(field => {
        this.$set(this.validationErrors, field, i18n.tc("common.errors." + response.data.detail[field]));
      });

      emitServerErrors(this.validationErrors);
    }
  }

  public initRequest() {
    Log.info("setRequestLoadingState");
    this.$appStore.actions.setRequestLoadingState(true);
    this.validationErrors = {};
  }

  public endRequest() {
    this.$appStore.actions.setRequestLoadingState(false);
  }

  public getErrors() {
    return this.validationErrors;
  }
}
