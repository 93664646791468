import { Config } from "@/core/shared/config/domain/config";
import axios, { AxiosError } from "axiosV1";
import { Server, ServerError } from "../domain/server";

export const axiosServer = ({ config: { baseURL, tenant } }: { config: Config }): Server => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      "X-dasgate-tenant-id": tenant,
      "Access-Control-Allow-Origin": "*",
    },
  });

  return {
    request: async ({ url, method, data, headers, responseType }) => {
      try {
        const axiosResponse = await axiosInstance.request({ url, method, data, headers, responseType });
        return { status: axiosResponse.status, data: axiosResponse.data, headers: axiosResponse.headers };
      } catch (error) {
        if ((error as AxiosError).isAxiosError) {
          (error as ServerError).isServerError = true;
          throw error;
        }
        throw error;
      }
    },
  };
};
