import { Component, Vue } from "vue-property-decorator";
import { Log } from "@/legacy/infrastructure/logger";

@Component
export default class Silent extends Vue {
  public mounted() {
    this.silent();
  }

  public async silent() {
    Log.info("signinSilentCallback");
    const user = await this.$services.authentication.silent();
    if (user) {
      this.$container.authRepository.update({ accessToken: user.accessToken });
    }
  }
}
