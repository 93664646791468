export enum RootMutations {
  SetRequestLoading = "setRequestLoading",
  SetRedirect = "setRedirect",
}

export enum RootActions {
  SetRequestLoading = "setRequestLoading",
  SetRedirect = "setRedirect",
}

export enum RootGetters {
  GetRequestStatus = "getRequestStatus",
  GetRedirect = "getRedirect",
}
