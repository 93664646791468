import { asFunction, asValue } from "@/_di/resolvers";
import { useCaseExecutor, UseCaseExecutor } from "@/ui/_utils/executor";
import router from "@/ui/_router";
import { ErrorHandler } from "@/ui/_utils/executor/useCaseExecutor";
import VueRouter, { NavigationGuard } from "vue-router";
import { ErrorsService } from "@/legacy/services/error.service";
import { guardFactory } from "@/ui/_router/guards/guardFactory";
import { Guard } from "@/ui/_router/guards/guards";

export const uiModule = {
  useCaseExecutor: asFunction<UseCaseExecutor>(useCaseExecutor),
  router: asValue<VueRouter>(router),
  errorHandler: asValue<ErrorHandler>({ handle: new ErrorsService().onError }),
  guardFactory: asFunction<(guard: Guard) => NavigationGuard>(guardFactory),
};
