import Vue from "vue";
import { WatchAltHeader } from "@/legacy/models/constants";
import { getHttpClient } from "@/legacy/features/authentication/auth-http-client.service";
import { TenantManagementService } from "../interfaces/tenant-management-service";

export class HttpTenantManagementService implements TenantManagementService {
  public async activateAll(): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.put(`/public/credentials/active`);
  }
  public async deactivateAll(): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.put(`/public/credentials/inactive`);
  }
  public async cancelAll(): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.delete(`/public/users`);
  }
  public async deleteAllData(): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.put(`/public/users/deleteAllData`);
  }
  public async uploadWatchlist(domain: string, watchlistFile: File): Promise<string> {
    const formData = new FormData();

    const type = "text/csv";
    formData.append("domain", domain);
    formData.append("watch_type", "interdicted");

    const httpClient = getHttpClient();

    try {
      if (Vue.prototype.$appStore.getters.settings.useWatch) {
        formData.append("upload", new Blob([watchlistFile], { type }));
        await httpClient.post(`/watchlists`, formData, {
          headers: { "Content-Type": "multipart/form-data", "Alt-Used": WatchAltHeader },
        });
      } else {
        formData.append("file", new Blob([watchlistFile], { type }));
        await httpClient.post(`/public/watchlists`, formData, { headers: { "Content-Type": "multipart/form-data" } });
      }
    } catch (error) {
      return "false";
    }
    return "true";
  }
}
