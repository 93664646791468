import { Serializer } from "./serializers";

export interface RequestTypeConfig<Data, Dto> {
  contentType: string;
  encodeRequest: (data: Data) => Dto;
}

export const RequestType = {
  json: <Data, Dto>(serializer?: Serializer<Data, Dto>): RequestTypeConfig<Data, Dto> => ({
    contentType: "application/json",
    encodeRequest: dto => (serializer ? serializer.encode(dto) : (dto as unknown as Dto)),
  }),
  formData: (): RequestTypeConfig<FormData, FormData> => ({
    contentType: "multipart/form-data",
    encodeRequest: formData => formData,
  }),
};

export type MapRequestLiterals = Exclude<keyof typeof RequestType, "json">;
export type MapRequestTypeMapper = {
  formData: FormData;
};
