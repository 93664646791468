import { TenantSettings } from "@/legacy/models/settings";

export enum SettingsMutationsType {
  SetSettings = "setSettings",
}

export enum SettingsActionsType {
  SetSettings = "setSettings",
}

export enum SettingsGettersType {
  GetSettings = "getSettings",
}

export interface SettingsGetters {
  settings: TenantSettings;
}

export interface SettingsActions {
  setSettings(settings: TenantSettings): void;
}
