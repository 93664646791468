import { Deserializer } from "@/core/shared/api/domain/serializers";
import { PortrayalResult } from "@/core/portrayal/domain/portrayal";

export interface PortrayalResultDto {
  score_biometry: number;
  score_biometry_valid: boolean;
  document_validation: number;
  score_document_valid: boolean;
  data_validation: boolean;
  selfie_authenticity_valid?: boolean;
  document_info?: {
    first_name: string;
    last_name: string;
    birth_date: string;
    id_number: string;
    document_number: string;
    expiry_date: string;
    expedition_country: string;
    nationality: string;
    document_type: "id_card" | "passport" | "residence_permit" | "others" | "driving_license" | "none";
  };
}

export const portrayalResultDeserializer: Deserializer<PortrayalResult, PortrayalResultDto> = {
  parse: data => ({
    documentData: data.document_info && {
      firstName: data.document_info.first_name,
      lastName: data.document_info.last_name,
      birthDate: data.document_info.birth_date,
      documentId: data.document_info.id_number,
      documentNumber: data.document_info.document_number,
      expiryDate: data.document_info.expiry_date,
      expeditionCountry: data.document_info?.expedition_country,
      nationality: data.document_info.nationality,
      documentType: data.document_info.document_type,
    },
    scores: {
      document: data.document_validation,
      documentOk: data.score_document_valid,
      dataValidationOk: data.data_validation ?? undefined,
      selfie: data.score_biometry,
      selfieOk: data.score_biometry_valid,
      selfieAuthentic: data.selfie_authenticity_valid ?? true,
    },
  }),
};
