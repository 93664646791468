import { Iam } from "@/legacy/models/constants";
import { getTenant } from "@/legacy/services/configuration.service";
import { Log } from "@/legacy/infrastructure/logger";
import { UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import { getIAMClient } from "@/legacy/features/authentication/auth-http-client.service";
import Vue from "vue";
import router, { publicRoutes, RouteNames } from "@/ui/_router";
import { Auth, Claims, claims, Roles } from "@/core/user/domain/auth";
import { camelCase } from "lodash";
import { IAuthenticationService, UserCodeResponse } from "./interfaces/authentication.service";

interface IamUser {
  access_token: string;
  profile: {
    roles: Roles[];
    email: string;
    preferred_username: string;
    profile: string;
    sub: string;
    enrolled: boolean;
    locale?: string;
    user_type: string;
    with_claims?: string;
  };
}

export default class AuthenticationService extends Vue implements IAuthenticationService {
  private userManager: UserManager;

  constructor() {
    super();
    const HOST = window.location.origin;
    const queryParams = window.location.search;
    const urlParams = new URLSearchParams(queryParams);
    const userCode = urlParams.get("user_code");
    const callback = queryParams ? "/callback" + queryParams : "/callback";
    const IAM = Iam + "auth/realms/" + getTenant();
    const userManagerSettings: UserManagerSettings = {
      authority: IAM,
      client_id: "web",
      redirect_uri: HOST + callback,
      response_type: "code",
      scope: "openid profile roles email",
      post_logout_redirect_uri: HOST,
      filterProtocolClaims: true,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      loadUserInfo: true,
    };

    if (userCode) {
      userManagerSettings.extraQueryParams = { user_code: userCode };
    }
    this.userManager = new UserManager(userManagerSettings);
    this.userManager.events.addAccessTokenExpiring(() => this.silentRenew());
    this.userManager.events.addAccessTokenExpired(() => this.silentRenew());
    Log.info("constructing auth service");
  }

  public async login(): Promise<void> {
    Log.info("login");
    return await this.userManager.signinRedirect();
  }

  public async logout(): Promise<void> {
    Log.info("logout");
    this.$container.authRepository.unset();
    if ((await this.userManager.getUser()) === null) {
      document.cookie = "";
    }
    return await this.userManager.signoutRedirect();
  }

  public async callback(): Promise<Auth> {
    Log.info("signing redirect callback");
    return toAuth((await this.userManager.signinRedirectCallback()) as unknown as IamUser);
  }

  public async silent(): Promise<Auth | undefined> {
    Log.info("signing silent callback");
    const iamUser = (await this.userManager.signinSilent()) as unknown as IamUser;
    return iamUser ? toAuth(iamUser) : undefined;
  }

  public async genUserCode(): Promise<UserCodeResponse> {
    const httpClient = getIAMClient();
    const response = await httpClient.post("/user-code/generate");
    if (response.status === 200) {
      return response.data;
    }
    return { usercode: "" } as UserCodeResponse;
  }

  public async silentRenew() {
    Log.info("launch silent renew");
    try {
      const user = await this.userManager.signinSilent();
      this.$container.authRepository.update({ accessToken: user!.access_token });
    } catch (error) {
      await this.clearCredentials();
    }
  }

  private async clearCredentials() {
    Log.info("launch clearCredentials");
    this.$container.authRepository.unset();

    const isPublicRoute = publicRoutes.includes(router.currentRoute.name as RouteNames);
    if (!isPublicRoute) {
      const vue = new Vue();
      vue.$services.authentication.login();
    }
  }
}

const toAuth = (user: IamUser): Auth => ({
  roles: user.profile.roles,
  email: user.profile.email!,
  userName: user.profile.preferred_username!,
  profile: user.profile.profile!,
  userId: user.profile.sub,
  enrolled: user.profile.enrolled ? user.profile.enrolled : false,
  accessToken: user.access_token,
  userType: user.profile.user_type,
  locale: user.profile.locale ? user.profile.locale : window.navigator.language.split("-")[0] || "es",
  claims: user.profile.with_claims
    ?.split(",")
    .map(claim => camelCase(claim))
    .filter(claim => claims.includes(claim as Claims)) as Claims[],
});
