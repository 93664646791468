import { Deserializer } from "@/core/shared/api/domain/serializers";
import { Accreditation, AccreditationType } from "@/core/accreditation/domain/accreditation";

interface BaseAccreditationDto {
  sid: string;
  credential_type: AccreditationTypeDto;
}

export interface QrAccreditationDto extends BaseAccreditationDto {
  credential_type: "qr";
  starts_at: string;
  ends_at: string;
}

export interface FaceAccreditationDto extends BaseAccreditationDto {
  credential_type: "embedding";
  created_at: string;
}

export interface ProofAccreditationDto extends BaseAccreditationDto {
  credential_type: "enrolment_proof";
}

export type AccreditationDto<Type extends AccreditationTypeDto = AccreditationTypeDto> = {
  qr: QrAccreditationDto;
  embedding: FaceAccreditationDto;
  enrolment_proof: ProofAccreditationDto;
}[Type];

export type AccreditationTypeDto<Type extends AccreditationType = AccreditationType> = {
  [AccreditationType.Qr]: "qr";
  [AccreditationType.Face]: "embedding";
  [AccreditationType.Proof]: "enrolment_proof";
}[Type];

type AccreditationDeserializer<Type extends AccreditationType = AccreditationType> = Deserializer<
  Accreditation<Type>,
  AccreditationDto<AccreditationTypeDto<Type>>
>;

const qrAccreditationDeserializer: AccreditationDeserializer<AccreditationType.Qr> = {
  parse: qrAccreditationDto => ({
    id: qrAccreditationDto.sid,
    type: AccreditationType.Qr,
    startsAt: new Date(qrAccreditationDto.starts_at),
    endsAt: new Date(qrAccreditationDto.ends_at),
  }),
};

const faceAccreditationDeserializer: AccreditationDeserializer<AccreditationType.Face> = {
  parse: faceAccreditationDto => ({
    id: faceAccreditationDto.sid,
    type: AccreditationType.Face,
    createdAt: new Date(faceAccreditationDto.created_at),
  }),
};

const proofAccreditationDeserializer: AccreditationDeserializer<AccreditationType.Proof> = {
  parse: proofAccreditationDto => ({
    id: proofAccreditationDto.sid,
    type: AccreditationType.Proof,
  }),
};

export const accreditationDeserializers: {
  [Type in AccreditationType]: AccreditationDeserializer<Type>;
} = {
  [AccreditationType.Qr]: qrAccreditationDeserializer,
  [AccreditationType.Face]: faceAccreditationDeserializer,
  [AccreditationType.Proof]: proofAccreditationDeserializer,
};

export const accreditationDeserializer: AccreditationDeserializer = {
  parse: <Type extends AccreditationType>(accreditationDto: AccreditationDto<AccreditationTypeDto<Type>>) => {
    const typeMap: Record<AccreditationTypeDto, AccreditationType> = {
      qr: AccreditationType.Qr,
      embedding: AccreditationType.Face,
      enrolment_proof: AccreditationType.Proof,
    };
    const deserializer = accreditationDeserializers[typeMap[accreditationDto.credential_type] as Type];
    return deserializer.parse(accreditationDto);
  },
};
