import { Store } from "vuex";
import RootState from "@/legacy/store/rootState";
import AppGetters from "@/legacy/plugins/app-store/app-getters";
import AppActions from "@/legacy/plugins/app-store/app-actions";

export interface AppStore {
  getters: AppGetters;
  actions: AppActions;
}

const createAppStore = (store: Store<RootState>): AppStore => {
  return {
    getters: new AppGetters(store),
    actions: new AppActions(store),
  };
};

export const appStorePlugin = {
  install(vue: any, store: Store<RootState>) {
    vue.prototype.$appStore = createAppStore(store);
  },
};
