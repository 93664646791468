import VueRouter, { RawLocation } from "vue-router";
import { ApiError, ApiResponse, isApiError, isApiResponse, StatusCodes } from "@/core/shared/api/domain/apiClient";
import { has, isDefined, isFunction } from "@/core/shared/utils";

export interface ExecutorResult<Data> {
  data: Data;
  isLoading: boolean;
}

type InitExecutorResult = {
  <Data>(value: Data): ExecutorResult<Data>;
  <Data = undefined>(): ExecutorResult<Data | undefined>;
};
export const executorResult: InitExecutorResult = <Data>(value?: unknown) => ({
  data: value as Data,
  isLoading: false,
});

type ErrorDefinitions = { [key in StatusCodes]?: (apiError: ApiError) => void } & {
  default?: (error: Error) => void;
  always?: (error: Error) => void;
};

interface ExecutorOptions<Data> {
  resultRef?: ExecutorResult<Data>;
  fallbackLocation?: RawLocation | ((data: Data) => RawLocation);
  onSuccess?: (data: Data) => void;
  onError?: (error: Error) => void;
  errorDefinitions?: ErrorDefinitions;
}

type NotApiResponse<T> = T & (T extends ApiResponse ? never : T);

export interface UseCaseExecutor<ExecuteResponse = void> {
  execute<Data>(useCase: () => Promise<ApiResponse<Data>>, options?: ExecutorOptions<Data>): ExecuteResponse;

  execute<Data>(useCase: () => Promise<NotApiResponse<Data>>, options?: ExecutorOptions<Data>): ExecuteResponse;
}

export interface ErrorHandler {
  handle: (error: Error) => void;
}

export const useCaseExecutor = ({
  router,
  errorHandler,
}: {
  router: VueRouter;
  errorHandler: ErrorHandler;
}): UseCaseExecutor<Promise<void>> => ({
  execute: async <Data>(
    useCase: () => Promise<NotApiResponse<Data> | ApiResponse<Data>>,
    { resultRef, fallbackLocation, onSuccess, onError, errorDefinitions }: ExecutorOptions<Data> = {}
  ) => {
    const result = resultRef ?? { data: undefined, isLoading: false };
    let response: NotApiResponse<Data> | ApiResponse<Data> | undefined;

    result.isLoading = true;
    try {
      response = await useCase();
      result.data = has(response, "isApiResponse") ? response.data : response;
    } catch (error) {
      if (isDefined(errorDefinitions) && isApiError(error) && isDefined(errorDefinitions[error.statusCode])) {
        errorDefinitions[error.statusCode]!(error);
      } else if (isDefined(errorDefinitions) && isDefined(errorDefinitions.default)) {
        errorDefinitions.default(error as Error);
      } else if (isApiError(error) && isDefined(error.link)) {
        router.push({ path: error.link.uri, query: error.link.params });
      } else if (isDefined(onError)) {
        onError(error as Error);
      } else {
        errorHandler.handle(isApiError(error) && error.originalError ? error.originalError : (error as Error));
      }

      if (isDefined(errorDefinitions) && isDefined(errorDefinitions.always)) {
        errorDefinitions.always(error as Error);
      }

      return;
    } finally {
      result.isLoading = false;
    }

    if (isApiResponse(response) && isDefined(response.link)) {
      router.push({ path: response.link.uri, query: response.link.params });
    } else if (isDefined(fallbackLocation)) {
      router.push(isFunction(fallbackLocation) ? fallbackLocation(result.data) : fallbackLocation);
    } else {
      onSuccess?.(result.data);
    }
  },
});
