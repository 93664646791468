import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DgBadge, DgButton, DgText } from "@dasgate/uikit";
import { Version } from "@/legacy/models/constants";
import { authPermissions, AuthPermissions } from "@/core/user/domain/auth";
import { RouteNames } from "@/ui/_router";

@Component({ components: { DgButton, DgBadge, DgText } })
export default class Menu extends Vue {
  @Prop({ required: false, default: false }) public isOpen!: boolean;
  public readonly version = Version;

  public get permissions(): AuthPermissions {
    return authPermissions(this.$container.authRepository.get());
  }

  get classes() {
    return [this.isOpen && "--open"];
  }

  public get isAuto(): boolean {
    return this.$appStore.getters.registerMode === "auto";
  }

  public get isGambling(): boolean {
    return this.$appStore.getters.settings.domain === "gambling";
  }

  public get visibleMenuItems() {
    return this.menuItems.filter(menuItem => menuItem.canSee);
  }

  public onCloseClick() {
    this.isOpen = false;
    this.$emit("on-close");
  }

  public onGoTo(destination: string) {
    this.isOpen = false;
    this.$emit("on-close");
    this.$router.push({ name: destination });
  }

  public get menuItems() {
    return [
      {
        title: "common.menu.portal",
        icon: "flag",
        to: RouteNames.PortalManagement,
        canSee: !this.isAuto,
      },
      {
        title: "common.menu.users",
        icon: "address-book",
        to: RouteNames.PortalUsers,
        canSee: this.permissions.isUserManager(),
      },
      {
        title: "common.menu.register",
        icon: "plus-square",
        to: RouteNames.SingleRegistration,
        canSee: this.permissions.canViewSingleEnrolment(),
      },
      {
        title: "common.menu.register-batch",
        icon: "plus-square",
        to: RouteNames.BatchRegistration,
        canSee: this.permissions.canViewBatchEnrolment(),
      },
      {
        title: "common.visits.menu.my-visits",
        icon: "calendar",
        to: RouteNames.MyVisits,
        canSee: this.permissions.isVisitor() && this.permissions.isEnrolled(),
      },
      {
        title: "common.visits.menu.visit-list",
        icon: "calendar",
        to: RouteNames.VisitList,
        canSee: this.permissions.isReceptionist(),
      },
      {
        title: "common.visits.menu.visit-request",
        icon: "file",
        to: RouteNames.NewVisit,
        canSee: this.permissions.isVisitor() && this.permissions.isEnrolled(),
      },
      {
        title: "common.portal.management.iam-users",
        icon: "caret-square-right",
        to: RouteNames.PortalAccounts,
        canSee: this.permissions.canViewCompleteOnboardings(),
      },
      {
        title: "common.portal.management.audit-accesses",
        icon: "list-alt",
        to: RouteNames.AuditAccesses,
        canSee: this.permissions.isAuditor(),
      },
      {
        title: "common.visits.menu.my-profile",
        icon: "user",
        to: RouteNames.UserProfile,
        canSee: this.permissions.canViewProfile() && this.permissions.isEnrolled(),
      },
      {
        title: "common.portal.management.update-watchlist",
        icon: "file",
        to: RouteNames.UploadWatchlist,
        canSee: this.permissions.canUploadWatchlist(),
      },
      {
        title: "common.portal.management.check-interdictions",
        icon: "list-alt",
        to: RouteNames.CheckInterdictions,
        canSee: this.permissions.isAssistant() && !this.permissions.isUserManager() && this.isGambling,
      },
      {
        title: "common.portal.management.activate-users",
        icon: "list-alt",
        to: RouteNames.ActivateUsers,
        canSee: this.permissions.isAssistant() && !this.permissions.isUserManager() && this.isGambling,
      },
      {
        title: "common.portal.management.cancel-account-assistant",
        icon: "list-alt",
        to: RouteNames.AssistantAccountCancellation,
        canSee: this.permissions.isAssistant() && !this.permissions.isUserManager() && this.isGambling,
      },
    ];
  }

  public isCurrentRoute(destination: string) {
    return destination === this.$route.name;
  }

  public async logout(e: Event): Promise<void> {
    if (e !== undefined) {
      e.preventDefault();
    }

    this.$appStore.actions.cleanAll();
    await this.$services.authentication.logout();
  }
}
