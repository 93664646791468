import { ServerResponseType } from "./server";
import { Deserializer } from "./serializers";

export interface ResponseTypeConfig<Data, Dto> {
  accept: string;
  responseType: ServerResponseType;
  parseResponse: (dto: Dto) => Data;
}

export const ResponseType = {
  image: (): ResponseTypeConfig<Blob, Blob> => ({
    accept: "image/png",
    responseType: "blob",
    parseResponse: blob => blob as Blob,
  }),
  pdf: (): ResponseTypeConfig<Blob, Blob> => ({
    accept: "application/pdf",
    responseType: "blob",
    parseResponse: blob => blob as Blob,
  }),
  json: <Data, Dto>(deserializer?: Deserializer<Data, Dto>): ResponseTypeConfig<Data, Dto> => ({
    accept: "application/json",
    responseType: "json",
    parseResponse: dto => (deserializer ? deserializer.parse(dto) : (dto as unknown as Data)),
  }),
};

export type MapResponseLiterals = Exclude<keyof typeof ResponseType, "json">;
export type MapResponseTypeMapper = {
  image: Blob;
  pdf: Blob;
};
