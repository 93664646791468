import Vue from "vue";
import { Observable, of } from "rxjs";
import { INotification, INotificationService, NotificationType } from "@/legacy/interfaces/notification.service";

export class NotificationService extends Vue implements INotificationService {
  private notifications$: Observable<INotification[]>;
  private notifications: INotification[] = [];
  private regularTimeoutMs = 2000;
  private errorTimeoutMs = 5000;

  constructor() {
    super();
    this.notifications$ = of(this.notifications);
  }

  info(message: string) {
    this.add(message, NotificationType.Info, this.regularTimeoutMs);
  }

  success(message: string) {
    this.add(message, NotificationType.Success, this.regularTimeoutMs);
  }

  warning(message: string) {
    this.add(message, NotificationType.Warning, this.errorTimeoutMs);
  }

  error(message: string) {
    this.add(message, NotificationType.Error, this.errorTimeoutMs);
  }

  destroy(notification: INotification): void {
    const index = this.notifications.indexOf(notification);

    if (index >= 0) {
      this.notifications.splice(index, 1);
    }
  }

  destroyAllErrors(): void {
    for (let index = 0; index < this.notifications.length; index++) {
      const notificationType = this.notifications[index].type;

      if (notificationType === NotificationType.Error || notificationType === NotificationType.Warning) {
        this.notifications.splice(index, 1);
      }
    }
  }

  public subscribe(next: (value: INotification[]) => void) {
    return this.notifications$.subscribe(next);
  }

  private add(message: string, type: NotificationType, timeoutMs: number): void {
    const notification: INotification = { message, type };

    this.notifications.unshift(notification);
    setTimeout(() => this.destroy(notification), timeoutMs);
  }
}
