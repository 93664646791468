import axios from "axios";

export const headerInterceptor = () => {
  axios.interceptors.request.use(
    (config: any) => {
      return config;
    },
    (err: any) => {
      return Promise.reject(err);
    }
  );
};
