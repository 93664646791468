import { asFunction, asValue } from "@/_di/resolvers";
import { PortrayalRepository } from "@/core/portrayal/domain/portrayalRepository";
import { apiPortrayalRepository } from "@/core/portrayal/infrastructure/apiPortrayalRepository";
import { portrayalUseCases, PortrayalUseCases } from "@/core/portrayal/useCases/portrayalUseCases";
import { PortrayalStorage } from "@/core/portrayal/domain/portrayalStorage";
import { appStorePortrayalStorage } from "@/core/portrayal/infrastructure/appStorePortrayalStorage";

export const portrayalModule = {
  portrayalStorage: asValue<PortrayalStorage>(appStorePortrayalStorage),
  portrayalRepository: asFunction<PortrayalRepository>(apiPortrayalRepository),
  portrayalUseCases: asFunction<PortrayalUseCases>(portrayalUseCases),
};
