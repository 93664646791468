import i18n from "@/legacy/infrastructure/i18n";
import {
  TakeSelfieAliveOptions,
  TakeSelfieAliveService,
} from "@/legacy/features/onboarding/interfaces/take-selfie-alive.service";
import { Log } from "@/legacy/infrastructure/logger";

declare function makeVDAliveWidget(): any;
declare function destroyVDAliveWidget(): void;
interface ImageDetectionEvent extends Event {
  detail: string;
}

declare global {
  interface Window {
    restartChallenge: any;
  }
}

export class VeridasTakeSelfieAliveService implements TakeSelfieAliveService {
  public take(options: TakeSelfieAliveOptions): void {
    try {
      const VDAlive = makeVDAliveWidget();
      const veridasOptions: Record<string, any> = {
        targetSelector: options.containerSelector,
        pathModels: "/sdk/alive/models/",
        ngas_images_path: "/sdk/alive/gifs/",
        webrtcUnsupportedText: i18n.t("common.onboarding.commonScan.webrtcUnsupportedText").toString(),
        repeatText: i18n.t("common.onboarding.commonScan.repeatText").toString(),
        continueText: i18n.t("common.onboarding.commonScan.continueText").toString(),
        infoUserAliveButtonText: i18n.t("common.onboarding.commonScan.infoUserAliveButtonText").toString(),
        infoReviewImageText: i18n.t("common.onboarding.takeSelfie.infoReviewImageText").toString(),
        smileRequestSmile: i18n.t("common.onboarding.takeSelfie.smileRequestSmile").toString(),
        smileRequestSerious: i18n.t("common.onboarding.takeSelfie.smileRequestSerious").toString(),
        fitYourFace: i18n.t("common.onboarding.takeSelfie.fitYourFace").toString(),
        not_move: i18n.t("common.onboarding.takeSelfie.notMove").toString(),
        center_face: i18n.t("common.onboarding.takeSelfie.centerFace").toString(),
        message_finish_challenge: i18n.t("common.onboarding.takeSelfie.messageFinishChallenge").toString(),
        message_middle_center: i18n.t("common.onboarding.takeSelfie.messageMiddleCenter").toString(),
        message_alive_light: i18n.t("common.onboarding.takeSelfie.messageAliveLight").toString(),
        message_alive_bold: i18n.t("common.onboarding.takeSelfie.messageAliveBold").toString(),
        restartingErrorText: i18n.t("common.onboarding.takeSelfie.restartingErrorText").toString(),
        stepChallenge: i18n.t("common.onboarding.takeSelfie.stepChallenge").toString(),
        stepOfChallenge: i18n.t("common.onboarding.takeSelfie.stepOfChallenge").toString(),
        repeatButtonColor: "#ffffff",
        confirmationDialogTextColor: "#ffffff",
        confirmationColorTick: "#072B80",
        buttonBackgroundColorLightRepeat: "#072B80",
        buttonBackgroundColorDarkRepeat: "#072B80",
        buttonBackgroundColorLight: options.primaryColor,
        buttonBackgroundColorDark: options.primaryColor,
        displayErrors: true,
        sdkBackgroundColorInactive: "#072B80",
        borderColorCenteringAidInactive: "#ffffff",
        outerGlowCenteringAidDefault: "#072B80",
        confirmationDialogBackgroundColor: "#072B80",
        detectionMessageTextColorSelfie: "#ffffff",
        detectionMessageBackgroundColorSelfie: "#072B80",
        outerGlowCenteringAidDetecting: options.primaryColor,
        borderColorCenteringAidDetecting: options.primaryColor,
        infoAlertShow: false,
        showLogo: false,
        aliveChallenge: options.aliveChallenge,
        stepsChallengeColor: "#ffffff",
      };

      VDAlive(veridasOptions);

      const ref = options.container;

      ref.addEventListener(
        "VDAlive_unmounted",
        () => {
          options.onFinish();
        },
        false
      );

      ref.addEventListener(
        "VDAlive_prefaceDetection",
        async e => {
          const imageDetectionEvent = e as ImageDetectionEvent;
          options.onSelfie(imageDetectionEvent.detail);
        },
        false
      );

      ref.addEventListener(
        "VDAlive_faceDetection",
        async e => {
          const imageDetectionEvent = e as ImageDetectionEvent;
          options.aliveChallenge
            ? options.onSelfieAlivePro(imageDetectionEvent.detail)
            : options.onSelfieAlive(imageDetectionEvent.detail);
        },
        false
      );

      ref.addEventListener(
        "VDAlive_mountFailure",
        e => {
          Log.info("VDAlive error");
          Log.info(e);
          options.onError(true);
        },
        false
      );

      ref.addEventListener(
        "VDAlive_cameraFailure",
        () => {
          options.onError(false);
        },
        false
      );

      ref.addEventListener(
        "VDAlive_detectionTimeout",
        e => {
          Log.info("VDAlive error");
          Log.info(e);
          options.onError(true);
        },
        false
      );

      ref.addEventListener(
        "VDAlive_mounted",
        () => {
          options.onStart();
        },
        false
      );

      ref.addEventListener(
        "VDAlive_restartChallenge",
        async () => {
          const challenge = await options.onCreateAliveChallenge();
          window.restartChallenge(challenge);
        },
        false
      );
    } catch (e) {
      Log.info("VDAlive error");
      Log.info(e);
      options.onError(true);
    }
  }

  public cancel(): void {
    destroyVDAliveWidget();
  }
}
