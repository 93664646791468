import { Serializer } from "@/core/shared/api/domain/serializers";
import { RegisterMode, UserData } from "@/core/user/domain/userRepository";

interface UserCreateDto {
  mode: "auto" | "assisted";
  claims: { user_type?: string; email?: string };
  action: "confirm";
}

export const userDataSerializer: Serializer<{ userData: UserData; registerMode: RegisterMode }, UserCreateDto> = {
  encode: data => ({
    mode: data.registerMode,
    claims: {
      user_type: data.userData.userType,
      email: data.userData.email,
    },
    action: "confirm",
  }),
};
