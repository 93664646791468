import { ActionContext, ActionTree } from "vuex";
import RootState from "@/legacy/store/rootState";
import { VisitSearchState } from "./state";
import { VisitSearchMutationsType } from "./contract";
import { GetVisitsRequest } from "../models/visits.models";

const actions: ActionTree<VisitSearchState, RootState> = {};

actions[VisitSearchMutationsType.SaveSearch] = (
  store: ActionContext<VisitSearchState, RootState>,
  payload: {
    filterdata: GetVisitsRequest;
    bookmarks: string[];
  }
) => {
  store.commit(VisitSearchMutationsType.SaveSearch, payload);
};

export default actions;
