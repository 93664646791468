import { GetterTree } from "vuex";
import RootState from "@/legacy/store/rootState";

import { UserSearchState } from "./state";
import { UserSearchGettersType } from "./contract";

const getters: GetterTree<UserSearchState, RootState> = {};

getters[UserSearchGettersType.Bookmarks] = (userSearchState: UserSearchState) => userSearchState.bookmarks;
getters[UserSearchGettersType.FilterData] = (userSearchState: UserSearchState) => userSearchState.filterdata;

export default getters;
