export enum PortrayalResultErrors {
  BlurredImageError = "portrayal-result/blurred-image-error",
  DocumentClassificationError = "portrayal-result/document-classification-error",
  SpoofedImageError = "portrayal-result/spoofed-image-error",
  ValidasError = "portrayal-result/validas-error",
  Timeout = "portrayal-result/timeout",
}

export enum SendSelfieErrors {
  BlurredImageError = "portrayal-result/blurred-image-error",
  DocumentClassificationError = "portrayal-result/document-classification-error",
  ValidasError = "portrayal-result/validas-error",
}
