import { MutationTree } from "vuex";
import { SettingsState } from "@/legacy/store/settings/state";
import { SettingsMutationsType } from "@/legacy/store/settings/contract";
import { TenantSettings } from "@/legacy/models/settings";

const mutations: MutationTree<SettingsState> = {};

mutations[SettingsMutationsType.SetSettings] = (state: SettingsState, settings: TenantSettings) => {
  state.assetsUrl = settings.assetsUrl;
  state.biometricQR = settings.biometricQR; // TODO: try to remove by asking API for settings?
  state.acsIdSchema = settings.acsIdSchema;
  state.assistantRegisterEnabled = settings.assistantRegisterEnabled;
  state.tenant = settings.tenant;
  state.domain = settings.domain;
  state.title = settings.title;
  state.userRegisterEnabled = settings.userRegisterEnabled;
  state.auditAccessesLevel = settings.auditAccessesLevel;
  state.sapConfigAuto = settings.sapConfigAuto;
  state.sapConfigAssisted = settings.sapConfigAssisted;
  state.exclusiveDoc = settings.exclusiveDoc;
  state.askFieldForDelete = settings.askFieldForDelete;
  state.customImages = settings.customImages;
  state.knowMoreUrl = settings.knowMoreUrl;
  if (settings.registerTypes !== undefined) {
    state.registerTypes = settings.registerTypes;
  }
  if (settings.bmsServices !== undefined) {
    state.bmsServices = settings.bmsServices;
  }
  if (settings.visits !== undefined) {
    state.visits.locations = settings.visits.locations; // TODO: remove unused?
    state.visits.mustAcceptPlan = settings.visits.mustAcceptPlan;
  }
  state.watchlistDomains = settings.watchlistDomains;
  if (settings.postEnrolmentAction) {
    state.postEnrolmentAction = settings.postEnrolmentAction;
  }
  state.useWatch = settings.useWatch;
  state.onboardingSteps = settings.onboardingSteps;
  state.showNextSteps = settings.showNextSteps;
  state.extendedInterdictionForm = settings.extendedInterdictionForm;
};

export default mutations;
