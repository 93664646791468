import Vue from "vue";
import Component from "vue-class-component";
import { DgButton, DgColumn, DgGridContainer, DgGridContent, DgImage, DgText } from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";

@Component({ components: { DgText, DgButton, DgColumn, DgImage, DgGridContainer, DgGridContent } })
export default class Landing extends Vue {
  onContinue() {
    this.$execute(this.$container.userUseCases.enroll, { fallbackLocation: { name: RouteNames.MyAccreditations } });
  }
}
