import { ColorService } from "@/legacy/interfaces/color.service";

const getColor = (variable: string, fallback: string): string => {
  if (getComputedStyle) {
    const color = getComputedStyle(document.documentElement).getPropertyValue(variable);
    if (color) {
      return color;
    }
  }

  return fallback;
};

export class CssVariableColorService implements ColorService {
  public getPrimaryColor: () => string = () => getColor("--color-brand", "#0D55FF");
}
