import { ApiClient, ApiError, StatusCodes } from "@/core/shared/api/domain/apiClient";
import { PortrayalRepository } from "@/core/portrayal/domain/portrayalRepository";
import { portrayalResultDeserializer } from "@/core/portrayal/infrastructure/portrayalResultDeserializer";
import { startedPortrayalDeserializer } from "@/core/portrayal/infrastructure/startedPortrayalDeserializer";
import { portrayalStartRequestSerializer } from "@/core/portrayal/infrastructure/portrayalStartRequestSerializer";
import { portrayalResultErrorMapper } from "@/core/portrayal/infrastructure/portrayalResultErrorMapper";
import { PortrayalResultErrors } from "@/core/portrayal/domain/portrayalErrors";
import { sendSelfieErrorMapper } from "@/core/portrayal/infrastructure/sendSelfieErrorMapper";

export const apiPortrayalRepository = ({ apiClient }: { apiClient: ApiClient }): PortrayalRepository => ({
  start: async portrayalStartRequest =>
    await apiClient.post("/internal/portrayals", portrayalStartRequest, {
      mapResponse: startedPortrayalDeserializer,
      mapRequest: portrayalStartRequestSerializer,
    }),
  sendSelfie: async ({ portrayalId, selfieImage, aliveImage }) => {
    return await apiClient.put(
      `/internal/portrayals/${portrayalId}/selfie`,
      {
        content: selfieImage,
        life_proofing: aliveImage,
      },
      {
        mapErrors: sendSelfieErrorMapper,
      }
    );
  },
  sendLifeProof: async ({ portrayalId, formData }) => {
    return await apiClient.put(`/internal/portrayals/${portrayalId}/lifeproof`, formData, {
      mapRequest: "formData",
    });
  },
  getResult: async portrayalId =>
    await waitForApiResult(
      async () =>
        await apiClient.get(`/internal/portrayals/${portrayalId}`, {
          mapResponse: portrayalResultDeserializer,
          mapErrors: portrayalResultErrorMapper,
        })
    ),
});

const waitForApiResult = <Response>(apiCall: () => Promise<Response>): Promise<Response> => {
  const intervalInMilliseconds = 2000;
  const timeoutInMilliseconds = 16000;
  let interval: ReturnType<typeof setTimeout>;
  let timeout: ReturnType<typeof setTimeout>;

  return new Promise((resolve, reject) => {
    interval = setInterval(async () => {
      try {
        const response = await apiCall();
        resolve(response);
        interval && clearInterval(interval);
        timeout && clearTimeout(timeout);
      } catch (error) {
        if ((error as ApiError).isApiError) {
          const errorApi = error as ApiError;
          if (errorApi.statusCode !== StatusCodes.NOT_FOUND) {
            interval && clearInterval(interval);
            timeout && clearTimeout(timeout);
            reject(error);
          }
        }
      }
    }, intervalInMilliseconds);

    timeout = setTimeout(() => {
      interval && clearInterval(interval);
      reject(
        new ApiError(StatusCodes.REQUEST_TIMEOUT, {
          reason: PortrayalResultErrors.Timeout,
          message: "",
          detail: undefined,
        })
      );
    }, timeoutInMilliseconds);
  });
};
