import { Credentials } from "@/legacy/features/authentication/credentials.models";

const state: Credentials = {
  userName: "",
  access_token: "",
  roles: [],
  profile: "",
  userId: "",
  email: "",
  enrolled: false,
};

export default state;
