import { RegisterMode, RegisterType } from "@/legacy/models/shared";

export interface OnboardingState {
  onboardingId: string;
  identityPortrayalId: string;
  documentType: string;
  documentCountry: string;
  registerType: RegisterType;
  registerMode: RegisterMode;
  lifeProofing: boolean;
  biometricQR: boolean;
  assistedUserId: string;
  assistedEmail: string;
  supportedDocs: { [key: string]: string[] };
  confirmData: any;
}

const state: OnboardingState = {
  onboardingId: "",
  identityPortrayalId: "",
  documentType: "none",
  documentCountry: "",
  registerType: RegisterType.None,
  registerMode: "auto",
  lifeProofing: false,
  biometricQR: false,
  assistedUserId: "",
  assistedEmail: "",
  supportedDocs: {},
  confirmData: {},
};

export default state;
