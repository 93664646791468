import Vue from "vue";

const EventBus = new Vue();
export default EventBus;

export function emitServerErrors(errors: { [id: string]: string }) {
  EventBus.$emit(EnumEventBus.serverErrors, errors);
}
export enum EnumEventBus {
  serverErrors = "serverError",
}
