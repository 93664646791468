import { IdentityPortrayalType, RegisterType } from "@/legacy/models/shared";

export type IdentityPortrayalRawDocumentType =
  | "id_card"
  | "passport"
  | "residence_permit"
  | "others"
  | "driving_license"
  | "none";
export type IdentityPortrayalRawType = "document" | "selfie";
export type IdentityPortrayalRawDocumentCountry = "ES" | "FR" | "XX";

export const getIdentityPortrayalRawType = (param: IdentityPortrayalType): IdentityPortrayalRawType => {
  if (param === IdentityPortrayalType.document) {
    return "document";
  }

  if (param === IdentityPortrayalType.selfie) {
    return "selfie";
  }

  return "document";
};

export const getRegisterType = (param: boolean): RegisterType => {
  if (param) {
    return RegisterType.WithIdentityProofing;
  }
  return RegisterType.OnlySelfieWithId;
};
