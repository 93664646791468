import {
  DocumentManualData,
  DocumentOcrData,
  DynamicData,
  NoDocumentData,
  NoDocumentDataWithId,
  RegisterType,
} from "@/legacy/models/shared";
import Vue from "vue";
import { redirectUser } from "@/legacy/features/common/shared";
import { BaseAssetsUrl } from "@/legacy/models/constants";
import axios from "axios";

export interface AcceptTermsRequest {
  onboardingId: string;
}

export interface StopOnboardingRequest {
  onboardingId: string;
}

export interface StartOnboardingRequest {
  mode: "auto" | "assisted";
  location?: string;
  userId?: string;
}

export interface StartOnboardingResponse {
  onboardingId: string;
  registerType: RegisterType;
  lifeProofing: boolean;
  biometricQR: boolean;
  supportedDocs: string;
}

export interface ConfirmOnboardingRequest {
  onboardingId: string;
  identityPortrayalId: string;
  data: DocumentOcrData | DocumentManualData | NoDocumentData | NoDocumentDataWithId | DynamicData;
}

export enum ConfirmOnboardingResponseErrorCode {
  Unknown = "confirm-onboarding-error.unknown",
  UnderAge = "confirm-onboarding-error.underage",
  DocumentExpired = "confirm-onboarding-error.document-expired",
  ForbiddenList = "confirm-onboarding-error.forbidden-list",
  LifeProofingFailed = "confirm-onboarding-error.life-proofing-failed",
  AlreadyRegistered = "confirm-onboarding-error.already-registered",
  DocumentAlreadyInUse = "confirm-onboarding-error.document-already-in-use",
  EnforcementSourceUnavailable = "confirm-onboarding-error.enforcement-source-unavailable",
}

export interface ConfirmOnboardingResponseOk {
  success: true;
  userId: string;
  qrId?: string;
}

export interface ConfirmOnboardingResponseKo {
  success: false;
  code: ConfirmOnboardingResponseErrorCode;
  retry: boolean;
}

export interface GetAcsidStatusRequest {
  acsid: string;
}

export interface GetUserOnboardingRequest {
  onboardingId: string;
}

export type ConfirmOnboardingResponse = ConfirmOnboardingResponseOk | ConfirmOnboardingResponseKo;

export const cancelOnboarding = async () => {
  const onboardingId = Vue.prototype.$appStore.getters.onboardingId;
  await Vue.prototype.$services.onboardingService.stop({ onboardingId });
  Vue.prototype.$appStore.actions.resetOnboarding();
  await redirectUser();
};

export const getSupportedDocs = async (docsProfile: string) => {
  const docFile = `${BaseAssetsUrl}.supported_docs/${docsProfile}.json`;
  const response = await axios.get(docFile);
  return response.data;
};

export const startOnboarding = async () => {
  const saloon = localStorage.getItem("saloon");
  const mode = Vue.prototype.$appStore.getters.registerMode;
  // TODO: catch conflict to check 409??
  const startOnboardingResponse = await Vue.prototype.$services.onboardingService.start({
    mode,
    location: saloon,
  });
  localStorage.removeItem("documentData");
  const docs = await getSupportedDocs(startOnboardingResponse.supportedDocs);
  Vue.prototype.$appStore.actions.startOnboarding(
    startOnboardingResponse.onboardingId,
    startOnboardingResponse.registerType,
    startOnboardingResponse.lifeProofing,
    startOnboardingResponse.biometricQR,
    docs
  );
};
