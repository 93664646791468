import Vue from "vue";
import { NavigationGuard } from "vue-router";
import { Guard } from "@/ui/_router/guards/guards";
import { authPermissions, AuthRepository } from "@/core/user/domain/auth";
import { isPromise } from "@/core/shared/utils";

export const guardFactory =
  ({ authRepository }: { authRepository: AuthRepository }): ((guard: Guard) => NavigationGuard) =>
  (guard: Guard) =>
  async (to, from, next) => {
    const result = guard(to, from, authPermissions(authRepository.get()));
    const location = isPromise(result) ? await result : result;
    if ("login" === location) {
      (Vue.prototype as Vue).$services.authentication.login();
      return;
    }

    location ? next(location) : next();
  };
