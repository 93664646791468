import { ServerRequestConfig } from "../domain/server";
import { ResponseTypeConfig } from "../domain/responseTypeConfig";
import { RequestTypeConfig } from "../domain/requestTypeConfig";

export const encodeServerRequestConfig = <ResponseData, ResponseDto, RequestData, RequestDto>({
  responseTypeConfig,
  requestTypeConfig,
  accessToken,
}: {
  responseTypeConfig: ResponseTypeConfig<ResponseData, ResponseDto>;
  requestTypeConfig: RequestTypeConfig<RequestData, RequestDto>;
  accessToken?: string;
}): Partial<ServerRequestConfig<RequestDto>> => ({
  responseType: responseTypeConfig.responseType,
  headers: {
    accept: responseTypeConfig.accept,
    "Content-Type": requestTypeConfig.contentType,
    Authorization: "Bearer " + accessToken,
  },
});
