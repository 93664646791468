export type RegisterMode = "auto" | "assisted";

export enum RegisterType {
  None = "none",
  WithIdentityProofing = "WithIdentityProofing",
  OnlySelfieWithId = "OnlySelfieWithId",
  Single = "Single",
  Auto = "Auto",
  Assisted = "Assisted",
  AssistedOtherDocuments = "AssistedOtherDocuments",
  AssistedExistingUsers = "AssistedExistingUsers",
}

export enum IdentityPortrayalDocumentCountry {
  spain = "ES",
  france = "FR",
  anyCountry = "XX",
}

export enum IdentityPortrayalType {
  document = "document",
  selfie = "selfie",
}

export interface DocumentOcrData {
  firstName: string;
  lastName: string;
  documentId?: string | undefined;
  documentNumber?: string | undefined;
  expiryDate: Date | null;
  birthDate: Date | null;
  expeditionCountry: string;
  nationality: string;
  documentType: string;
  email?: string;
}

export interface DocumentManualData extends DocumentOcrData {
  documentTypeDescription: string;
  nie: string;
}

export interface NoDocumentData {
  id: string;
  email: string;
}

export interface NoDocumentDataWithId {
  acs_id: string;
  id: string;
  email: string;
}

export interface ProcessedImageData {
  selfie: number;
  selfieOk: boolean;
  dataValidationOk?: boolean;
  document: number;
  documentOk: boolean;
  selfieAuthentic: boolean;
}

export interface PagedRequest {
  pageSize: number;
  pageIndex: number;
  bookmark: string;
}

export type OrderByRequest = "ASC" | "DESC";

export interface PagedResult<T> {
  pageSize: number;
  totalPages: number;
  totalItems: number;
  items: T[];
  bookmark: string;
}

export interface BiometricQrData {
  email: string;
  deliver_by_mail: boolean;
}

export interface SingleDynamicDocumentData extends BiometricQrData {
  fields: { [key: string]: string };
  selfie: string;
}

export interface DynamicData extends BiometricQrData {
  fields: { [key: string]: string };
  extraFields: { [key: string]: string };
  ocr?: string;
}

export interface OnboardingInfo {
  registerId: string;
  registerType: string;
  registerDate?: Date;
  lifeProofing: false;
  identityProofing: boolean;
  modifiedFields?: any;
  assistedBy?: string;
  host?: string;
  visitDate?: Date;
}

export interface UserImages {
  selfie?: string;
  obverse?: string;
  reverse?: string;
}

export interface GamblingAditionalData {
  phone?: string;
  email?: string;
}
