import Vue from "vue";
import VuexPersistence from "vuex-persist";
import Vuex, { StoreOptions } from "vuex";

import actions from "@/legacy/store/actions";
import getters from "@/legacy/store/getters";
import mutations from "@/legacy/store/mutations";
import state from "@/legacy/store/state";
import authModule from "@/legacy/features/authentication/store";
import RootState from "@/legacy/store/rootState";
import onboardingModule from "@/legacy/features/onboarding/store";
import portalModule from "@/legacy/features/portal/store";
import visitModule from "@/legacy/features/visits/store";
import settingsModule from "@/legacy/store/settings";

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ["auth", "onboarding"],
});

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state,
  actions,
  mutations,
  getters,
  modules: {
    auth: authModule,
    onboarding: onboardingModule,
    settings: settingsModule,
    portal: portalModule,
    visits: visitModule,
  },
  plugins: [vuexLocal.plugin],
};

export default new Vuex.Store<RootState>(store);
