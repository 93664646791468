import { ActionContext, ActionTree } from "vuex";
import RootState from "@/legacy/store/rootState";
import { UserSearchState } from "./state";
import { UserSearchMutationsType } from "./contract";
import { GetUsersRequest } from "../models/portal.models";

const actions: ActionTree<UserSearchState, RootState> = {};

actions[UserSearchMutationsType.SaveSearch] = (
  store: ActionContext<UserSearchState, RootState>,
  payload: {
    filterdata: GetUsersRequest;
    bookmarks: string[];
  }
) => {
  store.commit(UserSearchMutationsType.SaveSearch, payload);
};

export default actions;
