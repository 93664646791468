import { Accreditation, AccreditationRequest, AccreditationType } from "../domain/accreditation";
import { AccreditationRepository } from "../domain/accreditationRepository";
import { ApiClient, apiResponse, isApiError } from "@/core/shared/api/domain/apiClient";
import { accreditationDeserializer, accreditationDeserializers } from "./accreditationDeserializer";
import { accreditationRequestSerializer } from "./accreditationRequestSerializer";
import { accreditationsDeserializer } from "./accreditationsDeserializer";
import { accreditationRequestErrorMapper } from "@/core/accreditation/infrastructure/accreditationRequestErrorMapper";
import { AccreditationRequestErrors } from "@/core/accreditation/domain/accreditationErrors";

export const apiAccreditationRepository = ({ apiClient }: { apiClient: ApiClient }): AccreditationRepository => ({
  get: async ({ accreditationId, userId }) => {
    const { data: accreditation } = await apiClient.get(`/public/credentials/${userId}/sids/${accreditationId}`, {
      mapResponse: accreditationDeserializer,
    });
    return accreditation;
  },
  getImage: async ({ accreditationId, userId }) => {
    const { data: image } = await apiClient.get(`/public/credentials/${userId}/sids/${accreditationId}`, {
      mapResponse: "image",
    });

    return image;
  },
  getPdf: async ({ accreditationId, userId }) => {
    const { data: pdf } = await apiClient.get(`/public/credentials/${userId}/sids/${accreditationId}`, {
      mapResponse: "pdf",
    });

    return pdf;
  },
  getAll: async userId => {
    const { data: accreditations } = await apiClient.get(`/public/credentials/${userId}`, {
      mapResponse: accreditationsDeserializer,
    });

    return accreditations;
  },
  request: async <Type extends AccreditationType>(accreditationRequest: AccreditationRequest<Type>) => {
    try {
      return await apiClient.post("/internal/rights", accreditationRequest, {
        mapRequest: accreditationRequestSerializer,
        mapResponse: accreditationDeserializers[accreditationRequest.type],
        mapErrors: accreditationRequestErrorMapper,
      });
    } catch (error) {
      if (isApiError(error) && error.data?.reason === AccreditationRequestErrors.CredentialAlreadyExist) {
        return apiResponse(error.data.detail as Accreditation<Type>, error.link);
      }
      throw error;
    }
  },
  refresh: async ({ accreditationId, userId }) =>
    await apiClient.put(
      `/public/credentials/${userId}/sids/${accreditationId}/state`,
      { state: "active" },
      { mapResponse: accreditationDeserializer }
    ),
  revoke: async ({ accreditationId, userId }) =>
    await apiClient.put(`/public/credentials/${userId}/sids/${accreditationId}/state`, { state: "revoked" }),
});
