import { default as isEqual } from "lodash/isEqual";
import { default as isUndefined } from "lodash/isUndefined";
import { default as isEmpty } from "lodash/isEmpty";
import { default as isFunction } from "lodash/isFunction";
import { default as isString } from "lodash/isString";
import { default as isObject } from "lodash/isObject";

const isDefined = <T>(value: T | undefined): value is T => !isUndefined(value);
const has = <T extends string>(variable: unknown, property: T): variable is { [property in T]: any } =>
  isObject(variable) && property in variable;

const isPromise = (obj: any): obj is Promise<any> =>
  !!obj && (typeof obj === "object" || typeof obj === "function") && typeof obj.then === "function";

export { isDefined, isUndefined, isEqual, isEmpty, has, isFunction, isString, isObject, isPromise };
