import { ActionContext, ActionTree } from "vuex";
import { RootActions, RootMutations } from "./enums";
import RootState from "./rootState";

const actions: ActionTree<RootState, any> = {};

actions[RootActions.SetRequestLoading] = (store: ActionContext<RootState, RootState>, loadingState: boolean) => {
  store.commit(RootMutations.SetRequestLoading, loadingState);
};

actions[RootActions.SetRedirect] = (store: ActionContext<RootState, RootState>, redirect: string) => {
  store.commit(RootMutations.SetRedirect, redirect);
};
export default actions;
