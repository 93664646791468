import Vue from "vue";
import {
  DocumentOcrData,
  IdentityPortrayalDocumentCountry,
  IdentityPortrayalType,
  ProcessedImageData,
} from "../../../models/shared";

export interface StartIdentityPortrayalRequest {
  userId: string;
  onboardingId: string;
  portrayalType: IdentityPortrayalType;
  documentType: "id_card" | "passport" | "residence_permit" | "others" | "none" | "driving_license";
  documentCountry: IdentityPortrayalDocumentCountry;
}

export interface StartIdentityPortrayalResponse {
  identityPortrayalId: string;
}

export interface StopIdentityPortrayalRequest {
  identityPortrayalId: string;
}

export interface IdentityPortrayalImageRequest {
  identityPortrayalId: string;
  imageType: "document-obverse" | "document-reverse" | "selfie" | "alive" | "pro";
  image?: string;
  alive?: string;
  formData?: FormData;
}

export interface GetIdentityPortrayalRequest {
  identityPortrayalId: string;
}

export interface GetIdentityPortrayalResponse {
  done: boolean;
  ocr: DocumentOcrData;
  scores: ProcessedImageData;
  documentInfo?: boolean;
}

export function getCountryISOString(country: string, documentType: string): string {
  if (documentType === "passport" || documentType === "others") {
    return IdentityPortrayalDocumentCountry.anyCountry;
  }
  return country.toUpperCase();
}

export async function createPortrayal(documentType: string, country: string, userId: string): Promise<void> {
  const onboardingId = Vue.prototype.$appStore.getters.onboardingId;
  const portrayalType = documentType === "none" ? IdentityPortrayalType.selfie : IdentityPortrayalType.document;

  const documentCountry = getCountryISOString(country, documentType);

  const response = await Vue.prototype.$services.identityPortrayalService.start({
    userId,
    portrayalType,
    documentCountry,
    onboardingId,
    documentType,
  });

  Vue.prototype.$appStore.actions.startIdentityPortrayal({
    identityPortrayalId: response.identityPortrayalId,
    documentType,
    documentCountry,
  });
}
