import { Component, Vue } from "vue-property-decorator";
import { DgToasters, Notification } from "@dasgate/uikit";
import { Version } from "@/legacy/models/constants";
import { INotification } from "@/legacy/interfaces/notification.service";

@Component({
  components: {
    DgToasters,
  },
})
export default class App extends Vue {
  public toasters: INotification[] = [];

  public mounted() {
    this.$services.notificationService.subscribe(notification => (this.toasters = notification.reverse()));
    console.info("Welcome UI version " + Version);
  }

  public onToasterClose(notification: Notification) {
    this.$services.notificationService.destroy(notification);
  }
}
