import { getTenant } from "@/legacy/services/configuration.service";
import { BaseAssetsUrl } from "@/legacy/models/constants";
import { PluginFunction } from "vue";

export interface ResourceProvider {
  resource: (path: string) => string;
}

export const resourceProviderPlugin: PluginFunction<void> = (vue: any) => {
  vue.prototype.$resourceProvider = resourceProvider(vue);
};

const resourceProvider = (vue: any): ResourceProvider => ({
  resource: (path: string) => {
    const useCustomResources = vue.prototype.$appStore.getters.settings.customImages;

    return useCustomResources ? `${BaseAssetsUrl}${getTenant()}/theme/${path}` : require(`@/assets/${path}`);
  },
});
