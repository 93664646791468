import { AccessesService } from "../interfaces/accesses-service";
import {
  Access,
  DownloadAccessesRequest,
  GamblingAccess,
  GetAccessesRequest,
  GetAccessesResponse,
} from "../models/portal.models";
import { getHttpClient } from "@/legacy/features/authentication/auth-http-client.service";
import { AxiosRequestConfig } from "axios";
import qs from "qs";
import moment from "moment";
import i18n from "@/legacy/infrastructure/i18n";

interface GamblingAccessRaw {
  name: string;
  surname: string;
  birth_date: string;
  document_type: string;
  document_number: string;
  id_number: string;
  registration_date: Date;
  watchlists_check_mode: string;
  watchlists_checked: string[];
  enforcement_source: string;
}

interface AccessRaw {
  created_at: string;
  user_id: string;
  doc_type: string;
  facility: string;
  entity: GamblingAccessRaw;
}

interface GetAccessesRawResponse {
  page_size: number;
  total_pages: number;
  total_items: number;
  items: AccessRaw[];
  bookmark: string;
  counts?: any;
}

export class HttpAccessesService implements AccessesService {
  public async auditAccesses(request: GetAccessesRequest): Promise<GetAccessesResponse> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.get<GetAccessesRawResponse>(`/public/audit_accesses`, {
      params: request,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    const toReturn = {
      items: rawResponse.data.items.map(element => this.mapAccess(element)),
      totalPages: rawResponse.data.total_pages,
      totalItems: rawResponse.data.total_items,
      pageSize: rawResponse.data.page_size,
      bookmark: rawResponse.data.bookmark,
    } as GetAccessesResponse;
    if (rawResponse.data.counts) {
      toReturn.counts = rawResponse.data.counts;
    }
    return toReturn;
  }

  public mapAccess(element: AccessRaw) {
    return {
      createdAt: element?.created_at
        ? moment(new Date(element?.created_at)).format(i18n.t("tenant.dateHourFormat").toString())
        : "",
      userId: element?.user_id,
      accepted: element?.doc_type === "access",
      facility: element?.facility,
      entity: this.mapAccessEntity(element?.entity),
    } as Access;
  }

  public mapAccessEntity(element: GamblingAccessRaw) {
    const entity = {
      name: element?.name,
      surname: element?.surname,
      birthdate: element?.birth_date
        ? moment(new Date(element?.birth_date)).format(i18n.t("tenant.dateFormat").toString())
        : "",
      documentType: element?.document_type,
      documentNumber: element?.document_number,
      idNumber: element?.id_number,
      registrationDate: element?.registration_date
        ? moment(new Date(element?.registration_date)).format(i18n.t("tenant.dateFormat").toString())
        : "",
      watchlistsCheckMode: element?.watchlists_check_mode,
      watchlistsChecked: element?.watchlists_checked ? element?.watchlists_checked : [""],
    } as GamblingAccess;
    if (element?.enforcement_source) {
      entity.enforcementSource = element?.enforcement_source;
    }
    return entity;
  }

  public async downloadAccesses(request: DownloadAccessesRequest): Promise<any> {
    const httpClient = getHttpClient();
    const config: AxiosRequestConfig = {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await httpClient.post(`/public/audit_accesses`, request, config);
  }
}
