import { MutationTree } from "vuex";
import { AuthMutationsType } from "@/legacy/features/authentication/store/contract";
import { Credentials } from "../credentials.models";

const mutations: MutationTree<Credentials> = {};

mutations[AuthMutationsType.CredentialsStored] = (state: Credentials, credentials: Credentials) => {
  state.access_token = credentials.access_token;
  state.userName = credentials.userName;
  state.roles = credentials.roles;
  state.profile = credentials.profile;
  state.userId = credentials.userId;
  state.email = credentials.email;
};

mutations[AuthMutationsType.CredentialsRemoved] = (state: Credentials) => {
  state.access_token = "";
  state.userName = "";
  state.roles = [];
  state.profile = "";
  state.email = "";
  state.userId = "";
  state.enrolled = false;
};

mutations[AuthMutationsType.OnboardingStateStored] = (state: Credentials, enrolled: boolean) => {
  state.enrolled = enrolled;
};

mutations[AuthMutationsType.AccessTokenStored] = (state: Credentials, accessToken: string) => {
  state.access_token = accessToken;
};

export default mutations;
