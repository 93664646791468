import { GetUsersRequest } from "../models/portal.models";

export enum UserSearchMutationsType {
  SaveSearch = "saveSearch",
}

export enum UserSearchActionsType {
  SaveSearch = "saveSearch",
}

export enum UserSearchGettersType {
  FilterData = "filterData",
  Bookmarks = "bookmarks",
}

export interface UserSearchGetters {
  filterdata: GetUsersRequest;
  bookmarks: string[];
}

export interface UserSearchActions {
  saveUserSearch(filterdata: GetUsersRequest, bookmarks: string[]): void;
}
