import { EntityStorage } from "../domain/storage";
import { isNil } from "lodash";

export const entityStorageFactory = <T>(storage: Storage, key: string): EntityStorage<T> => ({
  get(): T | undefined {
    const value = storage.getItem(key);
    if (isNil(value)) {
      return undefined;
    }

    return JSON.parse(value);
  },
  set(value: T): void {
    const encodedValue = JSON.stringify(value);

    storage.setItem(key, encodedValue);
  },
  unset(): void {
    storage.removeItem(key);
  },
});
