import Vue from "vue";
import {
  DocumentManualData,
  DocumentOcrData,
  DynamicData,
  NoDocumentData,
  NoDocumentDataWithId,
} from "@/legacy/models/shared";
import {
  ConfirmOnboardingRequest,
  ConfirmOnboardingResponseErrorCode,
} from "@/legacy/features/onboarding/models/onboarding.models";
import {
  ConfirmOnboardingRawRequest,
  DynamicConfirmOnboardingRawRequestWithoutDocument,
} from "@/legacy/features/onboarding/services/http-onboarding.service";
import { formatter } from "@/ui/_utils/formatter";

export const encodeConfirmOnboardingRequest = (request: ConfirmOnboardingRequest) => {
  let rawRequest: ConfirmOnboardingRawRequest;

  if ("fields" in request.data) {
    rawRequest = encodeDynamicData(request.data);
  } else if ("nie" in request.data) {
    rawRequest = encodeDocumentManualData(request.data);
  } else if ("firstName" in request.data) {
    rawRequest = encodeDocumentOcrData(request.data);
  } else if ("acs_id" in request.data) {
    rawRequest = encodeNoDocumentDataWithId(request.data);
  } else {
    rawRequest = encodeNoDocumentData(request.data);
  }
  if (
    rawRequest.email &&
    Vue.prototype.$appStore.getters.settings.domain === "gambling" &&
    (Vue.prototype.$appStore.getters.registerMode === "auto" ||
      Vue.prototype.$appStore.getters.documentType === "others")
  ) {
    rawRequest.extra_fields = {
      ...(rawRequest.extra_fields ? (rawRequest.extra_fields as object) : {}),
      email: rawRequest.email,
    };
    delete rawRequest.email;
  }

  if (Vue.prototype.$appStore.getters.assistedEmail) {
    rawRequest.skip_email_check = true;
    rawRequest.email = Vue.prototype.$appStore.getters.assistedEmail;
  }

  return rawRequest;
};

const encodeDynamicData = (dynamicData: DynamicData) => {
  let rawRequest = {} as DynamicConfirmOnboardingRawRequestWithoutDocument;

  if (dynamicData.extraFields) {
    const extraFields: Record<string, string> = {};
    Object.entries(dynamicData.extraFields).forEach(([key, value]) => {
      extraFields[key] = value;
    });
    rawRequest.extra_fields = extraFields;
  }

  Object.entries(dynamicData.fields).forEach(([key, value]) => {
    if (key === "phone") {
      rawRequest.extra_fields = {
        ...(rawRequest.extra_fields ? (rawRequest.extra_fields as object) : {}),
        phone: value,
      };
      return;
    }
    rawRequest[key] = value;
  });

  const documentData = localStorage.getItem("documentData")
    ? JSON.parse(localStorage.getItem("documentData")!)
    : undefined;
  if (documentData) {
    rawRequest = {
      ...rawRequest,
      ...encodeDocumentOcrData(documentData),
    };
  }

  if (dynamicData.email) {
    rawRequest.email = dynamicData.email;
  }

  return rawRequest;
};

const encodeDocumentOcrData = (documentOcrData: DocumentOcrData) => ({
  first_name: documentOcrData.firstName,
  last_name: documentOcrData.lastName,
  birth_date: formatter.toISOUTCDateString(documentOcrData.birthDate as Date),
  expiry_date: formatter.toISOUTCDateString(documentOcrData.expiryDate as Date),
  ...(documentOcrData.documentId ? { id_number: documentOcrData.documentId } : {}),
  ...(documentOcrData.documentNumber ? { document_number: documentOcrData.documentNumber } : {}),
  document_type: documentOcrData.documentType,
  expedition_country: documentOcrData.expeditionCountry,
  nationality: documentOcrData.nationality,
  ...(documentOcrData.email ? { email: documentOcrData.email } : {}),
});

const encodeDocumentManualData = (documentManualData: DocumentManualData) => ({
  ...encodeDocumentOcrData(documentManualData),
  nie: documentManualData.nie,
  document_type: documentManualData.documentTypeDescription,
});

const encodeNoDocumentData = (noDocumentData: NoDocumentData) => ({
  id: noDocumentData.id,
  ...(noDocumentData.email ? { email: noDocumentData.email } : {}),
});

const encodeNoDocumentDataWithId = (noDocumentDataWithId: NoDocumentDataWithId) => ({
  username: noDocumentDataWithId.id,
  acs_id: noDocumentDataWithId.acs_id,
  ...(noDocumentDataWithId.email ? { email: noDocumentDataWithId.email } : {}),
});

export const parseErrorReason = (
  errorReason: string
): { errorCode: ConfirmOnboardingResponseErrorCode; retry: boolean } => {
  let errorCode = ConfirmOnboardingResponseErrorCode.Unknown;
  let retry = false;

  switch (errorReason) {
    case "underaged":
      errorCode = ConfirmOnboardingResponseErrorCode.UnderAge;
      break;
    case "user_interdicted":
      errorCode = ConfirmOnboardingResponseErrorCode.ForbiddenList;
      break;
    case "document_expired":
      errorCode = ConfirmOnboardingResponseErrorCode.DocumentExpired;
      break;
    case "life_proofing_failed":
      errorCode = ConfirmOnboardingResponseErrorCode.LifeProofingFailed;
      break;
    case "user_already_registered":
      errorCode = ConfirmOnboardingResponseErrorCode.AlreadyRegistered;
      break;
    case "document_already_exists":
      errorCode = ConfirmOnboardingResponseErrorCode.DocumentAlreadyInUse;
      break;
    case "enforcement_source_unavailable":
      errorCode = ConfirmOnboardingResponseErrorCode.EnforcementSourceUnavailable;
      retry = true;
      break;
  }

  return { errorCode, retry };
};
