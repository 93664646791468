import { IdentityPortrayalType, NoDocumentDataWithId, SingleDynamicDocumentData } from "@/legacy/models/shared";

export interface DelegatedNoDocumentDataWithId extends NoDocumentDataWithId {
  username: string;
  image: string;
  deliver_by_mail: boolean;
  acs_id: string;
}

export interface DelegatedOnboardingRequest {
  type: IdentityPortrayalType.selfie;
  data: DelegatedNoDocumentDataWithId | SingleDynamicDocumentData;
  acsIdSchema: string;
}

export enum DelegatedOnboardingResponseErrorCode {
  Unknown = "delegated-onboarding-error.unknown",
  DocumentExpired = "delegated-onboarding-error.document-expired",
  EmbeddingFailed = "delegated-onboarding-error.embedding-failed",
  IdAlreadyUsed = "delegated-onboarding-error.id-already-used",
  QrGenerationFailed = "delegated-onboarding-error.qr-generation-failed",
}

export interface DelegatedOnboardingResponseOk {
  success: true;
}

export interface DelegatedOnboardingResponseKo {
  success: false;
  code: DelegatedOnboardingResponseErrorCode;
}

export type DelegatedOnboardingResponse = DelegatedOnboardingResponseOk | DelegatedOnboardingResponseKo;

export interface BatchOnboardingRequest {
  onboardingFile: File;
  mediaFile: File;
  deliverByEmail: boolean;
  acsIdSchema: string;
}

export interface BatchOnboardingResponseOk {
  success: true;
  batchId: string;
}

export interface BatchOnboardingResponseKo {
  success: false;
  error: BatchOnboardingResponseErrorCode;
}

export type BatchOnboardingResponse = BatchOnboardingResponseOk | BatchOnboardingResponseKo;

export interface GetBatchStatusRequest {
  batchId: string;
}

export interface BatchProgress {
  total: number;
  failed: number;
  completed: number;
  rejected: number;
}

export enum BatchOnboardingResponseErrorCode {
  Unknown = 0,
  SelfieNotProvided = 1,
  ValidationFailed = 2,
  EmbeddingFailed = 3,
  ExistingAcsId = 4,
  EmailRequired = 5,
  InvalidEmail = 6,
  SelfieTooSmall = 7,
  SelfieTooBig = 8,
  MissingData = 9,
  MissingSelfie = 10,
  EmailInUse = 11,
}

export interface FailedBatchOnboardings {
  username: string;
  acs_id: string;
  reason: BatchOnboardingResponseErrorCode;
}

export interface BatchStatusResponse {
  batchProgress: BatchProgress;
  success: boolean;
  failedOnboardings: FailedBatchOnboardings[];
}

export interface GetAcsidStatusRequest {
  acsid: string;
}

export interface GetEmailStatusRequest {
  email: string;
}

export enum DelegatedOnboardingImageConstraints {
  MaxImageLength = 6990508,
  MinImageLength = 6828,
  DesiredImageLength = 6500000,
}

export enum BatchOnboardingFileConstraints {
  MaxFileLength = 1073741824,
}
