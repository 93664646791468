import deviceDetect from "mobile-device-detect";
import { IBrowserValidationService } from "@/legacy/interfaces/browser-validation.service";
import { BrowserSettings } from "@/legacy/models/settings";

export class BrowserValidationService implements IBrowserValidationService {
  public updatableBrowserMinVersion = 0;
  public currentBrowser = deviceDetect.browserName.replace(/\s/g, "").replace("/Browser/g", "");
  public currentBrowserVersion = deviceDetect.browserVersion;
  public currentBrowserIsValid = false;
  public hastoUpdateBrowser = false;
  public validBrowsers: string[] = [];
  public isIOSandNotSafari = deviceDetect.isIOS && !(deviceDetect.isMobileSafari || deviceDetect.isSafari);
  public isWindows = deviceDetect.isWindows;
  public isMacOs = deviceDetect.isMacOs;
  public isMobileNotIOS = deviceDetect.isMobile && !deviceDetect.isIOS;
  public isPC = !deviceDetect.isMobile;

  private browserSettings!: BrowserSettings;
  constructor(browserSettings: BrowserSettings) {
    this.validBrowsers = Object.keys(browserSettings);
    this.browserSettings = browserSettings;

    if (browserSettings[this.currentBrowser]) {
      this.currentBrowserIsValid = this.isIOSandNotSafari ? false : true;

      if (deviceDetect.isMobile) {
        this.updatableBrowserMinVersion = browserSettings[this.currentBrowser].minMobileVersion;
      } else {
        this.updatableBrowserMinVersion = browserSettings[this.currentBrowser].minDesktopVersion;
      }

      this.hastoUpdateBrowser = this.updatableBrowserMinVersion >= parseInt(deviceDetect.browserVersion, 10);
    }
  }

  public getUrlForDownload(browser: string): string {
    if (this.browserSettings[browser]) {
      return this.browserSettings[browser].downloadUrl;
    }
    return "";
  }
}
