import { Serializer } from "@/core/shared/api/domain/serializers";
import { NewVisitRequest } from "@/core/visit/domain/visitRepository";

export interface VisitRequestDto {
  title: string;
  start_at: string;
  end_at: string;
  host: string;
  visitor: string;
  location: string;
  extra_fields: { emergency_instructions: boolean };
}

export const visitRequestSerializer: Serializer<NewVisitRequest, VisitRequestDto> = {
  encode: newVisitRequest => ({
    title: newVisitRequest.title,
    start_at: newVisitRequest.startAt,
    end_at: newVisitRequest.endAt,
    host: newVisitRequest.host,
    visitor: newVisitRequest.visitor,
    location: newVisitRequest.location,
    extra_fields: { emergency_instructions: newVisitRequest.emergencyPlanAccepted },
  }),
};
