import { GetUsersRequest } from "../models/portal.models";

export interface UserSearchState {
  filterdata: GetUsersRequest | null;
  bookmarks: string[] | null;
}

const state: UserSearchState = {
  filterdata: null,
  bookmarks: null,
};

export default state;
