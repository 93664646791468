import { ApiClient, ApiOptions, ApiResponse } from "../domain/apiClient";
import { AuthRepository } from "@/core/user/domain/auth";
import { parseServerResponse } from "./parseServerResponse";
import { parseServerError } from "./parseServerError";
import { encodeServerRequestConfig } from "./encodeServerRequestConfig";
import { isDefined, isString } from "@/core/shared/utils";
import { isServerError, Server, ServerMethod } from "../domain/server";
import { Deserializer, Serializer } from "../domain/serializers";
import { MapResponseLiterals, ResponseType, ResponseTypeConfig } from "../domain/responseTypeConfig";
import { MapRequestLiterals, RequestType, RequestTypeConfig } from "../domain/requestTypeConfig";

export const apiClient = ({
  server,
  authRepository,
}: {
  server: Server;
  authRepository: AuthRepository;
}): ApiClient => {
  const serverRequest = async <
    MapResponse extends MapResponseLiterals,
    ResponseData,
    ResponseDto,
    MapRequest extends MapRequestLiterals | void = void,
    RequestData = void,
    RequestDto = void
  >({
    method,
    url,
    data,
    options,
  }: {
    method: ServerMethod;
    url: string;
    options?: ApiOptions<
      MapResponse | Deserializer<ResponseData, ResponseDto>,
      MapRequest | Serializer<RequestData, RequestDto>
    >;
    data?: RequestData;
  }): Promise<ApiResponse<ResponseData>> => {
    const responseTypeConfig = isString(options?.mapResponse)
      ? (ResponseType[options!.mapResponse]() as unknown as ResponseTypeConfig<ResponseData, ResponseDto>)
      : ResponseType.json<ResponseData, ResponseDto>(options?.mapResponse);

    const requestTypeConfig = isString(options?.mapRequest)
      ? (RequestType[options!.mapRequest as MapRequestLiterals]() as unknown as RequestTypeConfig<
          RequestData,
          RequestDto
        >)
      : RequestType.json<RequestData, RequestDto>(options?.mapRequest ?? undefined);

    try {
      return parseServerResponse(
        await server.request<ResponseDto, RequestDto>({
          ...encodeServerRequestConfig({
            requestTypeConfig,
            responseTypeConfig,
            accessToken: authRepository.get()?.accessToken,
          }),
          url,
          method,
          data: isDefined(data) && isDefined(requestTypeConfig) ? requestTypeConfig.encodeRequest(data) : undefined,
        }),
        responseTypeConfig.parseResponse
      );
    } catch (error) {
      if (isServerError(error)) {
        throw parseServerError(error, options?.mapErrors);
      }
      throw error;
    }
  };

  return {
    get: async <MapResponse extends MapResponseLiterals, ResponseData, ResponseDto>(
      url: string,
      options?: ApiOptions<MapResponse | Deserializer<ResponseData, ResponseDto>>
    ) => serverRequest<MapResponse, ResponseData, ResponseDto>({ method: ServerMethod.GET, url, options }),
    delete: async <MapResponse extends MapResponseLiterals, ResponseData, ResponseDto>(
      url: string,
      options?: ApiOptions<MapResponse | Deserializer<ResponseData, ResponseDto>>
    ) => serverRequest<MapResponse, ResponseData, ResponseDto>({ method: ServerMethod.DELETE, url, options }),
    post: async <
      MapResponse extends MapResponseLiterals,
      ResponseData,
      ResponseDto,
      MapRequest extends MapRequestLiterals,
      RequestData,
      RequestDto
    >(
      url: string,
      data: RequestData,
      options?: ApiOptions<
        MapResponse | Deserializer<ResponseData, ResponseDto>,
        MapRequest | Serializer<RequestData, RequestDto>
      >
    ) =>
      serverRequest<MapResponse, ResponseData, ResponseDto, MapRequest, RequestData, RequestDto>({
        method: ServerMethod.POST,
        url,
        data,
        options,
      }),
    put: async <
      MapResponse extends MapResponseLiterals,
      ResponseData,
      ResponseDto,
      MapRequest extends MapRequestLiterals,
      RequestData,
      RequestDto
    >(
      url: string,
      data: RequestData,
      options?: ApiOptions<
        MapResponse | Deserializer<ResponseData, ResponseDto>,
        MapRequest | Serializer<RequestData, RequestDto>
      >
    ) =>
      serverRequest<MapResponse, ResponseData, ResponseDto, MapRequest, RequestData, RequestDto>({
        method: ServerMethod.PUT,
        url,
        data,
        options,
      }),
  };
};
