import { Credentials } from "@/legacy/features/authentication/credentials.models";

export enum AuthMutationsType {
  CredentialsStored = "credentialsStored",
  CredentialsRemoved = "credentialsRemoved",
  AccessTokenStored = "accessTokenStored",
  OnboardingStateStored = "onboardingStateStored",
}

export enum AuthActionsType {
  CredentialsStored = "credentialsStored",
  CredentialsRemoved = "credentialsRemoved",
  AccessTokenStored = "accessTokenStored",
  OnboardingStateStored = "onboardingStateStored",
}

export enum AuthGettersType {
  IsAuthenticated = "isAuthenticated",
  AccessToken = "accessToken",
  RefreshToken = "refreshToken",
  IsUser = "isUser",
  IsAssistant = "isAssistant",
  IsAuditor = "isAuditor",
  IsVisitor = "isVisitor",
  IsReceptionist = "isReceptionist",
  CanSeeProfile = "canSeeProfile",
  CanGetAllData = "canGetAllData",
  CanResetCredentials = "canResetCredentials",
  CanAddLocation = "canAddLocation",
  CanAddBatches = "canAddBatches",
  CanAddSingles = "canAddSingles",
  CanDeleteAccountsOne = "canDeleteAccountsOne",
  CanDeleteAccountMe = "canDeleteAccountMe",
  CanDeleteAllUsers = "canDeleteAllUsers",
  CanDeactivateUser = "canDeactivateUser",
  CanDeactivateAllUsers = "canDeactivateAllUsers",
  IsUserManager = "isUserManager",
  IsAccountManager = "isAccountManager",
  UserName = "userName",
  Email = "email",
  UserLocation = "userLocation",
  UserId = "userId",
  Roles = "roles",
  Enrolled = "enrolled",
  CanEnforceInExternalSystems = "canEnforceInExternalSystems",
  CanUploadWatchlists = "canUploadWatchlists",
}

export interface AuthGetters {
  isAuthenticated: boolean;
  accessToken: string;
  refreshToken: string;
  userName: string;
}

export interface AuthActions {
  storeCredentials(credentials: Credentials): void;
  removeCredentials(): void;
  storeAccessToken(accessToken: string): void;
}
