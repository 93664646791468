import i18n from "@/legacy/infrastructure/i18n";
import { TakeSelfieOptions, TakeSelfieService } from "@/legacy/features/onboarding/interfaces/take-selfie.service";

declare function makeVDSelfieWidget(): any;
declare function destroyVDSelfieWidget(): void;
interface ImageDetectionEvent extends Event {
  detail: string;
}

export class VeridasTakeSelfieService implements TakeSelfieService {
  public take(options: TakeSelfieOptions): void {
    try {
      const VDSelfie = makeVDSelfieWidget();
      const veridasOptions: Record<string, any> = {
        targetSelector: options.containerSelector,
        webrtcUnsupportedText: i18n.t("common.onboarding.commonScan.webrtcUnsupportedText").toString(),
        repeatText: i18n.t("common.onboarding.commonScan.repeatText").toString(),
        continueText: i18n.t("common.onboarding.commonScan.continueText").toString(),
        infoUserSliderButtonText: i18n.t("common.onboarding.commonScan.infoUserSliderButtonText").toString(),
        infoAlertPhotoSelfie: i18n.t("common.onboarding.takeSelfie.infoAlertPhotoSelfie").toString(),
        infoReviewImageText: i18n.t("common.onboarding.takeSelfie.infoReviewImageText").toString(),
        confirmationDialogLinkTextColor: "#ffffff",
        confirmationDialogButtonBackgroundColor: options.primaryColor,
        displayErrors: true,
        confirmationColorTick: "#072B80",
        sdkBackgroundColorInactive: "#072B80",
        borderColorCenteringAidInactive: "#ffffff",
        borderColorCenteringAidDefault: "#ffffff",
        outerGlowCenteringAidDefault: "#072B80",
        confirmationDialogBackgroundColor: "#072B80",
        outerGlowCenteringAidDetecting: options.primaryColor,
        borderColorCenteringAidDetecting: options.primaryColor,
        borderColorCenteringAidDetectingSuccess: options.primaryColor,
        confirmationCaptureButtonBackgroundColor: options.primaryColor,
        infoUserSelfieBackgroundColor: "#f5f5f5",
        infoUserSelfieBackgroundColorTop: options.primaryColor,
        infoAlertShow: false,
        showLogo: false,
      };

      VDSelfie(veridasOptions);

      const ref = options.container;

      ref.addEventListener(
        "VDSelfie_unmounted",
        () => {
          options.onFinish();
        },
        false
      );

      ref.addEventListener(
        "VDSelfie_faceDetection",
        async e => {
          const imageDetectionEvent = e as ImageDetectionEvent;
          options.onSelfie(imageDetectionEvent.detail);
        },
        false
      );

      ref.addEventListener(
        "VDSelfie_mountFailure",
        () => {
          options.onError(true);
        },
        false
      );

      ref.addEventListener(
        "VDSelfie_cameraFailure",
        () => {
          options.onError(false);
        },
        false
      );

      ref.addEventListener(
        "VDSelfie_detectionTimeout",
        () => {
          options.onError(true);
        },
        false
      );

      ref.addEventListener(
        "VDSelfie_mounted",
        () => {
          options.onStart();
        },
        false
      );
    } catch {
      options.onError(true);
    }
  }

  public cancel(): void {
    destroyVDSelfieWidget();
  }
}
