import Vue from "vue";
import { RouteName, RouteNames } from "@/ui/_router";
import { RawLocation, Route } from "vue-router";
import { isDefined } from "@/core/shared/utils";
import { AuthPermissions } from "@/core/user/domain/auth";

export const getOnboardingRoute = () => {
  if (Vue.prototype.$appStore.getters.settings.showNextSteps) {
    return RouteNames.NextSteps;
  }

  if (Vue.prototype.$appStore.getters.identityProofing) {
    return Vue.prototype.$appStore.getters.settings.onboardingSteps
      ? RouteNames.IdentityProofingStep
      : RouteNames.RegisterDocuments;
  }

  return RouteNames.RegisterSelfie;
};

export const isNotValidPcOnboarding = (from: Route) =>
  Vue.prototype.$services.browserValidationService.isPC &&
  !isAnyOfRoutes(from, ["DeviceDetect", "DocumentScan", "IdentityProofingStep", "NextSteps"]);

export const isBrowserSupported = () =>
  Vue.prototype.$services.browserValidationService.currentBrowserIsValid &&
  !Vue.prototype.$services.browserValidationService.hastoUpdateBrowser;

export const isIdentityPortrayalAvailable = () =>
  isDefined(Vue.prototype.$appStore.getters.identityPortrayalId) &&
  isDefined(Vue.prototype.$appStore.getters.documentType);

export const getRouteIfEnrollingUser = (to: Route, permissions: AuthPermissions): RawLocation | undefined => {
  if (permissions.isEnrolled() || !permissions.isUser()) {
    return;
  }

  const name = getOnboardingRoute();
  return { name, query: name === RouteNames.RegisterSelfie ? to.query : undefined };
};

export const isRoute = (route: Route, routeName: RouteName) => RouteNames[routeName] === route.name;
export const isAnyOfRoutes = (route: Route, routeNames: RouteName[]) =>
  routeNames.some(name => RouteNames[name] === route.name);
