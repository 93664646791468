import Vue from "vue";
import { RouteConfig } from "vue-router";
import { RouteNames } from "@/ui/_router/names";
import {
  assistedAndOnboardingIdNeededGuard,
  canSeeProfileGuard,
  homeRouteRedirectionGuard,
  identityPortrayalIdNeededGuard,
  onboardingIdNeededGuard,
  portrayalWithDocumentNeededGuard,
  registerSelfieGuard,
  visitsSolutionGuard,
} from "@/ui/_router/guards";

const AcceptTerms = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/AcceptTerms/AcceptTerms.vue");
const DocumentSelection = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/DocumentSelection/DocumentSelection.vue");
const DocumentScan = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/DocumentScan/DocumentScan.vue");
const SelfieInfoStep = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/Tutorial/SelfieInfoStep/SelfieInfoStep.vue");
const TakeSelfie = () => import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/TakeSelfie/TakeSelfie.vue");
const TakeSelfieAlive = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/TakeSelfieAlive/TakeSelfieAlive.vue");
const PortrayalResult = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/PortrayalResult/PortrayalResult.vue");
const OnboardingResult = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/OnboardingResult/OnboardingResult.vue");
const RegistrationForm = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/RegistrationForm/RegistrationForm.vue");
const ProcessVisit = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/ProcessVisit/ProcessVisit.vue");
const DeviceDetect = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/DeviceDetect/DeviceDetect.vue");
const NextSteps = () =>
  import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/Tutorial/NextSteps/NextSteps.vue");
const IdentityProofingStep = () =>
  import(
    /* webpackChunkName: "bundle-onboard" */ "@/ui/Onboarding/Tutorial/IdentityProofingStep/IdentityProofingStep.vue"
  );
const UserHome = () => import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Portal/UserHome/UserHome.vue");
const Profile = () => import(/* webpackChunkName: "bundle-portal" */ "@/ui/Profile/MyProfile/MyProfile.vue");

export const onBoardingRoutes = (): RouteConfig[] => [
  {
    path: "",
    name: RouteNames.UserHome,
    component: UserHome,
    props: route => ({ saloon: route.query.saloon }),
    beforeEnter: Vue.prototype.$container.guardFactory(homeRouteRedirectionGuard),
  },
  {
    path: RouteNames.PrivacyOnboarding,
    name: RouteNames.PrivacyOnboarding,
    component: AcceptTerms,
    beforeEnter: Vue.prototype.$container.guardFactory(assistedAndOnboardingIdNeededGuard),
  },
  {
    path: RouteNames.RegisterDocuments,
    name: RouteNames.RegisterDocuments,
    component: DocumentSelection,
    beforeEnter: Vue.prototype.$container.guardFactory(onboardingIdNeededGuard),
    props: true,
  },
  {
    path: RouteNames.DocumentScan,
    name: RouteNames.DocumentScan,
    component: DocumentScan,
    beforeEnter: Vue.prototype.$container.guardFactory(portrayalWithDocumentNeededGuard),
  },
  {
    path: RouteNames.RegisterSelfie,
    name: RouteNames.RegisterSelfie,
    component: SelfieInfoStep,
    beforeEnter: Vue.prototype.$container.guardFactory(registerSelfieGuard),
  },
  {
    path: RouteNames.TakeSelfie,
    name: RouteNames.TakeSelfie,
    component: TakeSelfie,
    beforeEnter: Vue.prototype.$container.guardFactory(identityPortrayalIdNeededGuard),
  },
  {
    path: RouteNames.TakeSelfieAlive,
    name: RouteNames.TakeSelfieAlive,
    component: TakeSelfieAlive,
    beforeEnter: Vue.prototype.$container.guardFactory(identityPortrayalIdNeededGuard),
  },
  {
    path: RouteNames.ReviewRegister,
    name: RouteNames.ReviewRegister,
    component: PortrayalResult,
    beforeEnter: Vue.prototype.$container.guardFactory(portrayalWithDocumentNeededGuard),
  },
  {
    path: RouteNames.FinishRegisterWithError,
    name: RouteNames.FinishRegisterWithError,
    component: OnboardingResult,
    props: true,
  },
  {
    path: RouteNames.FinishRegister,
    name: RouteNames.FinishRegister,
    component: OnboardingResult,
    props: route => ({ userId: route.params.userId, success: true }),
  },
  {
    path: RouteNames.FinishRegisterWithQr,
    name: RouteNames.FinishRegisterWithQr,
    component: OnboardingResult,
    props: route => ({ qrId: route.params.qrId, userId: route.params.userId, success: true }),
  },
  {
    path: RouteNames.RegisterForm,
    name: RouteNames.RegisterForm,
    component: RegistrationForm,
    beforeEnter: Vue.prototype.$container.guardFactory(identityPortrayalIdNeededGuard),
  },
  {
    path: RouteNames.DeviceDetect,
    name: RouteNames.DeviceDetect,
    component: DeviceDetect,
    props: true,
  },
  {
    path: RouteNames.UserProfile,
    name: RouteNames.UserProfile,
    component: Profile,
    props: true,
    beforeEnter: Vue.prototype.$container.guardFactory(canSeeProfileGuard),
  },
  {
    path: RouteNames.NextSteps,
    name: RouteNames.NextSteps,
    component: NextSteps,
    props: true,
    beforeEnter: Vue.prototype.$container.guardFactory(onboardingIdNeededGuard),
  },
  {
    path: RouteNames.IdentityProofingStep,
    name: RouteNames.IdentityProofingStep,
    component: IdentityProofingStep,
    props: true,
  },
  {
    path: RouteNames.ProcessVisit,
    name: RouteNames.ProcessVisit,
    component: ProcessVisit,
    beforeEnter: Vue.prototype.$container.guardFactory(visitsSolutionGuard),
  },
];
