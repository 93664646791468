import { Deserializer } from "@/core/shared/api/domain/serializers";
import { UserDetail } from "../domain/user";

interface UserDetailDto {
  username: string;
  first_name: string;
  last_name: string;
  user_id: string;
  email: string;
  acs_id: string;
  created_at: string;
  id_number: string;
  nationality: string;
  portrayal_id: string;
  onboarding_id: string;
}

export const userDetailDeserializer: Deserializer<UserDetail, UserDetailDto> = {
  parse: data => ({
    username: data.username,
    firstName: data.first_name,
    lastName: data.last_name,
    id: data.user_id,
    email: data.email,
    acsId: data.acs_id,
    registerDate: data.created_at,
    documentId: data.id_number,
    nationality: data.nationality,
    portrayalId: data.portrayal_id,
    onboardingId: data.onboarding_id,
  }),
};
