import { GetterTree } from "vuex";
import RootState from "@/legacy/store/rootState";
import { AuthGettersType } from "@/legacy/features/authentication/store/contract";
import { Credentials } from "../credentials.models";

const getters: GetterTree<Credentials, RootState> = {};

getters[AuthGettersType.IsAuthenticated] = (credentials: Credentials) => !!credentials.access_token;
getters[AuthGettersType.AccessToken] = (credentials: Credentials) => credentials.access_token;
getters[AuthGettersType.IsUser] = (credentials: Credentials) => credentials.roles.includes("post_onboardings_auto");
getters[AuthGettersType.IsAssistant] = (credentials: Credentials) =>
  credentials.roles.includes("post_onboardings_assisted");
getters[AuthGettersType.CanSeeProfile] = (credentials: Credentials) => credentials.roles.includes("get_users_me");
getters[AuthGettersType.CanGetAllData] = (credentials: Credentials) => credentials.roles.includes("get_all_data_me");
getters[AuthGettersType.CanResetCredentials] = (credentials: Credentials) =>
  credentials.roles.includes("show_reset_credentials_button_me");
getters[AuthGettersType.CanAddLocation] = (credentials: Credentials) =>
  credentials.roles.includes("post_credentials_location");
getters[AuthGettersType.CanAddBatches] = (credentials: Credentials) => credentials.roles.includes("post_batches");
getters[AuthGettersType.CanAddSingles] = (credentials: Credentials) => credentials.roles.includes("post_singles");
getters[AuthGettersType.CanDeleteAccountsOne] = (credentials: Credentials) =>
  credentials.roles.includes("delete_accounts_one");
getters[AuthGettersType.CanDeleteAccountMe] = (credentials: Credentials) =>
  credentials.roles.includes("delete_accounts_me");
getters[AuthGettersType.CanDeleteAllUsers] = (credentials: Credentials) =>
  credentials.roles.includes("delete_users_all");
getters[AuthGettersType.CanDeactivateUser] = (credentials: Credentials) =>
  credentials.roles.includes("put_credentials_state_one");
getters[AuthGettersType.CanDeactivateAllUsers] = (credentials: Credentials) =>
  credentials.roles.includes("put_credentials_state_all");
getters[AuthGettersType.IsUserManager] = (credentials: Credentials) => credentials.roles.includes("get_users_all");
getters[AuthGettersType.IsAccountManager] = (credentials: Credentials) =>
  credentials.roles.includes("get_accounts_all");
getters[AuthGettersType.IsVisitor] = (credentials: Credentials) => credentials.roles.includes("post_visits_me");
getters[AuthGettersType.IsReceptionist] = (credentials: Credentials) => credentials.roles.includes("post_visits_all");
getters[AuthGettersType.IsAuditor] = (credentials: Credentials) =>
  credentials.roles.includes("post_audit_accesses_all") || credentials.roles.includes("post_accesses_myscope");
getters[AuthGettersType.CanEnforceInExternalSystems] = (credentials: Credentials) =>
  credentials.roles.includes("post_external_enforcements");
getters[AuthGettersType.CanUploadWatchlists] = (credentials: Credentials) =>
  credentials.roles.includes("post_watchlists");

getters[AuthGettersType.UserName] = (credentials: Credentials) => credentials.userName;
getters[AuthGettersType.Email] = (credentials: Credentials) => credentials.email!;
getters[AuthGettersType.UserLocation] = (credentials: Credentials) => credentials.profile;
getters[AuthGettersType.UserId] = (credentials: Credentials) => credentials.userId;
getters[AuthGettersType.Roles] = (credentials: Credentials) => credentials.roles;
getters[AuthGettersType.Enrolled] = (credentials: Credentials) => credentials.enrolled;

export default getters;
