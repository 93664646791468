import Vue from "vue";
import VueI18n from "vue-i18n";
import { ConfigurationService } from "@/legacy/services/configuration.service";
import { LocalizationService, Translation } from "@/legacy/services/localization.service";
import { isDefined, isEmpty } from "@/core/shared/utils";
import { merge } from "lodash";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "es",
  fallbackLocale: "es",
  silentTranslationWarn: process.env.NODE_ENV === "test",
});

const getDefaultLocale = (translations: Translation[]) => {
  if (translations.length === 1) {
    return translations[0].locale;
  }

  const defaultLocaleOptions = [
    localStorage.getItem("locale"),
    process.env.VUE_APP_I18N_LOCALE,
    window.navigator.language?.split("-")[0],
    "es",
    "en",
  ];

  return (
    defaultLocaleOptions.find(locale => {
      translations.find(translation => translation.locale === locale);
    }) ?? translations[0].locale
  );
};

const getFallbackLocale = (translations: Translation[]) => {
  if (translations.length === 1) {
    return translations[0].locale;
  }

  const fallbackLocaleOptions = [process.env.VUE_APP_I18N_FALLBACK_LOCALE, "es", "en"];

  return (
    fallbackLocaleOptions.find(locale => {
      translations.find(translation => translation.locale === locale);
    }) ?? translations[0].locale
  );
};

const getTranslations = async () => {
  const defaultTranslations: Translation[] = [
    { locale: "es", messages: require("@/assets/locale/es.json") },
    { locale: "en", messages: require("@/assets/locale/en.json") },
    { locale: "eu", messages: require("@/assets/locale/eu.json") },
  ];

  const configurationService = new ConfigurationService();
  const settings = await configurationService.getConfig();
  const tenantTranslations = await LocalizationService.getTenantLocales(settings);

  if (isEmpty(tenantTranslations)) {
    return defaultTranslations;
  }

  return tenantTranslations.map(tenantTranslation => {
    const sameLocaleDefaultTranslation = defaultTranslations.find(
      defaultTranslation => tenantTranslation.locale === defaultTranslation.locale
    );

    if (!isDefined(sameLocaleDefaultTranslation)) {
      return tenantTranslation;
    }

    return {
      locale: tenantTranslation.locale,
      messages: merge(sameLocaleDefaultTranslation.messages, tenantTranslation.messages),
    };
  });
};

export const initI18n = async (i18n: VueI18n) => {
  const translations = await getTranslations();

  translations.forEach(translation => {
    i18n.setLocaleMessage(translation.locale, translation.messages);
  });

  i18n.locale = getDefaultLocale(translations);
  i18n.fallbackLocale = getFallbackLocale(translations);
};

export default i18n;
