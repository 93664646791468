import { Deserializer } from "@/core/shared/api/domain/serializers";
import { StartedPortrayal } from "@/core/portrayal/domain/portrayalRepository";

interface StartedPortrayalDto {
  portrayal_id: string;
  life_proofing?: boolean;
}

export const startedPortrayalDeserializer: Deserializer<StartedPortrayal, StartedPortrayalDto> = {
  parse: startedPortrayalInfoDto => ({
    portrayalId: startedPortrayalInfoDto.portrayal_id,
    lifeProofing: startedPortrayalInfoDto.life_proofing,
  }),
};
