import { getHttpClient } from "@/legacy/features/authentication/auth-http-client.service";
import {
  GetIdentityPortrayalRequest,
  GetIdentityPortrayalResponse,
  IdentityPortrayalImageRequest,
  StartIdentityPortrayalRequest,
  StartIdentityPortrayalResponse,
  StopIdentityPortrayalRequest,
} from "@/legacy/features/onboarding/models/identity-portrayal.models";
import { IdentityPortrayalService } from "@/legacy/features/onboarding/interfaces/identity-portrayal.service";
import {
  getIdentityPortrayalRawType,
  IdentityPortrayalRawDocumentCountry,
  IdentityPortrayalRawDocumentType,
  IdentityPortrayalRawType,
} from "@/legacy/infrastructure//shared";

interface StartIdentityPortrayalRawRequest {
  user_id: string;
  portrayal_type: IdentityPortrayalRawType;
  document_type: IdentityPortrayalRawDocumentType;
  document_country: IdentityPortrayalRawDocumentCountry;
  onboarding_id: string;
}

interface StartIdentityPortrayalRawResponse {
  portrayal_id: string;
}

interface GetIdentityPortrayalRawResponse {
  portrayal_id: string;
  onboarding_id: string;
  status: "initialized" | "running" | "completed" | "cancelled" | "failed" | "finished";
  score_biometry: number;
  document_validation: number;
  score_biometry_valid: boolean;
  score_document_valid: boolean;
  data_validation: boolean;
  document_info?: {
    first_name: string;
    last_name: string;
    birth_date: string;
    id_number: string;
    document_number: string;
    expiry_date: string;
    expedition_country: string;
    nationality: string;
    document_type: IdentityPortrayalRawDocumentType;
  };
  selfie_authenticity_valid?: boolean;
}

export class HttpIdentityPortrayalService implements IdentityPortrayalService {
  public async start(request: StartIdentityPortrayalRequest): Promise<StartIdentityPortrayalResponse> {
    const httpClient = getHttpClient();
    const rawRequest: StartIdentityPortrayalRawRequest = {
      user_id: request.userId,
      portrayal_type: getIdentityPortrayalRawType(request.portrayalType),
      document_type: request.documentType,
      document_country: request.documentCountry,
      onboarding_id: request.onboardingId,
    };

    const rawResponse = await httpClient.post<StartIdentityPortrayalRawResponse>(`/internal/portrayals`, rawRequest);
    return {
      identityPortrayalId: rawResponse.data.portrayal_id,
    };
  }

  public async stop(stopIdentityPortrayalRequest: StopIdentityPortrayalRequest): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.delete<void>(`/internal/portrayals/${stopIdentityPortrayalRequest.identityPortrayalId}`);
  }

  public async createChallenge(length: number, expiration: number): Promise<string> {
    const httpClient = getHttpClient();
    const response = await httpClient.post(`/internal/challenges`, { length, expiration });
    if (response.status === 200) {
      return response.data;
    }
    return "";
  }

  public async sendImage(identityPortrayalImageRequest: IdentityPortrayalImageRequest): Promise<void> {
    const httpClient = getHttpClient();
    if (identityPortrayalImageRequest.imageType === "document-obverse") {
      await httpClient.put<void>(`/internal/portrayals/${identityPortrayalImageRequest.identityPortrayalId}/obverse`, {
        content: identityPortrayalImageRequest.image,
      });
      return;
    }

    if (identityPortrayalImageRequest.imageType === "document-reverse") {
      await httpClient.put<void>(`/internal/portrayals/${identityPortrayalImageRequest.identityPortrayalId}/reverse`, {
        content: identityPortrayalImageRequest.image,
      });
      return;
    }

    if (identityPortrayalImageRequest.imageType === "selfie") {
      await httpClient.put<void>(`/internal/portrayals/${identityPortrayalImageRequest.identityPortrayalId}/selfie`, {
        content: identityPortrayalImageRequest.image,
      });
      return;
    }
    if (identityPortrayalImageRequest.imageType === "alive") {
      await httpClient.put<void>(`/internal/portrayals/${identityPortrayalImageRequest.identityPortrayalId}/selfie`, {
        content: identityPortrayalImageRequest.image,
        life_proofing: identityPortrayalImageRequest.alive,
      });
      return;
    }
    if (identityPortrayalImageRequest.imageType === "pro") {
      await httpClient.put<void>(
        `/internal/portrayals/${identityPortrayalImageRequest.identityPortrayalId}/lifeproof`,
        identityPortrayalImageRequest.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return;
    }
  }

  public async get(request: GetIdentityPortrayalRequest): Promise<GetIdentityPortrayalResponse> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.get<GetIdentityPortrayalRawResponse>(
      `/internal/portrayals/${request.identityPortrayalId}`
    );
    const done = rawResponse.status === 200;
    const selfieAuthentic =
      rawResponse.data.selfie_authenticity_valid === undefined ? true : rawResponse.data.selfie_authenticity_valid;

    return {
      done,
      documentInfo: rawResponse.data.document_info ? true : false,
      ocr: {
        firstName: rawResponse.data.document_info?.first_name || "",
        lastName: rawResponse.data.document_info?.last_name || "",
        birthDate: rawResponse.data.document_info ? new Date(rawResponse.data.document_info.birth_date) : new Date(),
        documentId: rawResponse.data.document_info?.id_number || "",
        documentNumber: rawResponse.data.document_info?.document_number || "",
        expiryDate: rawResponse.data.document_info ? new Date(rawResponse.data.document_info.expiry_date) : new Date(),
        expeditionCountry: rawResponse.data.document_info?.expedition_country || "",
        nationality: rawResponse.data.document_info?.nationality || "",
        documentType: rawResponse.data.document_info?.document_type || "others",
      },
      scores: {
        document: rawResponse.data.document_validation,
        documentOk: rawResponse.data.score_document_valid,
        dataValidationOk: rawResponse.data.data_validation ?? undefined,
        selfie: rawResponse.data.score_biometry,
        selfieOk: rawResponse.data.score_biometry_valid,
        selfieAuthentic,
      },
    };
  }
}
