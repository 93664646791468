import { ApiError, ApiErrorBody, InternalErrors, StatusCodes } from "../domain/apiClient";
import { parseLinkHeader } from "./parseLinkHeader";
import { ServerError } from "../domain/server";
import { has } from "@/core/shared/utils";
import { ErrorMapper } from "@/core/shared/api/domain/serializers";

export const parseServerError = (serverError: ServerError, errorMapper?: ErrorMapper): ApiError =>
  new ApiError(
    serverError.response?.status as StatusCodes,
    parseApiErrorBody(serverError.response?.data, errorMapper),
    {
      link: parseLinkHeader(serverError.response?.headers?.link),
      originalError: serverError,
    }
  );

const parseApiErrorBody = (body: unknown, errorMapper?: ErrorMapper): ApiErrorBody => {
  const reason = has(body, "reason") && errorMapper?.[body?.reason]?.reason;

  return {
    reason: reason ? reason : InternalErrors.Unknown,
    message: (has(body, "message") && body.message) ?? "Unknown api error",
    detail: parseDetail(body, errorMapper),
  };
};

const parseDetail = (body: unknown, errorMapper?: ErrorMapper) => {
  if (!has(body, "detail")) {
    return undefined;
  }

  if (!has(body, "reason")) {
    return body.detail;
  }

  return errorMapper?.[body.reason]?.dataMapper?.parse(body.detail) ?? body.detail;
};
