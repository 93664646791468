import { userModule } from "@/core/user/_di/userModule";
import { sharedModule } from "@/core/shared/_di/sharedModule";
import { accreditationModule } from "@/core/accreditation/_di/accreditationModule";
import { portrayalModule } from "@/core/portrayal/_di/portrayalModule";
import { uiModule } from "@/ui/_di/uiModule";
import { visitModule } from "@/core/visit/_di/visitModule";
import { facilityModule } from "@/core/facility/_di/facilityModule";

export const modules = {
  ...visitModule,
  ...facilityModule,
  ...userModule,
  ...accreditationModule,
  ...portrayalModule,
  ...sharedModule,
  ...uiModule,
};
