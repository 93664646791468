import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { Silent } from "@/ui/Silent";
import { Callback } from "@/ui/Callback";
import { RouteNames } from "@/ui/_router/names";
import { Landing } from "@/ui/Landing";
import { Page } from "@/ui/_components/Page";
import { landingGuard } from "@/ui/_router/guards";
import { OnBoardingPage } from "@/ui/_components/OnBoardingPage";
import { onBoardingRoutes } from "./onboarding.routes";
import { onVisitRoutes } from "./visit.routes";
import { onPortalRoutes, onProfileRoutes } from "./portal.routes";

const Error = () => import(/* webpackChunkName: "bundle-onboard" */ "@/ui/Error/Error.vue");

const router = new VueRouter({ mode: "history" });

export const routes = (): RouteConfig[] => [
  {
    path: "/" + RouteNames.Callback,
    name: RouteNames.Callback,
    component: Callback,
  },
  {
    path: "/" + RouteNames.Silent,
    name: RouteNames.Silent,
    component: Silent,
  },
  {
    path: "/" + RouteNames.Landing,
    component: Page,
    children: [
      {
        path: "/",
        name: RouteNames.Landing,
        component: Landing,
        beforeEnter: Vue.prototype.$container.guardFactory(landingGuard),
      },
    ],
  },
  {
    path: "/portal",
    component: Page,
    children: onPortalRoutes(),
  },
  {
    path: "/profile",
    component: Page,
    children: onProfileRoutes(),
  },
  {
    path: "/visit",
    component: Page,
    children: onVisitRoutes(),
  },
  {
    path: "/",
    component: OnBoardingPage,
    children: onBoardingRoutes(),
  },
  {
    path: "/" + RouteNames.Error,
    component: Page,
    children: [
      {
        path: "/",
        name: RouteNames.Error,
        component: Error,
        props: true,
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

export const initRouter = () => {
  Vue.use(VueRouter);
  router.addRoutes(routes());

  router.beforeEach(async (to, from, next) => {
    // Clear all error messages when the user changes the route
    if (Vue.prototype.$services) {
      Vue.prototype.$services.notificationService.destroyAllErrors();
    }
    next();
  });
};

export default router;
export * from "@/ui/_router/names";
export * from "@/ui/_router/guards";
