import { StatusCodes } from "http-status-codes";
import { has } from "@/core/shared/utils";
import { Deserializer, ErrorMapper, Serializer } from "./serializers";
import { MapResponseLiterals, MapResponseTypeMapper } from "./responseTypeConfig";
import { MapRequestLiterals, MapRequestTypeMapper } from "./requestTypeConfig";

export interface ApiOptions<MapResponse, MapRequest = void> {
  mapRequest?: MapRequest;
  mapResponse?: MapResponse;
  mapErrors?: ErrorMapper;
}

export enum InternalErrors {
  Unknown = "internal/unknown",
}

export interface Link {
  uri: string;
  params: Record<string, string>;
}

export interface ApiResponse<Data = void> {
  isApiResponse: true;
  data: Data;
  link?: Link;
}

export interface ApiErrorBody<Detail = unknown> {
  message: string;
  reason: string;
  detail: Detail;
}

export class ApiError<Detail = unknown> extends Error {
  isApiError: true;
  statusCode: StatusCodes;
  originalError?: Error;
  link?: Link;
  data: ApiErrorBody<Detail>;

  constructor(
    statusCode: StatusCodes,
    data: ApiErrorBody<Detail>,
    options?: {
      originalError?: Error;
      link?: Link;
    }
  ) {
    super(
      `Api Error ${statusCode}(${options?.originalError ? "Original error: " + options.originalError.message : ""})`
    );

    this.isApiError = true;
    this.statusCode = statusCode;
    this.data = data;
    this.link = options?.link;
    this.originalError = options?.originalError;
  }
}

export interface ApiClient {
  get<ResponseData = void, ResponseDto = void>(
    url: string,
    options?: ApiOptions<Deserializer<ResponseData, ResponseDto>>
  ): Promise<ApiResponse<ResponseData>>;
  get<ResponseLiteral extends MapResponseLiterals>(
    url: string,
    options?: ApiOptions<ResponseLiteral>
  ): Promise<ApiResponse<MapResponseTypeMapper[ResponseLiteral]>>;

  delete<ResponseData = void, ResponseDto = void>(
    url: string,
    options?: ApiOptions<Deserializer<ResponseData, ResponseDto>>
  ): Promise<ApiResponse<ResponseData>>;
  delete<ResponseLiteral extends MapResponseLiterals>(
    url: string,
    options?: ApiOptions<ResponseLiteral>
  ): Promise<ApiResponse<MapResponseTypeMapper[ResponseLiteral]>>;

  post<ResponseData = void, ResponseDto = void, RequestData = void, RequestDto = void>(
    url: string,
    data?: RequestData,
    options?: ApiOptions<Deserializer<ResponseData, ResponseDto>, Serializer<RequestData, RequestDto>>
  ): Promise<ApiResponse<ResponseData>>;
  post<ResponseLiteral extends MapResponseLiterals, RequestData = void, RequestDto = void>(
    url: string,
    data?: RequestData,
    options?: ApiOptions<ResponseLiteral, Serializer<RequestData, RequestDto>>
  ): Promise<ApiResponse<MapResponseTypeMapper[ResponseLiteral]>>;
  post<RequestLiteral extends MapRequestLiterals, ResponseData = void, ResponseDto = void>(
    url: string,
    data?: MapRequestTypeMapper[RequestLiteral],
    options?: ApiOptions<Deserializer<ResponseData, ResponseDto>, RequestLiteral>
  ): Promise<ApiResponse<ResponseData>>;
  post<ResponseLiteral extends MapResponseLiterals, RequestLiteral extends MapRequestLiterals>(
    url: string,
    data?: MapRequestTypeMapper[RequestLiteral],
    options?: ApiOptions<ResponseLiteral, RequestLiteral>
  ): Promise<ApiResponse<MapResponseTypeMapper[ResponseLiteral]>>;

  put<ResponseData = void, ResponseDto = void, RequestData = void, RequestDto = void>(
    url: string,
    data?: RequestData,
    options?: ApiOptions<Deserializer<ResponseData, ResponseDto>, Serializer<RequestData, RequestDto>>
  ): Promise<ApiResponse<ResponseData>>;
  put<ResponseLiteral extends MapResponseLiterals, RequestData = void, RequestDto = void>(
    url: string,
    data?: RequestData,
    options?: ApiOptions<ResponseLiteral, Serializer<RequestData, RequestDto>>
  ): Promise<ApiResponse<MapResponseTypeMapper[ResponseLiteral]>>;
  put<RequestLiteral extends MapRequestLiterals, ResponseData = void, ResponseDto = void>(
    url: string,
    data?: MapRequestTypeMapper[RequestLiteral],
    options?: ApiOptions<Deserializer<ResponseData, ResponseDto>, RequestLiteral>
  ): Promise<ApiResponse<ResponseData>>;
  put<ResponseLiteral extends MapResponseLiterals, RequestLiteral extends MapRequestLiterals>(
    url: string,
    data?: MapRequestTypeMapper[RequestLiteral],
    options?: ApiOptions<ResponseLiteral, RequestLiteral>
  ): Promise<ApiResponse<MapResponseTypeMapper[ResponseLiteral]>>;
}

export const isApiError = (error: unknown): error is ApiError => has(error, "isApiError");
export const isApiResponse = (response: unknown): response is ApiResponse => has(response, "isApiResponse");

export const apiResponse = <Data>(data: Data, link?: Link): ApiResponse<Data> => ({ data, link, isApiResponse: true });

export { StatusCodes };
