import { EntityStorage } from "@/core/shared/storage/domain/storage";
import { isDefined } from "@/core/shared/utils";

export interface Auth {
  userName: string;
  accessToken: string;
  roles: Roles[];
  email: string;
  profile: string;
  userId: string;
  enrolled: boolean;
  locale: string;
  userType?: string;
  claims?: Claims[];
}

export enum Roles {
  CanViewAccreditations = "can_view_accreditations",
  CanViewCompleteOnboardings = "can_view_complete_onboardings",
  PostOnboardingsAuto = "post_onboardings_auto",
  PostOnboardingsAssisted = "post_onboardings_assisted",
  GetUsersMe = "get_users_me",
  GetAllDataMe = "get_all_data_me",
  ShowResetCredentialsButtonMe = "show_reset_credentials_button_me",
  PostCredentialsLocation = "post_credentials_location",
  PostBatches = "post_batches",
  PostSingles = "post_singles",
  DeleteAccountsOne = "delete_accounts_one",
  DeleteAccountsMe = "delete_accounts_me",
  DeleteUsersAll = "delete_users_all",
  PutCredentialsStateOne = "put_credentials_state_one",
  PutCredentialsStateAll = "put_credentials_state_all",
  GetUsersAll = "get_users_all",
  GetAccountsAll = "get_accounts_all",
  PostVisitsMe = "post_visits_me",
  PostVisitsAll = "post_visits_all",
  PostAuditAccessesAll = "post_audit_accesses_all",
  PostAccessesMyscope = "post_accesses_myscope",
  PostExternalEnforcements = "post_external_enforcements",
  PostWatchlists = "post_watchlists",
  PostTimeUser = "post_time_user",
}

export type Role = keyof typeof Roles;

export const claims: (keyof Pick<Auth, "email" | "userType">)[] = ["email", "userType"];

export type Claims = (typeof claims)[number];

export type AuthStorage = EntityStorage<Auth>;

export interface AuthRepository {
  get: () => Auth | undefined;
  set: (auth: Auth) => void;
  update: (auth: Partial<Auth>) => void;
  unset: () => void;
}

const hasRole = (role: Role, auth?: Auth) => hasAnyOfRoles([role], auth);
const hasAnyOfRoles = (role: Role[], auth?: Auth) => role.some(role => auth?.roles?.includes(Roles[role]) ?? false);

export const authPermissions = (auth?: Auth) => ({
  hasRole: (role: Roles) => auth?.roles.includes(role),
  hasClaims: () => isDefined(auth?.claims) ?? false,
  isEnrolled: () => auth?.enrolled ?? false,
  isAuthenticated: () => isDefined(auth?.accessToken) ?? false,
  isUser: () => hasRole("PostOnboardingsAuto", auth),
  isAssistant: () => hasRole("PostOnboardingsAssisted", auth),
  isAuditor: () => hasAnyOfRoles(["PostAuditAccessesAll", "PostAccessesMyscope"], auth),
  isVisitor: () => hasRole("PostVisitsMe", auth),
  isUserManager: () => hasRole("GetUsersAll", auth),
  isAccountManager: () => hasRole("GetAccountsAll", auth),
  isReceptionist: () => hasRole("PostVisitsAll", auth),
  canViewAccreditations: () => hasRole("CanViewAccreditations", auth),
  canViewProfile: () => hasRole("GetUsersMe", auth),
  canDeleteAccounts: () => hasAnyOfRoles(["DeleteAccountsMe", "DeleteAccountsOne"], auth),
  canDeleteAllUsers: () => hasRole("DeleteUsersAll", auth),
  canDeleteAccountMe: () => hasRole("DeleteAccountsMe", auth),
  canDeactivateAllCredentials: () => hasRole("PutCredentialsStateAll", auth),
  canResetCredentials: () => hasRole("ShowResetCredentialsButtonMe", auth),
  canViewBatchEnrolment: () => hasRole("PostBatches", auth),
  canViewSingleEnrolment: () => hasRole("PostSingles", auth),
  canUploadWatchlist: () => hasRole("PostWatchlists", auth),
  canViewCompleteOnboardings: () => hasRole("CanViewCompleteOnboardings", auth),
});

export type AuthPermissions = ReturnType<typeof authPermissions>;
