import { Serializer } from "@/core/shared/api/domain/serializers";
import { PortrayalStartRequest } from "@/core/portrayal/domain/portrayalRepository";

export interface PortrayalStartRequestDto {
  user_id: string;
  portrayal_type: "selfie";
  process: "accredit" | "onboarding";
}

export const portrayalStartRequestSerializer: Serializer<PortrayalStartRequest, PortrayalStartRequestDto> = {
  encode: portrayalStartRequest => ({
    user_id: portrayalStartRequest.userId,
    portrayal_type: portrayalStartRequest.type,
    process: portrayalStartRequest.process,
  }),
};
