import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BaseUrl, Iam } from "@/legacy/models/constants";
import store from "@/legacy/store";
import { AuthGettersType } from "@/legacy/features/authentication/store/contract";
import { getTenant } from "@/legacy/services/configuration.service";
import { Log } from "@/legacy/infrastructure/logger";
import Vue from "vue";

export const getIAMClient = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: Iam + "auth/realms/" + getTenant(),
    headers: {
      Authorization: "Bearer " + store.getters[AuthGettersType.AccessToken],
    },
  });
  // Add interceptors
  instance.interceptors.request.use(request => initRequestHandler(request));

  instance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  );
  return instance;
};

export const getHttpActionClient = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: BaseUrl,
    headers: {
      "X-dasgate-tenant-id": getTenant(),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  instance.interceptors.request.use(request => initRequestHandler(request));

  instance.interceptors.response.use(response => successHandler(response));
  return instance;
};

export const getHttpClient = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: BaseUrl,
    headers: {
      Authorization: "Bearer " + store.getters[AuthGettersType.AccessToken],
      "X-dasgate-tenant-id": getTenant(),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  // Add interceptors
  instance.interceptors.request.use(request => initRequestHandler(request));

  instance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  );
  return instance;
};

export const initRequestHandler = async (request: AxiosRequestConfig) => {
  // eslint-disable-next-line no-prototype-builtins
  if (request.params && request.params.hasOwnProperty("silent")) {
    Log.info("initRequestHandler silent mode");
  } else {
    Log.info("initRequestHandler");
    (Vue.prototype as Vue).$services.requestService.initRequest();
  }
  return request;
};

export const isKnownError = (error: any) => {
  // TODO: we should avoid this check!!! jruiz!!!!
  let knownError = false;
  if (error.response!.data.detail === "onboarding_confirmation_forbidden") {
    knownError = true;
  }
  if (error.response!.data.message === "location needed") {
    knownError = true;
  }
  return knownError;
};

const errorHandler = async (error: any) => {
  const vue: Vue = Vue.prototype;
  Log.info("errorRequestHandler");
  const originalRequest = error.config;

  if (error.response!.status === 403 && !isKnownError(error)) {
    if (!originalRequest._retry) {
      Log.warn("not authorized, silent renew");
      await vue.$services.authentication.silentRenew();
      originalRequest._retry = true;
      axios.defaults.headers.Authorization = "Bearer " + store.getters[AuthGettersType.AccessToken];
      return getHttpClient().request(originalRequest);
    } else {
      Log.warn("not authorized, silent renew not possible, maybe go to login");
    }
  }
  if (error.response!.status === 401) {
    vue.$appStore.actions.cleanAll();
    await vue.$services.authentication.logout();
  }

  vue.$services.requestService.endRequest();
  Log.error(error);
  return Promise.reject({ ...error });
};

export const successHandler = (response: AxiosResponse<any>) => {
  Log.info("successRequestHandler");
  (Vue.prototype as Vue).$services.requestService.endRequest();
  return response;
};
