import { VisitsService } from "../interfaces/visits-service";
import {
  ChangeVisitStatusRequest,
  CheckInResponse,
  CheckOutResponse,
  ConfirmationRequest,
  DeleteVisitRequest,
  DeleteVisitResponse,
  Facility,
  GetVisitsRequest,
  GetVisitsResponse,
  HistoryEntity,
  VisitInfo,
} from "../models/visits.models";
import { getHttpActionClient, getHttpClient } from "@/legacy/features/authentication/auth-http-client.service";

interface GetVisitsRawRequest {
  pageSize?: number;
  pageIndex?: number;
  bookmark?: string;
  filter?: string;
  visitor?: string;
  toDate?: string;
  fromDate?: string;
  orderby?: string;
  sorted?: string;
  user?: string;
}

interface VisitInfoRaw {
  id: string;
  title: string;
  expected_at: string;
  leaving_at: string;
  host: string;
  location: FacilityRaw;
  history: [];
  status: "expected" | "requested" | "checked_in" | "checked_out" | "on_site" | "not_on_site" | "cancelled";
  visitor?: string;
}

interface FacilityRaw {
  facility_id: string;
  alias: string;
  timezone: string;
}

interface GetVisitsRawResponse {
  page_size: number;
  total_pages: number;
  total_items: number;
  items: VisitInfoRaw[];
  bookmark: string;
}

export class HttpVisitsService implements VisitsService {
  public async mapVisit(element: any) {
    return {
      id: element?.id,
      title: element?.title,
      expected_at: element?.expected_at,
      leaving_at: element?.leaving_at,
      host: element?.host,
      location: await this.mapFacility(element?.location),
      history: await Promise.all(element?.history.map((entry: any) => this.mapHistoryEntry(entry))),
      status: element?.status,
      visitor: element?.visitor,
      visitorData: element?.visitorData,
      visitorProfile: element?.visitorProfile,
    } as VisitInfo;
  }

  public async mapFacility(element: any) {
    return {
      facilityId: element?.facility_id,
      alias: element?.alias,
      timezone: element?.timezone,
      label: element?.alias,
      value: element?.facility_id,
    } as Facility;
  }

  public async mapHistoryEntry(element: any) {
    return {
      datetime: element?.datetime,
      action: element?.action,
      message: element?.message,
      result: element?.result,
    } as HistoryEntity;
  }

  public async getVisits(request: GetVisitsRequest): Promise<GetVisitsResponse> {
    const httpClient = getHttpClient();

    const rawRequest: GetVisitsRawRequest = {
      pageSize: request.pageSize,
      pageIndex: request.pageIndex,
      bookmark: request.bookmark,
      fromDate: request.fromDate ? request.fromDate : undefined,
      toDate: request.toDate ? request.toDate : undefined,
      orderby: request.orderby,
      sorted: request.sorted,
      visitor: request.visitor ? request.visitor : undefined,
      user: request.user ? request.user : undefined,
    };
    const rawResponse = await httpClient.get<GetVisitsRawResponse>(`/public/visits`, {
      params: rawRequest,
    });

    return {
      items: await Promise.all(rawResponse.data.items.map(element => this.mapVisit(element))),
      totalPages: rawResponse.data.total_pages,
      totalItems: rawResponse.data.total_items,
      pageSize: rawResponse.data.page_size,
      bookmark: rawResponse.data.bookmark,
    } as GetVisitsResponse;
  }

  public async deleteVisit(request: DeleteVisitRequest): Promise<DeleteVisitResponse> {
    const httpClient = getHttpClient();
    let response: DeleteVisitResponse = {
      success: false,
    };
    await httpClient
      .post(`/public/visits/${request.id}/cancellation`)
      .then(() => {
        response = { success: true };
      })
      .catch(error => {
        response = { success: false, errorMessage: error.response.data.reason };
      });
    return response;
  }

  public async checkinVisit(request: ChangeVisitStatusRequest): Promise<CheckInResponse> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.put(`/public/visits/${request.id}/checkin`);
    return rawResponse.data;
  }

  public async checkoutVisit(request: ChangeVisitStatusRequest): Promise<CheckOutResponse> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.put(`/public/visits/${request.id}/checkout`);
    return rawResponse.data;
  }

  public async confirmation(confirmationRequest: ConfirmationRequest): Promise<VisitInfo | string> {
    const httpClient = getHttpActionClient();

    let confirmationResponse;
    let errorMessage = "";
    await httpClient
      .post<VisitInfo>(
        `/public/visits/${confirmationRequest.visitId}/confirmation`,
        {},
        {
          headers: { Authorization: "Bearer " + confirmationRequest.token },
        }
      )
      .then(rawResponse => {
        confirmationResponse = rawResponse.data;
      })
      .catch(error => {
        if (error.response.status === 401) {
          errorMessage = "token_expired";
        } else {
          errorMessage =
            error.response.data.detail.status === "accepted" ? "visit_already_accepted" : "visit_already_rejected";
        }
      });
    if (errorMessage) {
      return errorMessage;
    }
    return confirmationResponse as unknown as VisitInfo;
  }
}
