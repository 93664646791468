import Vue from "vue";
import { RouteNames } from "@/ui/_router/names";
import { visitsSolutionGuard } from "@/ui/_router/guards";

const NewVisit = () => import(/* webpackChunkName: "bundle-visits" */ "@/ui/Visits/NewVisit/NewVisit.vue");
const VisitsListRedesign = () =>
  import(/* webpackChunkName: "bundle-visits" */ "@/ui/Visits/VisitsList/VisitsList.vue");
const VisitDetailRedesign = () =>
  import(/* webpackChunkName: "bundle-visits" */ "@/ui/Visits/VisitDetail/VisitDetail.vue");

const VisitConfirmationRedesign = () =>
  import(/* webpackChunkName: "bundle-visits" */ "@/ui/Visits/VisitConfirmation/VisitConfirmation.vue");

export const onVisitRoutes = () => [
  {
    path: RouteNames.VisitList,
    name: RouteNames.VisitList,
    component: VisitsListRedesign,
    beforeEnter: Vue.prototype.$container.guardFactory(visitsSolutionGuard),
  },
  {
    path: RouteNames.MyVisits,
    name: RouteNames.MyVisits,
    component: VisitsListRedesign,
    beforeEnter: Vue.prototype.$container.guardFactory(visitsSolutionGuard),
  },
  {
    path: RouteNames.NewVisit,
    name: RouteNames.NewVisit,
    component: NewVisit,
    beforeEnter: Vue.prototype.$container.guardFactory(visitsSolutionGuard),
  },
  {
    path: RouteNames.VisitDetail,
    name: RouteNames.VisitDetail,
    component: VisitDetailRedesign,
    beforeEnter: Vue.prototype.$container.guardFactory(visitsSolutionGuard),
    props: true,
  },
  {
    path: RouteNames.VisitConfirmation,
    name: RouteNames.VisitConfirmation,
    component: VisitConfirmationRedesign,
  },
];
