import { Accreditation, AccreditationType } from "@/core/accreditation/domain/accreditation";
import { AuthRepository } from "@/core/user/domain/auth";
import { Config } from "@/core/shared/config/domain/config";
import { AccreditationRepository } from "@/core/accreditation/domain/accreditationRepository";
import { ApiResponse } from "@/core/shared/api/domain/apiClient";
import { PortrayalStorage } from "@/core/portrayal/domain/portrayalStorage";

export interface AccreditationUseCases {
  request: <Type extends AccreditationType>(type: Type) => Promise<ApiResponse<Accreditation<Type>>>;
  refresh: (id: string) => Promise<ApiResponse<Accreditation>>;
  revoke: (id: string) => Promise<ApiResponse>;
  get: (id: string) => Promise<Accreditation>;
  getAll: () => Promise<Accreditation[]>;
  getImage: (id: string) => Promise<Blob>;
  getPdf: () => Promise<Blob>;
}

export const accreditationUseCases = ({
  accreditationRepository,
  authRepository,
  portrayalStorage,
  config,
}: {
  accreditationRepository: AccreditationRepository;
  authRepository: AuthRepository;
  portrayalStorage: PortrayalStorage;
  config: Config;
}): AccreditationUseCases => {
  const useCase: AccreditationUseCases = {
    get: async id => {
      return await accreditationRepository.get({ userId: authRepository.get()!.userId, accreditationId: id });
    },
    getImage: async id => {
      return await accreditationRepository.getImage({ userId: authRepository.get()!.userId, accreditationId: id });
    },
    getPdf: async () => {
      const { id: accreditationId } = (await useCase.request(AccreditationType.Proof)).data;
      return await accreditationRepository.getPdf({ userId: authRepository.get()!.userId, accreditationId });
    },
    getAll: async () => {
      return await accreditationRepository.getAll(authRepository.get()!.userId);
    },
    request: async type => {
      const accreditationRequest = {
        location: config.tenant,
        userId: authRepository.get()!.userId,
        type,
        portrayalId:
          type === AccreditationType.Face && portrayalStorage.get().portrayalId
            ? portrayalStorage.get().portrayalId
            : undefined,
      };
      const response = await accreditationRepository.request(accreditationRequest);
      portrayalStorage.clear();

      return response;
    },
    refresh: async id => {
      return await accreditationRepository.refresh({ userId: authRepository.get()!.userId, accreditationId: id });
    },
    revoke: async id => {
      return await accreditationRepository.revoke({ userId: authRepository.get()!.userId, accreditationId: id });
    },
  };

  return useCase;
};
