import { ApiResponse } from "@/core/shared/api/domain/apiClient";
import { PortrayalRepository } from "@/core/portrayal/domain/portrayalRepository";
import { PortrayalProcess, PortrayalResult, PortrayalType } from "@/core/portrayal/domain/portrayal";
import { AuthRepository } from "@/core/user/domain/auth";
import { PortrayalStorage } from "@/core/portrayal/domain/portrayalStorage";

export interface PortrayalUseCases {
  start: (type: PortrayalType, process: PortrayalProcess) => Promise<ApiResponse>;
  sendSelfie: (selfieImage: string, aliveImage?: string) => Promise<ApiResponse>;
  sendLifeProof: (formData: FormData) => Promise<ApiResponse>;
  getResult: (portrayalId: string) => Promise<ApiResponse<PortrayalResult>>;
}

export const portrayalUseCases = ({
  portrayalRepository,
  authRepository,
  portrayalStorage,
}: {
  portrayalRepository: PortrayalRepository;
  authRepository: AuthRepository;
  portrayalStorage: PortrayalStorage;
}): PortrayalUseCases => ({
  start: async (type, process) => {
    const userId = authRepository.get()!.userId;
    const { data: startedPortrayal, ...response } = await portrayalRepository.start({ userId, type, process });
    portrayalStorage.set(startedPortrayal);
    return { ...response, data: undefined };
  },
  sendSelfie: async (selfieImage, aliveImage) =>
    await portrayalRepository.sendSelfie({ selfieImage, aliveImage, portrayalId: portrayalStorage.get().portrayalId }),
  sendLifeProof: async formData =>
    await portrayalRepository.sendLifeProof({ portrayalId: portrayalStorage.get().portrayalId, formData }),
  getResult: async portrayalId => {
    try {
      return await portrayalRepository.getResult(portrayalId);
    } catch (error) {
      portrayalStorage.clear();
      throw error;
    }
  },
});
