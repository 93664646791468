import { asFunction } from "@/_di/resolvers";
import { userUseCases, UserUseCases } from "@/core/user/useCases/userUseCases";
import { UserRepository } from "@/core/user/domain/userRepository";
import { apiUserRepository } from "@/core/user/infrastructure/apiUserRepository";
import { authStorage } from "@/core/user/infrastructure/authStorage";
import { authRepository } from "@/core/user/infrastructure/authRepository";
import { AuthRepository, AuthStorage } from "@/core/user/domain/auth";

export const userModule = {
  userUseCases: asFunction<UserUseCases>(userUseCases),
  userRepository: asFunction<UserRepository>(apiUserRepository),
  authRepository: asFunction<AuthRepository>(authRepository),
  authStorage: asFunction<AuthStorage>(authStorage),
};
