import { Component, Prop, Vue } from "vue-property-decorator";
import { Log } from "@/legacy/infrastructure/logger";
import { redirectUser } from "@/legacy/features/common/shared";

@Component
export default class Callback extends Vue {
  @Prop({ required: false, default: "" }) public saloon!: string;

  public mounted() {
    this.callback();
  }

  public async callback() {
    try {
      const auth = await this.$services.authentication.callback();

      Vue.prototype.$container.authRepository.set(auth);

      if (auth.locale) {
        localStorage.setItem("locale", auth.locale);
        this.$i18n.locale = auth.locale;
      }

      this.saloon = this.$route.query.saloon ? this.$route.query.saloon.toString() : "";
      if (!this.$appStore.getters.isUser) {
        this.saloon = auth.profile ?? "";
      }

      localStorage.setItem("saloon", this.saloon);

      this.$appStore.actions.resetOnboarding();
      await redirectUser();
    } catch (error) {
      Log.info("error: " + error);
      this.$services.authentication.logout();
    }
  }
}
