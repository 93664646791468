import { ErrorMapper } from "@/core/shared/api/domain/serializers";
import { AccreditationRequestErrors } from "@/core/accreditation/domain/accreditationErrors";
import { accreditationDeserializer } from "@/core/accreditation/infrastructure/accreditationDeserializer";

export const accreditationRequestErrorMapper: ErrorMapper = {
  credential_already_exists: {
    reason: AccreditationRequestErrors.CredentialAlreadyExist,
    dataMapper: accreditationDeserializer,
  },
};
