import { Link } from "../domain/apiClient";
import { isString } from "../../utils";

const createParamsObjectReducer = (paramsObject: Record<string, string>, rawParam: string) => {
  const [, paramKey, paramValue] = rawParam.match(/\s*(.+)\s*=\s*"?([^"]+)"?/)!;
  paramsObject[paramKey] = paramValue;
  return paramsObject;
};

const parseParams = (rawParams: string) => {
  const paramsArray = rawParams.split(";");
  paramsArray.shift();
  return paramsArray.reduce(createParamsObjectReducer, {});
};

export const parseLinkHeader = (linkHeader: unknown): Link | undefined => {
  if (!linkHeader || !isString(linkHeader)) return undefined;

  try {
    const [, uri, rawParams] = linkHeader.match(/<?([^>]*)>(.*)/)!;
    const params = parseParams(rawParams);
    return { uri, params };
  } catch (e) {
    return undefined;
  }
};
