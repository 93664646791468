import { Facility } from "@/core/facility/domain/facility";
import { FacilityRepository } from "../domain/facilityRepository";

export interface FacilityUseCases {
  getFacilities: () => Promise<Facility[]>;
}

export const facilityUseCases = ({
  facilityRepository,
}: {
  facilityRepository: FacilityRepository;
}): FacilityUseCases => ({
  getFacilities: async () => {
    return (await facilityRepository.getFacilities()).data;
  },
});
