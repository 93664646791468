import { MutationTree } from "vuex";
import { GetVisitsRequest } from "../models/visits.models";
import { VisitSearchMutationsType } from "./contract";
import { VisitSearchState } from "./state";

const mutations: MutationTree<VisitSearchState> = {};

mutations[VisitSearchMutationsType.SaveSearch] = (
  state: VisitSearchState,
  payload: {
    filterdata: GetVisitsRequest;
    bookmarks: string[];
  }
) => {
  state.filterdata = payload.filterdata;
  state.bookmarks = payload.bookmarks;
};

export default mutations;
