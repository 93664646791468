import { Deserializer } from "@/core/shared/api/domain/serializers";
import { Facility } from "../domain/facility";

export interface FacilityDto {
  facility_id: string;
  alias: string;
  timezone: string;
}

export const facilitiesDeserializer: Deserializer<Facility[], FacilityDto[]> = {
  parse: data =>
    data.map(facilityDto => ({
      id: facilityDto.facility_id,
      alias: facilityDto.alias,
      timezone: facilityDto.timezone,
    })),
};
