import { GetterTree } from "vuex";
import RootState from "@/legacy/store/rootState";

import { VisitSearchState } from "./state";
import { VisitSearchGettersType } from "./contract";

const getters: GetterTree<VisitSearchState, RootState> = {};

getters[VisitSearchGettersType.Bookmarks] = (visitSearchState: VisitSearchState) => visitSearchState.bookmarks;
getters[VisitSearchGettersType.FilterData] = (visitSearchState: VisitSearchState) => visitSearchState.filterdata;

export default getters;
