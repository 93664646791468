import { Auth, Claims } from "@/core/user/domain/auth";
import { UserDetail } from "./user";
import { ApiResponse } from "@/core/shared/api/domain/apiClient";

export type UserData = Partial<Pick<Auth, Claims>>;

export enum RegisterMode {
  Auto = "auto",
  Assisted = "assisted",
}

export interface UserRepository {
  enroll: (userData: UserData, registerMode: RegisterMode) => Promise<void>;
  getUserDetail: (id: string) => Promise<ApiResponse<UserDetail>>;
  getUserSelfie: (id: string) => Promise<ApiResponse<string>>;
}
