import { RegisterType } from "@/legacy/models/shared";

export enum OnboardingMutationsType {
  StartOnboarding = "startOnboarding",
  ResetOnboarding = "resetOnboarding",
  StartIdentityPortrayal = "startIdentityPortrayal",
  CancelIdentityPortrayal = "cancelIdentityPortrayal",
  SetRegisterMode = "setRegisterMode",
  SetConfirmData = "setConfirmData",
}

export enum OnboardingActionsType {
  StartOnboarding = "startOnboarding",
  ResetOnboarding = "resetOnboarding",
  StartIdentityPortrayal = "startIdentityPortrayal",
  CancelIdentityPortrayal = "cancelIdentityPortrayal",
  SetRegisterMode = "setRegisterMode",
  SetConfirmData = "setConfirmData",
}

export enum OnboardingGettersType {
  OnboardingId = "onboardingId",
  IdentityPortrayalId = "identityPortrayalId",
  DocumentType = "documentType",
  DocumentCountry = "documentCountry",
  GetRegisterType = "registerType",
  IdentityProofing = "identityProofing",
  GetRegisterMode = "getRegisterMode",
  LifeProofing = "lifeProofing",
  BiometricQR = "biometricQR",
  AssistedUserId = "assistedUserId",
  AssistedEmail = "assistedEmail",
  SupportedDocs = "supportedDocs",
  GetConfirmData = "getConfirmData",
}

export interface OnboardingGetters {
  onboardingId: string;
  identityPortrayalId: string;
  documentType: string;
  documentCountry: string;
  registerType: RegisterType;
  identityProofing: boolean;
  lifeProofing: boolean;
  biometricQR: boolean;
}

export interface OnboardingActions {
  startOnboarding(
    onboardingId: string,
    registerType: RegisterType,
    lifeProofing: boolean,
    biometricQR: boolean,
    supportedDocs: { [key: string]: string[] },
    assistedUserId?: string,
    assistedEmail?: string
  ): void;
  resetOnboarding(): void;
  startIdentityPortrayal(payload: { identityPortrayalId: string; documentType: string; documentCountry: string }): void;
  cancelIdentityPortrayal(): void;
}
