import { RouteConfig } from "vue-router";
import { RouteNames } from "@/ui/_router/names";
import {
  accountManagerGuard,
  assistantNeededGuard,
  auditorGuard,
  batchOnboardingGuard,
  canSeeDeleteAccountGuard,
  canSeePortalGuard,
  canSeeProfileGuard,
  myAccreditationsGuard,
  singleOnboardingGuard,
  uploadWatchlistGuard,
  userHomeGuard,
  userManagerGuard,
} from "@/ui/_router/guards";
import Vue from "vue";

const MyProfile = () => import(/* webpackChunkName: "bundle-portal" */ "@/ui/Profile/MyProfile/MyProfile.vue");
const DeleteAccount = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Profile/DeleteAccount/DeleteAccount.vue");
const PortalManagement = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Portal/PortalManagement/PortalManagement.vue");
const PortalAccounts = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Portal/PortalAccounts/PortalAccounts.vue");
const PortalUsers = () => import(/* webpackChunkName: "bundle-portal" */ "@/ui/Portal/PortalUsers/PortalUsers.vue");
const CheckInterdictions = () =>
  import(/* webpackChunkName: "bundle-gambling" */ "@/ui/Portal/CheckInterdictions/CheckInterdictions.vue");
const UploadWatchlist = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Portal/UploadWatchlist/UploadWatchlist.vue");
const UploadWatchlistResult = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Portal/UploadWatchlistResult/UploadWatchlistResult.vue");
const UserDetail = () => import(/* webpackChunkName: "bundle-portal" */ "@/ui/Portal/UserDetail/UserDetail.vue");
const ActivateUsers = () =>
  import(/* webpackChunkName: "bundle-gambling" */ "@/ui/Portal/ActivateUsers/ActivateUsers.vue");
const MyAccreditations = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Profile/MyAccreditations/MyAccreditations.vue");
const AccreditationDetail = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Profile/AccreditationDetail/AccreditationDetail.vue");
const AccreditFace = () => import(/* webpackChunkName: "bundle-portal" */ "@/ui/Profile/AccreditFace/AccreditFace.vue");
const AccreditProof = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Profile/AccreditProof/AccreditProof.vue");
const SingleRegistration = () =>
  import(/* webpackChunkName: "bundle-admin" */ "@/ui/Portal/SingleRegistration/SingleRegistration.vue");
const OnboardingResult = () =>
  import(/* webpackChunkName: "bundle-portal" */ "@/ui/Onboarding/OnboardingResult/OnboardingResult.vue");
const BatchRegistration = () =>
  import(/* webpackChunkName: "bundle-admin" */ "@/ui/Portal/BatchRegistration/BatchRegistration.vue");
const BatchRegistrationResult = () =>
  import(/* webpackChunkName: "bundle-admin" */ "@/ui/Portal/BatchRegistrationResult/BatchRegistrationResult.vue");
const AssistantAccountCancellation = () =>
  import(
    /* webpackChunkName: "bundle-gambling" */ "@/ui/Portal/AssistantAccountCancellation/AssistantAccountCancellation.vue"
  );
const AuditAccesses = () =>
  import(/* webpackChunkName: "bundle-gambling" */ "@/ui/Portal/AuditAccesses/AuditAccesses.vue");
const ResetCredentials = () =>
  import(/* webpackChunkName: "bundle-gambling" */ "@/ui/Portal/ResetCredentials/ResetCredentials.vue");

const UserHome = () => import(/* webpackChunkName: "bundle-portal" */ "@/ui/Portal/UserHome/UserHome.vue");

export const onProfileRoutes = (): RouteConfig[] => [
  {
    path: RouteNames.UserProfile,
    name: RouteNames.UserProfile,
    component: MyProfile,
    beforeEnter: Vue.prototype.$container.guardFactory(canSeeProfileGuard),
  },
  {
    path: RouteNames.DeleteAccount,
    name: RouteNames.DeleteAccount,
    component: DeleteAccount,
    beforeEnter: Vue.prototype.$container.guardFactory(canSeeDeleteAccountGuard),
    props: true,
  },
  {
    path: RouteNames.MyAccreditations,
    name: RouteNames.MyAccreditations,
    component: MyAccreditations,
    beforeEnter: Vue.prototype.$container.guardFactory(myAccreditationsGuard),
    props: true,
  },
  {
    path: RouteNames.AccreditationDetail,
    name: RouteNames.AccreditationDetail,
    component: AccreditationDetail,
    beforeEnter: Vue.prototype.$container.guardFactory(myAccreditationsGuard),
    props: true,
  },
  {
    path: RouteNames.AccreditFace,
    name: RouteNames.AccreditFace,
    component: AccreditFace,
    beforeEnter: Vue.prototype.$container.guardFactory(myAccreditationsGuard),
    props: true,
  },
  {
    path: RouteNames.AccreditProof,
    name: RouteNames.AccreditProof,
    component: AccreditProof,
    props: true,
  },
];

export const onPortalRoutes = (): RouteConfig[] => [
  {
    path: "",
    name: RouteNames.PortalManagement,
    component: PortalManagement,
    beforeEnter: Vue.prototype.$container.guardFactory(canSeePortalGuard),
  },
  {
    path: RouteNames.PortalAccounts,
    name: RouteNames.PortalAccounts,
    component: PortalAccounts,
    beforeEnter: Vue.prototype.$container.guardFactory(accountManagerGuard),
  },
  {
    path: RouteNames.PortalUsers,
    name: RouteNames.PortalUsers,
    component: PortalUsers,
    beforeEnter: Vue.prototype.$container.guardFactory(userManagerGuard),
  },
  {
    path: RouteNames.PortalUserDetail + "/:id",
    name: RouteNames.PortalUserDetail,
    component: UserDetail,
    beforeEnter: Vue.prototype.$container.guardFactory(userManagerGuard),
  },
  {
    path: RouteNames.ResetCredentials,
    name: RouteNames.ResetCredentials,
    component: ResetCredentials,
  },
  {
    path: RouteNames.UserHome,
    name: RouteNames.UserHome,
    component: UserHome,
    beforeEnter: Vue.prototype.$container.guardFactory(userHomeGuard),
  },
  {
    path: RouteNames.SingleRegistration,
    name: RouteNames.SingleRegistration,
    component: SingleRegistration,
    beforeEnter: Vue.prototype.$container.guardFactory(singleOnboardingGuard),
  },
  {
    path: RouteNames.DelegatedFinishRegister,
    name: RouteNames.DelegatedFinishRegister,
    component: OnboardingResult,
    props: { success: true },
    beforeEnter: Vue.prototype.$container.guardFactory(singleOnboardingGuard),
  },
  {
    path: RouteNames.BatchRegistration,
    name: RouteNames.BatchRegistration,
    component: BatchRegistration,
    beforeEnter: Vue.prototype.$container.guardFactory(batchOnboardingGuard),
  },
  {
    path: RouteNames.BatchRegistrationResult,
    name: RouteNames.BatchRegistrationResult,
    component: BatchRegistrationResult,
    props: true,
    beforeEnter: Vue.prototype.$container.guardFactory(batchOnboardingGuard),
  },
  {
    path: RouteNames.AuditAccesses,
    name: RouteNames.AuditAccesses,
    component: AuditAccesses,
    props: true,
    beforeEnter: Vue.prototype.$container.guardFactory(auditorGuard),
  },
  {
    path: RouteNames.ActivateUsers,
    name: RouteNames.ActivateUsers,
    component: ActivateUsers,
    beforeEnter: Vue.prototype.$container.guardFactory(assistantNeededGuard),
  },
  {
    path: RouteNames.CheckInterdictions,
    name: RouteNames.CheckInterdictions,
    component: CheckInterdictions,
    beforeEnter: Vue.prototype.$container.guardFactory(assistantNeededGuard),
  },
  {
    path: RouteNames.AssistantAccountCancellation,
    name: RouteNames.AssistantAccountCancellation,
    component: AssistantAccountCancellation,
    beforeEnter: Vue.prototype.$container.guardFactory(assistantNeededGuard),
  },
  {
    path: RouteNames.UploadWatchlist,
    name: RouteNames.UploadWatchlist,
    component: UploadWatchlist,
    beforeEnter: Vue.prototype.$container.guardFactory(uploadWatchlistGuard),
  },
  {
    path: RouteNames.UploadWatchlistResult,
    name: RouteNames.UploadWatchlistResult,
    component: UploadWatchlistResult,
    props: route => ({ success: route.params.success === "true" }),
    beforeEnter: Vue.prototype.$container.guardFactory(uploadWatchlistGuard),
  },
];
