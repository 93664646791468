import { ActionContext, ActionTree } from "vuex";
import { AuthActionsType, AuthMutationsType } from "@/legacy/features/authentication/store/contract";
import RootState from "@/legacy/store/rootState";
import { Credentials } from "@/legacy/features/authentication/credentials.models";

const actions: ActionTree<Credentials, RootState> = {};

actions[AuthActionsType.CredentialsStored] = (
  store: ActionContext<Credentials, RootState>,
  credentials: Credentials
) => {
  store.commit(AuthMutationsType.CredentialsStored, credentials);
};

actions[AuthActionsType.OnboardingStateStored] = (store: ActionContext<Credentials, RootState>, enrolled: boolean) => {
  store.commit(AuthMutationsType.OnboardingStateStored, enrolled);
};

actions[AuthActionsType.CredentialsRemoved] = (store: ActionContext<Credentials, RootState>) => {
  store.commit(AuthMutationsType.CredentialsRemoved);
};

actions[AuthActionsType.AccessTokenStored] = (store: ActionContext<Credentials, RootState>, accessToken: string) => {
  store.commit(AuthMutationsType.AccessTokenStored, accessToken);
};

export default actions;
