// tslint:disable:no-console
const shouldLogOnConsole = process.env.NODE_ENV !== "production";

export const Log = {
  info: (message?: any, ...optionalParams: any[]) => {
    if (shouldLogOnConsole) {
      console.info(message, ...optionalParams);
    }
  },
  warn: (message?: any, ...optionalParams: any[]) => {
    if (shouldLogOnConsole) {
      console.warn(message, ...optionalParams);
    }
  },
  error: (message?: any, ...optionalParams: any[]) => {
    if (shouldLogOnConsole) {
      console.error(message, ...optionalParams);
    }
  },
};
