export interface INotification {
  message: string;
  type: NotificationType;
}

export enum NotificationType {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Error = "error",
}

export interface INotificationService {
  info(message: string): void;
  success(message: string): void;
  warning(message: string): void;
  error(message: string): void;
  destroy(notification: INotification): void;
  destroyAllErrors(): void;
  subscribe(next: (value: INotification[]) => void): void;
}
