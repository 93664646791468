import dotenv from "dotenv";

dotenv.config();

interface IConfiguration {
  [key: string]: string;
}

export default class ConfigProvider {
  public static get Configuration(): IConfiguration {
    const productionMode = process.env.VUE_APP_DEBUG === "false" || process.env.VUE_APP_DEBUG === undefined;
    return {
      backendHost: "$VUE_APP_API_URL",
      version: "$APP_VERSION",
      assetsUrl: productionMode ? "$VUE_APP_ASSETS_URL" : window.location.origin + "/",
      iam: "$VUE_APP_IAM",
      watchAltHeader: "$VUE_APP_WATCH_ALT_HEADER",
    };
  }

  public static value(name: string) {
    if (!(name in this.Configuration)) {
      return;
    }

    const value = this.Configuration[name];

    if (!value) {
      return;
    }

    if (value.startsWith("$VUE_APP_")) {
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        return;
      }
    } else {
      return value;
    }
  }
}
