import { NewVisitRequest, VisitRepository } from "../domain/visitRepository";
import { ApiClient } from "@/core/shared/api/domain/apiClient";
import { visitDeserializer } from "@/core/visit/infraestructure/visitResponseDeserializer";
import { visitRequestSerializer } from "@/core/visit/infraestructure/visitRequestSerializer";

export const apiVisitRepository = ({ apiClient }: { apiClient: ApiClient }): VisitRepository => ({
  addNewVisit: async (newVisitRequest: NewVisitRequest) => {
    return await apiClient.post(`/public/visits`, newVisitRequest, {
      mapResponse: visitDeserializer,
      mapRequest: visitRequestSerializer,
    });
  },
});
