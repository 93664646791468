import Vue from "vue";
import { WatchAltHeader } from "@/legacy/models/constants";
import {
  ActivateCredentialRequest,
  CancelUserRequest,
  CancelUserResponse,
  CreateAccountResponse,
  Credential,
  DeactivateCredentialRequest,
  DeleteUserDataRequest,
  EnforcementResult,
  ExternalUserInfo,
  GetIamUserDetailResponse,
  GetIamUserRequest,
  GetUserCredentialRequest,
  GetUserCredentialsResponse,
  GetUserDetailRequest,
  GetUserDetailResponse,
  GetUserMediaRequest,
  GetUserQrImageResponse,
  GetUsersRequest,
  GetUsersResponse,
  PostAnonymousUser,
  PostEnforcement,
  PostLocationRequest,
  PutUserLocale,
  QrCredentialInfo,
  SearchUsersResponse,
  UserInfo,
} from "@/legacy/features/portal/models/portal.models";
import { UserService } from "../interfaces/user-service";
import { getHttpClient } from "@/legacy/features/authentication/auth-http-client.service";
import { AxiosRequestConfig } from "axios";

interface UserInfoRaw {
  user_id: string;
  acs_id: string;
  name: string;
  surname: string;
  username: string;
  email: string;
  created_at: string;
}

interface ExternalUserInfoRaw {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  date_register: Date;
  createdTimestamp?: string;
  onboarding_status?: string;
  active: boolean;
}

interface GetUsersRawResponse {
  page_size: number;
  total_pages: number;
  total_items: number;
  items: UserInfoRaw[];
  bookmark: string;
}

interface GetUsersRawRequest {
  pageSize: number;
  pageIndex: number;
  bookmark: string;
  filter?: string;
  idNumber?: string;
  birthDate?: string;
  orderby: string;
  sorted: string;
}

interface SearchUsersRawRequest {
  pageSize: number;
  pageIndex: number;
  filter?: string;
}

interface SearchUsersRawResponse {
  total_items: number;
  items: ExternalUserInfoRaw[];
}

interface GetUserCrendentialRawResponse {
  user_id: string;
  acs_id: string;
  activated: boolean;
  credentials: QrCredentialInfo[];
}

interface GetUserDetailRawResponse {
  user_id: string;
  acs_id: string;
  created_at: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  id_number: string;
  nationality: string;
  portrayal_id: string;
  onboarding_id: string;
}

interface GetUserCredentialsRaw {
  activated: boolean;
  locations: string[];
  credentials: { [credentialType: string]: GetUserDetailCredentialRawResponse };
  metadata: any;
}

interface GetUserDetailCredentialRawResponse {
  sid: string;
  created_at: string;
  credential_type: string;
}

interface PostLocationRawRequest {
  object: string;
  subject: string;
}

interface CancelUserRawRequest {
  id_number?: string;
}

export class HttpUserService implements UserService {
  public async deactivateCredential(request: DeactivateCredentialRequest): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.put<GetUserCrendentialRawResponse>(`/public/credentials/${request.userId}/inactive`);
    return;
  }

  public async activateCredential(request: ActivateCredentialRequest): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.put<GetUserCrendentialRawResponse>(`/public/credentials/${request.userId}/active`);
    return;
  }

  public async getCredentials(request: GetUserDetailRequest): Promise<GetUserCredentialsResponse> {
    const httpClient = getHttpClient();
    try {
      const rawResponse = await httpClient.get<GetUserCredentialsRaw>(`/public/credentials/${request.id}`);
      return {
        activated: rawResponse.data.activated,
        locations: rawResponse.data.locations,
        credentials: Object.keys(rawResponse.data.credentials).map(elementKey => {
          const element = rawResponse.data.credentials[elementKey];
          return {
            sid: element?.sid || "",
            createdAt: element?.created_at || new Date(),
            credentialType: element?.credential_type || "",
          } as Credential;
        }),
        metadata: rawResponse.data.metadata,
      };
    } catch (ex) {
      return {
        activated: false,
        locations: [],
        credentials: [],
      };
    }
  }

  public async deleteData(request: DeleteUserDataRequest): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.delete<GetUserCrendentialRawResponse>(`/internal/accounts/${request.id}`);
    return;
  }

  public async getQrImage(request: GetUserCredentialRequest): Promise<GetUserQrImageResponse> {
    const httpClient = getHttpClient();
    const config: AxiosRequestConfig = {
      responseType: "blob",
      headers: {
        Accept: "image/png",
      },
    };
    const rawResponse = await httpClient.get(`/public/credentials/${request.userId}/sids/${request.sid}`, config);
    const image = {
      content: rawResponse.data,
    };

    return image;
  }

  public async getUserDetail(request: GetUserDetailRequest): Promise<GetUserDetailResponse> {
    const httpClient = getHttpClient();

    const rawResponse = await httpClient.get<GetUserDetailRawResponse>(`/public/users/${request.id}`);

    return {
      username: rawResponse.data.username,
      firstName: rawResponse.data.first_name,
      lastName: rawResponse.data.last_name,
      id: rawResponse.data.user_id,
      email: rawResponse.data.email,
      acsId: rawResponse.data.acs_id,
      registerDate: rawResponse.data.created_at,
      documentId: rawResponse.data.id_number,
      nationality: rawResponse.data.nationality,
      portrayalId: rawResponse.data.portrayal_id,
      onboardingId: rawResponse.data.onboarding_id,
    };
  }

  public async getIamUser(request: GetIamUserRequest): Promise<GetIamUserDetailResponse> {
    const httpClient = getHttpClient();
    try {
      const rawResponse = await httpClient.get<ExternalUserInfoRaw>(`/internal/accounts?userId=${request.userId}`);
      return {
        id: rawResponse.data.id,
        username: rawResponse.data.username,
        firstName: rawResponse.data.firstName,
        lastName: rawResponse.data.lastName,
        email: rawResponse.data.email,
        date_register: rawResponse.data.createdTimestamp ? new Date(rawResponse.data.createdTimestamp) : new Date(),
      };
    } catch (ex) {
      return {
        id: "",
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        date_register: new Date(),
      };
    }
  }

  public async cancelUser(request: CancelUserRequest): Promise<CancelUserResponse> {
    const httpClient = getHttpClient();
    let response: CancelUserResponse = {
      success: false,
    };
    const payload: CancelUserRawRequest = {
      id_number: request.idNumber,
    };

    await httpClient
      .delete(`/internal/accounts/${request.id}`, {
        data: request.idNumber ? payload : undefined,
      })
      .then(() => {
        response = { success: true };
      })
      .catch(error => {
        response = { success: false, errorMessage: error.response.data.detail };
      });
    return response;
  }

  public async mapUser(element: any) {
    const active = (await this.getCredentials({ id: element.user_id })).activated;
    return {
      id: element?.user_id,
      name: element?.username,
      surname: element?.surname,
      email: element?.email,
      username: element?.username,
      date_register: element?.created_at ? new Date(element?.created_at) : null,
      active,
    } as UserInfo;
  }

  public async mapIamUser(element: any) {
    const active = true;
    return {
      id: element?.id,
      username: element?.username,
      firstName: element?.firstName,
      lastName: element?.lastName ? element?.lastName : "",
      email: element?.email,
      date_register: element?.createdTimestamp ? new Date(element?.createdTimestamp) : null,
      onboarding_status: element?.onboarding_status,
      active,
    } as ExternalUserInfo;
  }

  public async getUsers(request: GetUsersRequest): Promise<GetUsersResponse> {
    const httpClient = getHttpClient();

    const rawRequest: GetUsersRawRequest = {
      pageSize: request.pageSize,
      pageIndex: request.pageIndex,
      bookmark: request.bookmark,
      filter: request.filter !== "" ? `\\"${request.filter}\\"` : request.filter,
      orderby: request.orderby,
      sorted: request.sorted,
    };
    if (request.idNumber) {
      rawRequest.idNumber = request.idNumber;
      rawRequest.birthDate = request.birthDate;
    }

    const rawResponse = await httpClient.get<GetUsersRawResponse>(`/public/users`, {
      params: rawRequest,
    });

    return {
      items: await Promise.all(rawResponse.data.items.map(element => this.mapUser(element))),
      totalPages: rawResponse.data.total_pages,
      totalItems: rawResponse.data.total_items,
      pageSize: rawResponse.data.page_size,
      bookmark: rawResponse.data.bookmark,
    } as GetUsersResponse;
  }

  public async searchAccounts(request: GetUsersRequest): Promise<SearchUsersResponse> {
    const httpClient = getHttpClient();

    const rawRequest: SearchUsersRawRequest = {
      pageSize: request.pageSize,
      pageIndex: request.pageIndex,
      filter: request.filter,
    };

    const rawResponse = await httpClient.get<SearchUsersRawResponse>(`/public/accounts`, {
      params: rawRequest,
    });
    return {
      items: await Promise.all(rawResponse.data.items.map(element => this.mapIamUser(element))),
      totalItems: rawResponse.data.total_items,
      totalPages: Math.ceil(rawResponse.data.total_items / request.pageSize),
      pageSize: request.pageSize,
      bookmark: request.bookmark,
    } as SearchUsersResponse;
  }

  public async getUserSelfie(request: GetUserMediaRequest): Promise<string> {
    try {
      const httpClient = getHttpClient();
      const rawResponse = await httpClient.get(`/public/users/${request.id}/selfie`);
      if (rawResponse.status === 200) {
        return rawResponse.data;
      }
      return "";
    } catch (ex) {
      return "";
    }
  }

  public async getUserObverse(request: GetUserMediaRequest): Promise<string> {
    try {
      const httpClient = getHttpClient();
      const rawResponse = await httpClient.get(`/public/users/${request.id}/obverse`);
      if (rawResponse.status === 200) {
        return rawResponse.data;
      }
      return "";
    } catch (ex) {
      return "";
    }
  }

  public async getUserReverse(request: GetUserMediaRequest): Promise<string> {
    try {
      const httpClient = getHttpClient();
      const rawResponse = await httpClient.get(`/public/users/${request.id}/reverse`);
      if (rawResponse.status === 200) {
        return rawResponse.data;
      }
      return "";
    } catch (ex) {
      return "";
    }
  }

  public async addNewLocation(request: PostLocationRequest): Promise<GetUserCredentialsResponse> {
    const httpClient = getHttpClient();
    const payload: PostLocationRawRequest = {
      object: request.location,
      subject: request.id,
    };

    await httpClient.post(`/internal/rights`, payload);

    return await this.getCredentials({ id: request.id });
  }

  public async createAnonymousUser(request: PostAnonymousUser): Promise<CreateAccountResponse> {
    const httpClient = getHttpClient();
    const payload = { email: request.email };

    const rawResponse = await httpClient.post("/internal/accounts", payload);
    return {
      userId: rawResponse.data.user_id,
    };
  }

  public async updateLocale(request: PutUserLocale): Promise<void> {
    const httpClient = getHttpClient();
    try {
      await httpClient.put(`/internal/accounts/${request.userId}/locale`, { locale: request.locale });
      return;
    } catch (ex) {
      return;
    }
  }

  public async enforceUser(enforcement: PostEnforcement): Promise<EnforcementResult> {
    const httpClient = getHttpClient();
    let response;
    if (Vue.prototype.$appStore.getters.settings.useWatch) {
      response = await httpClient.post(`/enforcements`, enforcement, {
        headers: { "Alt-Used": WatchAltHeader },
      });
    } else {
      response = await httpClient.post(`/internal/enforcements`, enforcement);
    }
    return {
      result: response.data.result,
      reason: response.data.reason,
    } as EnforcementResult;
  }
}
