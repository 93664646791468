/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from "axios";
import { getHttpClient } from "@/legacy/features/authentication/auth-http-client.service";
import { OnboardingService } from "@/legacy/features/onboarding/interfaces/onboarding-service";
import {
  AcceptTermsRequest,
  ConfirmOnboardingRequest,
  ConfirmOnboardingResponse,
  GetAcsidStatusRequest,
  GetUserOnboardingRequest,
  StartOnboardingRequest,
  StartOnboardingResponse,
  StopOnboardingRequest,
} from "@/legacy/features/onboarding/models/onboarding.models";
import { TenantSettings } from "@/legacy/models/settings";
import { getRegisterType } from "@/legacy/infrastructure/shared";
import { OnboardingInfo } from "@/legacy/models/shared";
import {
  encodeConfirmOnboardingRequest,
  parseErrorReason,
} from "@/legacy/features/onboarding/services/http-onboarding.serializer";

export type AcceptedRegisterType =
  | "none"
  | "WithDocument"
  | "OnlySelfie"
  | "GamblingAssisted"
  | "CorporateAssisted"
  | "CorporateAuto";

interface StartOnboardingRawRequest {
  mode: "auto" | "assisted";
  location?: string;
  user_id?: string;
}

interface StartOnboardingRawResponse {
  onboarding_id: string;
  onboarding_type: AcceptedRegisterType;
  biometric_qr: boolean;
  identity_proofing: boolean;
  life_proofing: boolean;
  supported_docs: string;
}

interface ConfirmOnboardingRawRequestWithDocument {
  first_name: string;
  last_name: string;
  birth_date: string;
  id_number?: string;
  document_number?: string;
  expiry_date: string;
  expedition_country: string;
  document_type: string;
  nie?: string;
  skip_email_check?: boolean;
  email?: string;
  extra_fields?: { [key: string]: string };
}

interface ConfirmOnboardingRawRequestWithoutDocument {
  id: string;
  email?: string;
  skip_email_check?: boolean;
  extra_fields?: { [key: string]: string };
}

interface ConfirmOnboardingRawRequestWithoutDocumentWithId {
  acs_id: string;
  username: string;
  email?: string;
  skip_email_check?: boolean;
  extra_fields?: { [key: string]: string };
}

export interface DynamicConfirmOnboardingRawRequestWithoutDocument {
  [key: string]: string | boolean | number | DynamicConfirmOnboardingRawRequestWithoutDocument;
}

export type ConfirmOnboardingRawRequest =
  | ConfirmOnboardingRawRequestWithDocument
  | ConfirmOnboardingRawRequestWithoutDocument
  | ConfirmOnboardingRawRequestWithoutDocumentWithId
  | DynamicConfirmOnboardingRawRequestWithoutDocument;

interface ConfirmOnboardingRawResponse {
  onboarding_id: string;
  user_id: string;
  qr_sid?: string;
  mode: "auto" | "assisted";
  status: "initialized" | "running" | "completed" | "cancelled" | "failed" | "finished";
  result?: {
    outcome: "accepted" | "rejected";
    reason:
      | "document_expired"
      | "underaged"
      | "life_proofing_failed"
      | "not_withelisted"
      | "user_interdicted"
      | "ocr_incomplete"
      | "identity_verification_below_th"
      | "document_type_not_supported"
      | "ocr_adaptations_not_enabled"
      | "portrayal_not_completed"
      | "legal_terms_not_accepted"
      | "assisted_mode_not_enabled"
      | "user_already_registered"
      | "enforcement_source_unavailable"
      | "document_already_exists";
  };
}

export class HttpOnboardingService implements OnboardingService {
  constructor(private readonly settings: TenantSettings) {}

  public async start(request: StartOnboardingRequest): Promise<StartOnboardingResponse> {
    const httpClient = getHttpClient();
    const payload: StartOnboardingRawRequest = {
      mode: request.mode,
      location: request.location,
      user_id: request.userId,
    };
    const rawResponse = await httpClient.post<StartOnboardingRawResponse>("/internal/onboardings", payload);
    const responseRegisterType = getRegisterType(rawResponse.data.identity_proofing);
    return {
      onboardingId: rawResponse.data.onboarding_id,
      registerType: responseRegisterType,
      lifeProofing: rawResponse.data.life_proofing,
      biometricQR: rawResponse.data.biometric_qr,
      supportedDocs: rawResponse.data.supported_docs,
    };
  }

  public async stop(request: StopOnboardingRequest): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.delete<void>(`/internal/onboardings/${request.onboardingId}`);
  }

  public async acceptTerms(request: AcceptTermsRequest): Promise<void> {
    const httpClient = getHttpClient();
    await httpClient.put(`/internal/onboardings/${request.onboardingId}/legal_terms`);
  }

  public async getTermsOfUse(locale: string): Promise<string> {
    const axiosClient = axios.create({
      baseURL: this.settings.assetsUrl,
    });
    delete axiosClient.defaults.headers.Authorization;
    const rawResponse = await axiosClient.get<string>(`/tos-${locale}.md`);
    return rawResponse.data;
  }

  public async confirm(request: ConfirmOnboardingRequest): Promise<ConfirmOnboardingResponse> {
    const httpClient = getHttpClient();
    const rawRequest = encodeConfirmOnboardingRequest(request);

    let errorReason = "";
    try {
      const rawResponse = await httpClient.put<ConfirmOnboardingRawResponse>(
        `/internal/onboardings/${request.onboardingId}/portrayals/${request.identityPortrayalId}`,
        rawRequest
      );
      if (rawResponse.data.result?.outcome === "accepted") {
        localStorage.removeItem("documentData");
        return {
          userId: rawResponse.data.user_id,
          qrId: rawResponse.data.qr_sid,
          success: true,
        };
      }
      errorReason = rawResponse.data.result?.reason as string;
    } catch (error: any) {
      errorReason = error.response.data.reason;
    }
    const { errorCode, retry } = parseErrorReason(errorReason);

    return {
      success: false,
      code: errorCode,
      retry,
    };
  }

  public async acsidExists(request: GetAcsidStatusRequest): Promise<boolean> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.get(`/public/credentials?acs_id=` + request.acsid, {
      params: { silent: true },
      validateStatus: status => {
        return status === 200 || status === 404;
      },
    });
    if (rawResponse.status === 200) {
      return true;
    }
    return false;
  }

  public async getUserOnboarding(request: GetUserOnboardingRequest): Promise<OnboardingInfo> {
    const httpClient = getHttpClient();
    const rawResponse = await httpClient.get(`/internal/onboardings/${request.onboardingId}`);
    return {
      registerId: rawResponse.data.onboarding_id,
      registerType: rawResponse.data.mode,
      lifeProofing: rawResponse.data.life_proofing,
      identityProofing: rawResponse.data.identity_proofing !== null ? rawResponse.data.identity_proofing : false,
      modifiedFields: this.getModifiedFields(rawResponse.data.portrayals),
      assistedBy: rawResponse.data.assisted_by!,
      visitDate: rawResponse.data.visit_date!,
      host: rawResponse.data.host!,
    };
  }

  private getModifiedFields(element: any) {
    if (!element) {
      return null;
    }
    const portrayalId = Object.keys(element)[0];
    const fields = element[portrayalId].ocr_adaptations;
    if (!fields) {
      return null;
    }
    return {
      birthDate: fields.birth_date!.adapted,
      expiryDate: fields.expiry_date!.adapted,
      documentNumber: fields.document_number?.adapted,
      documentId: fields.id_number?.adapted,
      firstName: fields.first_name!.adapted,
      lastName: fields.last_name?.adapted,
      nationality: fields.nationality?.adapted,
      expeditionCountry: fields.expedition_country?.adapted,
    };
  }
}
