import Vue from "vue";
import { AxiosError, AxiosResponse } from "axios";
import { Log } from "@/legacy/infrastructure/logger";
import { redirectUser } from "@/legacy/features/common/shared";
import { IErrorsService } from "@/legacy/interfaces/error.service";

export class ErrorsService extends Vue implements IErrorsService {
  public onWarning(warning: string) {
    Log.warn(warning);
  }

  public async onError(error: Error) {
    const axiosError = error as AxiosError;
    if (axiosError.isAxiosError) {
      const response = axiosError.response;
      if (response!.status === 401) {
        Log.warn("not logged in");
        // await this.$services.authentication.logout();
      } else if (response!.status === 404) {
        this.$services.notificationService.error("Recurso no existe");
      } else if (response!.status === 405) {
        this.$services.notificationService.error("No permitido");
      } else if (response!.status === 500) {
        this.$services.notificationService.error("API Server error");
      } else if (response!.status === 422 || response!.status === 409) {
        Log.warn("Request error. Response:", response);
        this.$services.requestService.syncErrors(response);

        await this.checkIfUserEnrolled(response!);
      } else {
        const message = this.getMessageFromResponse(response!);
        this.$services.notificationService.warning(this.$tc(message));
      }
    } else {
      Log.error(error);
    }
  }

  private getMessageFromResponse(response: AxiosResponse<any>): string {
    if (response.data && response.data.detail && typeof response.data.detail === "string") {
      return response.data.detail;
    }

    if (response.data && response.data.title && typeof response.data.title === "string") {
      return response.data.title;
    }

    return response.statusText;
  }

  private async checkIfUserEnrolled(response: AxiosResponse<any>) {
    if (
      response.data &&
      response.data.detail &&
      response.data.detail.user_id &&
      response.data.detail.user_id === "user_already_registered"
    ) {
      Log.error("user_already_registered");
      this.$container.authRepository.update({ enrolled: true });

      await redirectUser();
    }
  }
}
