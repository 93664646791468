import { GetVisitsRequest } from "../models/visits.models";

export interface VisitSearchState {
  filterdata: GetVisitsRequest | null;
  bookmarks: string[] | null;
}

const state: VisitSearchState = {
  filterdata: null,
  bookmarks: null,
};

export default state;
