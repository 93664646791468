import { MutationTree } from "vuex";
import { GetUsersRequest } from "../models/portal.models";
import { UserSearchMutationsType } from "./contract";
import { UserSearchState } from "./state";

const mutations: MutationTree<UserSearchState> = {};

mutations[UserSearchMutationsType.SaveSearch] = (
  state: UserSearchState,
  payload: {
    filterdata: GetUsersRequest;
    bookmarks: string[];
  }
) => {
  state.filterdata = payload.filterdata;
  state.bookmarks = payload.bookmarks;
};

export default mutations;
