import { NewVisitRequest, VisitRepository } from "../domain/visitRepository";
import { Visit } from "@/core/visit/domain/visit";

export interface VisitUseCases {
  addNewVisit: (newVisitRequest: NewVisitRequest) => Promise<Visit>;
}

export const visitUseCases = ({ visitRepository }: { visitRepository: VisitRepository }): VisitUseCases => ({
  addNewVisit: async (newVisitRequest: NewVisitRequest) => {
    return (await visitRepository.addNewVisit(newVisitRequest)).data;
  },
});
