import { Module } from "vuex";
import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import RootState from "@/legacy/store/rootState";
import { Credentials } from "../credentials.models";

const authModule: Module<Credentials, RootState> = {
  actions,
  state,
  getters,
  mutations,
};

export default authModule;
