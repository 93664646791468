import { TenantSettings } from "@/legacy/models/settings";

// tslint:disable-next-line: no-empty-interface
export type SettingsState = TenantSettings;

const state: SettingsState = {
  assetsUrl: "",
  tenant: "",
  title: "",
  userRegisterEnabled: false,
  domain: "gambling",
  assistantRegisterEnabled: true,
  biometricQR: false,
  acsIdSchema: "string64",
  registerTypes: {},
  bmsServices: [],
  visits: { locations: [], mustAcceptPlan: false },
  auditAccessesLevel: "",
  locales: [],
  watchlistDomains: [],
  useWatch: false,
  extendedInterdictionForm: false,
};

export default state;
