import { Component, Vue } from "vue-property-decorator";
import { DgGridContainer, DgGridContent, DgLayout } from "@dasgate/uikit";
import { PageHeader } from "./_components/Header";
import { PageMenu } from "./_components/Menu";

@Component({ components: { PageHeader, DgGridContainer, DgGridContent, DgLayout, PageMenu } })
export default class Page extends Vue {
  public isMenuOpen = false;

  public onMenuClose() {
    this.isMenuOpen = false;
  }

  public onMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  get classes() {
    return [this.isMenuOpen && "--visible"];
  }
}
