import { apiResponse, ApiResponse } from "../domain/apiClient";
import { parseLinkHeader } from "./parseLinkHeader";
import { ServerResponse } from "../domain/server";

export const parseServerResponse = <ResponseData, ResponseDto>(
  serverResponse: ServerResponse<ResponseDto>,
  parseResponse: (dto: ResponseDto) => ResponseData
): ApiResponse<ResponseData> =>
  apiResponse(
    serverResponse.data !== "" ? parseResponse(serverResponse.data) : (undefined as ResponseData),
    parseLinkHeader(serverResponse.headers.link)
  );
