import { StatusCodes } from "./apiClient";
import { has } from "@/core/shared/utils";

export interface Server {
  request<ResponseDto, RequestDto>(config: ServerRequestConfig<RequestDto>): Promise<ServerResponse<ResponseDto>>;
}

export interface ServerResponse<ResponseDto> {
  status: StatusCodes;
  data: ResponseDto;
  headers: Record<string, unknown>;
}

export interface ServerError extends Error {
  response: ServerResponse<unknown>;
  isServerError: true;
}

export interface ServerRequestConfig<RequestData> {
  url: string;
  method: ServerMethod;
  data?: RequestData;
  headers?: Record<string, any>;
  responseType?: ServerResponseType;
}

export type ServerResponseType = "blob" | "json";

export enum ServerMethod {
  GET = "get",
  DELETE = "delete",
  PUT = "put",
  POST = "post",
}

export const isServerError = (error: unknown): error is ServerError => has(error, "isServerError");
