import { render, staticRenderFns } from "./OnBoardingPage.vue?vue&type=template&id=2040344c&scoped=true&"
import script from "./OnBoardingPage.ts?vue&type=script&lang=ts&"
export * from "./OnBoardingPage.ts?vue&type=script&lang=ts&"
import style0 from "./OnboardingPage.scss?vue&type=style&index=0&id=2040344c&prod&lang=scss&scoped=true&"
import style1 from "@dasgate/uikit/lib/index.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2040344c",
  null
  
)

export default component.exports