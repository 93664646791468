import { GetVisitsRequest } from "../models/visits.models";

export enum VisitSearchMutationsType {
  SaveSearch = "saveVisitSearch",
}

export enum VisitSearchActionsType {
  SaveSearch = "saveVisitSearch",
}

export enum VisitSearchGettersType {
  FilterData = "visitFilterData",
  Bookmarks = "visitBookmarks",
}

export interface VisitSearchGetters {
  filterdata: GetVisitsRequest;
  bookmarks: string[];
}

export interface VisitSearchActions {
  saveVisitsSearch(filterdata: GetVisitsRequest, bookmarks: string[]): void;
}
