import Vue from "vue";
import { Auth, AuthRepository, AuthStorage } from "@/core/user/domain/auth";
import { Credentials } from "@/legacy/features/authentication/credentials.models";

export const authRepository = ({ authStorage }: { authStorage: AuthStorage }): AuthRepository => ({
  get: () => authStorage.get(),
  set: (auth: Auth) => {
    authStorage.set(auth);
    setAuthInStore(auth);
  },
  update: (auth: Partial<Auth>) => {
    const currentAuth = authStorage.get();
    const newAuth = Object.assign({}, currentAuth, auth);

    authStorage.set(newAuth);
    setAuthInStore(newAuth);
  },
  unset: () => {
    authStorage.unset();
    Vue.prototype.$appStore.actions.removeCredentials();
  },
});

const setAuthInStore = (auth: Auth) => {
  const credentials: Credentials = {
    access_token: auth.accessToken,
    roles: auth.roles,
    email: auth.email,
    userName: auth.userName,
    profile: auth.profile,
    userId: auth.userId,
    enrolled: auth.enrolled,
  };

  Vue.prototype.$appStore.actions.storeAccessToken(auth.accessToken);
  Vue.prototype.$appStore.actions.storeCredentials(credentials);
  Vue.prototype.$appStore.actions.storeOnboardingState(auth.enrolled);
};
