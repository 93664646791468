import { Component, Vue } from "vue-property-decorator";
import { DgDropdown, DgIcon, DgRow } from "@dasgate/uikit";
import { redirectUser } from "@/legacy/features/common/shared";
import { BaseAssetsUrl } from "@/legacy/models/constants";
import { getTenant } from "@/legacy/services/configuration.service";
import axios from "axios";

@Component({ components: { DgDropdown, DgIcon, DgRow } })
export default class Header extends Vue {
  private availableLocales: string[] = [];

  public async mounted() {
    this.availableLocales = await this.loadLocales();
  }

  public async onLogout() {
    this.$appStore.actions.cleanAll();
    await this.$services.authentication.logout();
  }

  public async onGoHome() {
    this.$appStore.actions.resetOnboarding();
    await redirectUser();
  }

  get currentLocale() {
    return this.$i18n.locale;
  }

  get locales() {
    return this.availableLocales.map((locale: string) => ({
      label: this.$t("common.locales." + locale),
      value: locale,
    }));
  }

  public async changeLocale(locale: string) {
    this.$i18n.locale = locale;
    localStorage.setItem("locale", locale);
    await this.$services.userService.updateLocale({
      userId: this.$appStore.getters.userId,
      locale,
    });
    await this.$services.authentication.silentRenew();
  }

  private async loadLocales() {
    const assetsUrl = `${BaseAssetsUrl}${getTenant()}`;

    return (
      await Promise.all(
        this.$i18n.availableLocales.map(async locale => {
          try {
            await axios.get(`${assetsUrl}/locales/${locale.concat(".json")}`);
            return locale;
          } catch (error) {
            return undefined;
          }
        })
      )
    ).filter(Boolean) as string[];
  }
}
