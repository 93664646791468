import { RegisterMode, UserData, UserRepository } from "../domain/userRepository";
import { ApiClient, apiResponse } from "@/core/shared/api/domain/apiClient";
import { userDataSerializer } from "./userDataSerializer";
import { userDetailDeserializer } from "./userDetailSerializer";

export const apiUserRepository = ({ apiClient }: { apiClient: ApiClient }): UserRepository => ({
  enroll: async (userData: UserData, registerMode: RegisterMode) => {
    await apiClient.post("/internal/onboardings", { userData, registerMode }, { mapRequest: userDataSerializer });
  },
  getUserDetail: async (id: string) => {
    return await apiClient.get(`/public/users/${id}`, { mapResponse: userDetailDeserializer });
  },
  getUserSelfie: async (id: string) => {
    try {
      return await apiClient.get(`/public/users/${id}/selfie`);
    } catch (error) {
      return apiResponse("");
    }
  },
});
