import Vue from "vue";
import axios from "axios";
import { TenantSettings } from "@/legacy/models/settings";

export interface Translation {
  locale: string;
  messages: any;
}

export class LocalizationService extends Vue {
  public static async getTenantLocales(settings: TenantSettings): Promise<Translation[]> {
    const promises: Array<Promise<Translation>> = [];
    const locales = ["es", "en", "eu"];
    locales.forEach(locale => {
      promises.push(this.getTenantLocale(settings.assetsUrl, locale));
    });

    return (await Promise.allSettled(promises))
      .filter(tenantLocalePromise => tenantLocalePromise.status === "fulfilled")
      .map(tenantLocalePromise => (tenantLocalePromise as PromiseFulfilledResult<Translation>).value);
  }

  private static getTenantLocale(assetsUrl: string, locale: string): Promise<Translation> {
    return new Promise<Translation>((resolve, reject) => {
      axios
        .get(`${assetsUrl}/locales/${locale}.json`)
        .then(response => {
          resolve({
            locale,
            messages: response.data,
          });
        })
        .catch(() => {
          reject();
        });
    });
  }
}
