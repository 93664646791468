import {
  DocumentScanOptions,
  DocumentScanService,
  veridasDocumentGroup,
} from "@/legacy/features/onboarding/interfaces/document-scan.service";
import i18n from "@/legacy/infrastructure/i18n";

declare function makeVDDocumentWidget(): any;
declare function destroyVDDocumentWidget(): void;
interface ImageDetectionEvent extends Event {
  detail: string;
}
interface DocumentTypeEvent extends Event {
  detail: string;
}

export class VeridasDocumentScanService implements DocumentScanService {
  public scan(options: DocumentScanOptions): void {
    try {
      const VDDocument = makeVDDocumentWidget();
      const sdkBackgroundColor = "#072B80";
      const veridasOptions: Record<string, any> = {
        targetSelector: options.containerSelector,
        webrtcUnsupportedText: i18n.t("common.onboarding.commonScan.webrtcUnsupportedText").toString(),
        repeatText: i18n.t("common.onboarding.commonScan.repeatText").toString(),
        continueText: i18n.t("common.onboarding.commonScan.continueText").toString(),
        infoUserSliderButtonText: i18n.t("common.onboarding.commonScan.infoUserSliderButtonText").toString(),
        infoAlertTwoSidedDocument: i18n.t("common.onboarding.documentScan.infoAlertTwoSidedDocument").toString(),
        infoAlertSingleSidedDocument: i18n.t("common.onboarding.documentScan.infoAlertSingleSidedDocument").toString(),
        obverseNotFoundText: i18n.t("common.onboarding.documentScan.obverseNotFoundText").toString(),
        reverseNotFoundText: i18n.t("common.onboarding.documentScan.reverseNotFoundText").toString(),
        manualCaptureText: i18n.t("common.onboarding.documentScan.manualCaptureText").toString(),
        manualCaptureTextMobile: i18n.t("common.onboarding.documentScan.manualCaptureTextMobile").toString(),
        infoReviewImageText: i18n.t("common.onboarding.documentScan.infoReviewImageText").toString(),
        infoReviewBlurImageText: i18n.t("common.onboarding.documentScan.infoReviewBlurImageText").toString(),
        tooFarText: i18n.t("common.onboarding.documentScan.tooFarText").toString(),
        confirmationDialogLinkTextColor: "#ffffff",
        confirmationDialogButtonBackgroundColor: options.primaryColor,
        displayErrors: true,
        sdkBackgroundColorInactive: sdkBackgroundColor,
        borderColorCenteringAidInactive: "#ffffff",
        confirmationColorTick: sdkBackgroundColor,
        borderColorCenteringAidDefault: "#ffffff",
        outerGlowCenteringAidDefault: sdkBackgroundColor,
        confirmationDialogBackgroundColor: sdkBackgroundColor,
        detectionMessageBackgroundColor: sdkBackgroundColor,
        outerGlowCenteringAidDetecting: options.primaryColor,
        borderColorCenteringAidDetecting: options.primaryColor,
        borderColorCenteringAidDetectingSuccess: options.primaryColor,
        confirmationCaptureButtonBackgroundColor: options.primaryColor,
        infoAlertShow: false,
        blurDetectorActive: true,
        docLibHelperPath: "/sdk/documents/docLibHelper",
        logEventsToConsole: false,
        showLogo: false,
      };

      if (options.documentType === "passport") {
        veridasOptions.passport = ["XX_Passport_YYYY"];
      } else if (options.documentType === "others") {
        veridasOptions.documentsMinimal = ["XX_XX_XXXX"];
      } else {
        veridasOptions.documents = veridasDocumentGroup(options.documentType, options.documentCountry);
      }

      VDDocument(veridasOptions);

      const ref = options.container;

      ref.addEventListener(
        "VDDocument_mounted",
        () => {
          options.onStart();
        },
        false
      );

      ref.addEventListener(
        "VDDocument_unmounted",
        () => {
          options.onFinish();
        },
        false
      );

      ref.addEventListener(
        "VDDocument_obverseDetection",
        async e => {
          const imageDetectionEvent = e as ImageDetectionEvent;
          options.onObverse(imageDetectionEvent.detail);
        },
        false
      );

      ref.addEventListener("VDDocument_obverseDetection_type", async e => {
        const documentTypeDetected = e as DocumentTypeEvent;
        options.onObverseTypeDetected(documentTypeDetected.detail);
      });

      ref.addEventListener("VDDocument_reverseDetection_type", async e => {
        const documentTypeDetected = e as DocumentTypeEvent;
        options.onReverseTypeDetected(documentTypeDetected.detail);
      });

      ref.addEventListener(
        "VDDocument_reverseDetection",
        async e => {
          const imageDetectionEvent = e as ImageDetectionEvent;
          options.onReverse(imageDetectionEvent.detail);
        },
        false
      );

      ref.addEventListener(
        "VDDocument_mountFailure",
        () => {
          options.onError(true);
        },
        false
      );

      ref.addEventListener(
        "VDDocument_cameraFailure",
        () => {
          options.onError(false);
        },
        false
      );

      ref.addEventListener(
        "VDDocument_detectionTimeout",
        () => {
          options.onError(true);
        },
        false
      );
    } catch {
      options.onError(true);
    }
  }

  public cancel(): void {
    destroyVDDocumentWidget();
  }
}
