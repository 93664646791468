import Vue from "vue";
import { RawLocation, Route } from "vue-router";
import { AuthPermissions } from "@/core/user/domain/auth";
import { RouteNames } from "@/ui/_router";
import {
  getOnboardingRoute,
  getRouteIfEnrollingUser,
  isAnyOfRoutes,
  isBrowserSupported,
  isIdentityPortrayalAvailable,
  isNotValidPcOnboarding,
  isRoute,
} from "./utils";
import { isDefined, isUndefined } from "@/core/shared/utils";
import { startOnboarding } from "@/legacy/features/onboarding/models/onboarding.models";
import { createPortrayal } from "@/legacy/features/onboarding/models/identity-portrayal.models";

type GuardResponse = RawLocation | "login" | undefined;
export type Guard = (to: Route, from: Route, permissions: AuthPermissions) => GuardResponse | Promise<GuardResponse>;

export const myAccreditationsGuard: Guard = (to, from, permissions) => {
  if (!permissions.isEnrolled() || !permissions.canViewAccreditations()) {
    return "login";
  }

  return;
};

export const landingGuard: Guard = (to, from, permissions) => {
  if (permissions.isEnrolled()) {
    return { name: RouteNames.MyAccreditations };
  }

  return;
};

export const uploadWatchlistGuard: Guard = (to, from, permissions) => {
  if (!permissions.canUploadWatchlist()) {
    return "login";
  }

  return;
};

export const visitsSolutionGuard: Guard = (to, from, permissions) => {
  if (isRoute(to, "VisitDetail") && isUndefined(to.params.data)) {
    return { name: RouteNames.MyVisits };
  }

  if (!permissions.isVisitor() && !permissions.isReceptionist()) {
    return "login";
  }

  if (permissions.isVisitor()) {
    return getRouteIfEnrollingUser(to, permissions);
  }

  return;
};

export const userHomeGuard: Guard = (to, from, permissions) => {
  const enrollingUserRoute = getRouteIfEnrollingUser(to, permissions);
  if (isDefined(enrollingUserRoute)) {
    return enrollingUserRoute;
  }

  if (permissions.isVisitor()) {
    return { name: RouteNames.MyVisits };
  }

  return;
};

export const requireAuthenticationGuard: Guard = (to, from, permissions) => {
  if (!isAnyOfRoutes(to, ["Callback", "Silent", "VisitConfirmation"]) && !permissions.isAuthenticated()) {
    return "login";
  }

  return;
};

export const canSeeDeleteAccountGuard: Guard = (to, from, permissions) => {
  if (!permissions.canDeleteAccounts()) {
    return "login";
  }

  return;
};

export const canSeeProfileGuard: Guard = (to, from, permissions) => {
  if (!(permissions.canViewProfile() && permissions.isEnrolled())) {
    return "login";
  }

  return;
};

export const homeRouteRedirectionGuard: Guard = (to, from, permissions) => {
  if (permissions.hasClaims()) {
    if (permissions.isEnrolled()) {
      return { name: RouteNames.MyAccreditations };
    } else {
      return { name: RouteNames.Landing };
    }
  }

  if (!permissions.isUser()) {
    return { name: RouteNames.PortalManagement };
  }

  if (permissions.isEnrolled()) {
    if (permissions.isVisitor()) {
      return { name: RouteNames.MyVisits };
    } else {
      return { name: RouteNames.UserHome };
    }
  }

  if (isNotValidPcOnboarding(from) || !isBrowserSupported()) {
    return { name: RouteNames.DeviceDetect };
  }

  const name = getOnboardingRoute();
  return { name, query: name === RouteNames.RegisterSelfie ? to.query : undefined };
};

export const assistantNeededGuard: Guard = (to, from, permissions) => {
  if (!permissions.isAssistant()) {
    return "login";
  }

  return;
};

export const canSeePortalGuard: Guard = (to, from, permissions) => {
  if (permissions.isUser()) {
    return "login";
  }

  return;
};

export const assistedAndOnboardingIdNeededGuard: Guard = (to, from, permissions) => {
  if (!permissions.isAssistant()) {
    const name = getOnboardingRoute();
    return { name, query: name === RouteNames.RegisterSelfie ? to.query : undefined };
  }

  return;
};

export const batchOnboardingGuard: Guard = (to, from, permissions) => {
  if (!permissions.canViewBatchEnrolment()) {
    return "login";
  }

  return;
};

export const portrayalWithDocumentNeededGuard: Guard = to => {
  if (!isIdentityPortrayalAvailable()) {
    return { name: RouteNames.PrivacyOnboarding };
  }

  if (to.query.process == "accredit") {
    return;
  }

  if (Vue.prototype.$appStore.getters.documentType === "none") {
    if (isRoute(to, "ReviewRegister")) {
      return { name: RouteNames.RegisterForm };
    }

    return { name: RouteNames.RegisterSelfie, query: to.query };
  }

  return;
};

export const identityPortrayalIdNeededGuard: Guard = () => {
  if (!isIdentityPortrayalAvailable()) {
    return { name: RouteNames.PrivacyOnboarding };
  }
  return;
};

export const auditorGuard: Guard = (to, from, permissions) => {
  if (!permissions.isAuditor()) {
    return "login";
  }

  return;
};

export const singleOnboardingGuard: Guard = (to, from, permissions) => {
  if (!permissions.canViewSingleEnrolment()) {
    return "login";
  }

  return;
};

export const userManagerGuard: Guard = (to, from, permissions) => {
  if (!permissions.isUserManager()) {
    return "login";
  }

  return;
};

export const accountManagerGuard: Guard = (to, from, permissions) => {
  if (!permissions.isAccountManager()) {
    return "login";
  }

  return;
};

export const onboardingIdNeededGuard: Guard = async (to, from, permissions) => {
  if (isNotValidPcOnboarding(from) || !isBrowserSupported()) {
    return { name: RouteNames.DeviceDetect };
  }

  if (Vue.prototype.$appStore.getters.onboardingId) {
    return;
  }

  if (!Vue.prototype.$appStore.getters.identityProofing) {
    return { name: RouteNames.RegisterSelfie, query: to.query };
  }

  if (permissions.hasClaims()) {
    return { name: permissions.isEnrolled() ? RouteNames.MyAccreditations : RouteNames.Landing };
  }

  if (permissions.isUser() && !permissions.isEnrolled()) {
    await startOnboarding();
    const userId = Vue.prototype.$appStore.getters.userId;
    await createPortrayal("none", "xx", userId);

    return { name: getOnboardingRoute() };
  }

  if (permissions.isVisitor() && permissions.isEnrolled()) {
    return { name: RouteNames.MyVisits };
  }

  if (permissions.isUser() && permissions.isEnrolled()) {
    return { name: RouteNames.UserHome };
  }

  return { name: RouteNames.PortalManagement };
};

export const registerSelfieGuard: Guard = async (to, from, permissions) => {
  if (isNotValidPcOnboarding(from) || !isBrowserSupported()) {
    return { name: RouteNames.DeviceDetect, query: to.query };
  }

  if (isAnyOfRoutes(from, ["MyAccreditations", "DeviceDetect", "PrivacyOnboarding"])) {
    return;
  }

  if (!isAnyOfRoutes(from, ["Callback", "DocumentScan", "TakeSelfie", "TakeSelfieAlive", "NextSteps"])) {
    if (permissions.hasClaims()) {
      return { name: permissions.isEnrolled() ? RouteNames.MyAccreditations : RouteNames.Landing };
    }

    if (permissions.isUser() && !permissions.isEnrolled()) {
      await startOnboarding();
      if (!Vue.prototype.$appStore.getters.identityProofing) {
        const userId = Vue.prototype.$appStore.getters.userId;
        await createPortrayal("none", "xx", userId);
      }
      return { name: getOnboardingRoute() };
    }
    if (permissions.isVisitor() && permissions.isEnrolled()) {
      return { name: RouteNames.MyVisits };
    }

    if (permissions.isUser() && permissions.isEnrolled()) {
      return { name: RouteNames.UserHome };
    }

    return { name: RouteNames.PortalManagement };
  }

  if (!isIdentityPortrayalAvailable()) {
    return { name: RouteNames.PrivacyOnboarding };
  }

  return;
};
