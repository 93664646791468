import { MutationTree } from "vuex";
import { OnboardingState } from "@/legacy/features/onboarding/store/state";
import { OnboardingMutationsType } from "@/legacy/features/onboarding/store/contract";
import { RegisterMode, RegisterType } from "@/legacy/models/shared";

const mutations: MutationTree<OnboardingState> = {};

mutations[OnboardingMutationsType.StartOnboarding] = (
  state: OnboardingState,
  payload: {
    onboardingId: string;
    registerType: RegisterType;
    lifeProofing: boolean;
    biometricQR: boolean;
    assistedUserId: string;
    assistedEmail: string;
    supportedDocs: { [key: string]: string[] };
  }
) => {
  state.onboardingId = payload.onboardingId;
  state.registerType = payload.registerType;
  state.lifeProofing = payload.lifeProofing;
  state.biometricQR = payload.biometricQR;
  state.assistedUserId = payload.assistedUserId;
  state.assistedEmail = payload.assistedEmail;
  state.supportedDocs = payload.supportedDocs;
};

mutations[OnboardingMutationsType.SetRegisterMode] = (state: OnboardingState, registerMode: RegisterMode) => {
  state.registerMode = registerMode;
};

mutations[OnboardingMutationsType.ResetOnboarding] = (state: OnboardingState) => {
  state.onboardingId = "";
  state.identityPortrayalId = "";
  state.documentType = "none";
  state.registerType = RegisterType.None;
  state.registerMode = "auto";
  state.lifeProofing = false;
  state.biometricQR = false;
  state.assistedUserId = "";
  state.assistedEmail = "";
  state.confirmData = {};
};

mutations[OnboardingMutationsType.StartIdentityPortrayal] = (
  state: OnboardingState,
  payload: {
    identityPortrayalId: string;
    documentType: string;
    documentCountry: string;
  }
) => {
  state.identityPortrayalId = payload.identityPortrayalId;
  state.documentType = payload.documentType;
  state.documentCountry = payload.documentCountry;
};

mutations[OnboardingMutationsType.CancelIdentityPortrayal] = (state: OnboardingState) => {
  state.identityPortrayalId = "";
  state.documentType = "none";
  state.documentCountry = "";
};

mutations[OnboardingMutationsType.SetConfirmData] = (state: OnboardingState, confirmData: any) => {
  state.confirmData = confirmData;
};

export default mutations;
