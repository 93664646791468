import { AuthGetters, AuthGettersType } from "@/legacy/features/authentication/store/contract";
import { OnboardingGetters, OnboardingGettersType } from "@/legacy/features/onboarding/store/contract";
import { Store } from "vuex";
import RootState from "@/legacy/store/rootState";
import { RegisterMode, RegisterType } from "@/legacy/models/shared";
import { SettingsGetters, SettingsGettersType } from "@/legacy/store/settings/contract";
import { TenantSettings } from "@/legacy/models/settings";
import { RootGetters } from "@/legacy/store/enums";
import { UserSearchGetters, UserSearchGettersType } from "@/legacy/features/portal/store/contract";
import { GetUsersRequest } from "@/legacy/features/portal/models/portal.models";
import { GetVisitsRequest } from "@/legacy/features/visits/models/visits.models";
import { VisitSearchGettersType } from "@/legacy/features/visits/store/contract";

export default class AppGetters implements AuthGetters, OnboardingGetters, SettingsGetters, UserSearchGetters {
  constructor(private readonly store: Store<RootState>) {}

  public get userName(): string {
    return this.store.getters[AuthGettersType.UserName];
  }

  public get email(): string {
    return this.store.getters[AuthGettersType.Email];
  }

  public get userId(): string {
    return this.store.getters[AuthGettersType.UserId];
  }

  public get userEnrolled(): boolean {
    return this.store.getters[AuthGettersType.Enrolled];
  }

  public get assistedUserId(): string {
    return this.store.getters[OnboardingGettersType.AssistedUserId];
  }

  public get assistedEmail(): string {
    return this.store.getters[OnboardingGettersType.AssistedEmail];
  }

  public get onboardingId(): string {
    return this.store.getters[OnboardingGettersType.OnboardingId];
  }

  public get supportedDocs(): { [key: string]: string[] } {
    return this.store.getters[OnboardingGettersType.SupportedDocs];
  }

  public get identityPortrayalId(): string {
    return this.store.getters[OnboardingGettersType.IdentityPortrayalId];
  }

  public get documentType(): string {
    return this.store.getters[OnboardingGettersType.DocumentType];
  }

  public get documentCountry(): string {
    return this.store.getters[OnboardingGettersType.DocumentCountry];
  }

  public get isAuthenticated(): boolean {
    return this.store.getters[AuthGettersType.IsAuthenticated];
  }

  public get accessToken(): string {
    return this.store.getters[AuthGettersType.AccessToken];
  }

  public get refreshToken(): string {
    return this.store.getters[AuthGettersType.RefreshToken];
  }

  public get roles(): string[] {
    return this.store.getters[AuthGettersType.Roles];
  }

  public get isUser(): boolean {
    return this.store.getters[AuthGettersType.IsUser];
  }

  public get isAssistant(): boolean {
    return this.store.getters[AuthGettersType.IsAssistant];
  }

  public get canEnforceInExternalSystems(): boolean {
    return this.store.getters[AuthGettersType.CanEnforceInExternalSystems];
  }

  public get isUserManager(): boolean {
    return this.store.getters[AuthGettersType.IsUserManager];
  }

  public get isAccountManager(): boolean {
    return this.store.getters[AuthGettersType.IsAccountManager];
  }

  public get canAddSingles(): boolean {
    return this.store.getters[AuthGettersType.CanAddSingles];
  }

  public get canAddBatches(): boolean {
    return this.store.getters[AuthGettersType.CanAddBatches];
  }

  public get canAddLocation(): boolean {
    return this.store.getters[AuthGettersType.CanAddLocation];
  }

  public get canDeleteAccountsOne(): boolean {
    return this.store.getters[AuthGettersType.CanDeleteAccountsOne];
  }

  public get canDeleteAccountMe(): boolean {
    return this.store.getters[AuthGettersType.CanDeleteAccountMe];
  }

  public get canDeleteAllUsers(): boolean {
    return this.store.getters[AuthGettersType.CanDeleteAllUsers];
  }

  public get canDeactivateUser(): boolean {
    return this.store.getters[AuthGettersType.CanDeactivateUser];
  }

  public get canDeactivateAllUsers(): boolean {
    return this.store.getters[AuthGettersType.CanDeactivateAllUsers];
  }

  public get canSeeProfile(): boolean {
    return this.store.getters[AuthGettersType.CanSeeProfile];
  }

  public get canGetAllData(): boolean {
    return this.store.getters[AuthGettersType.CanGetAllData];
  }

  public get canResetCredentials(): boolean {
    return this.store.getters[AuthGettersType.CanResetCredentials];
  }

  public get canUploadWatchlists(): boolean {
    return this.store.getters[AuthGettersType.CanUploadWatchlists];
  }

  public get isVisitor(): boolean {
    return this.store.getters[AuthGettersType.IsVisitor];
  }

  public get isReceptionist(): boolean {
    return this.store.getters[AuthGettersType.IsReceptionist];
  }

  public get isAuditor(): boolean {
    return this.store.getters[AuthGettersType.IsAuditor];
  }

  public get userLocation(): string {
    return this.store.getters[AuthGettersType.UserLocation];
  }

  public get settings(): TenantSettings {
    return this.store.getters[SettingsGettersType.GetSettings];
  }

  public get registerType(): RegisterType {
    return this.store.getters[OnboardingGettersType.GetRegisterType];
  }

  public get registerMode(): RegisterMode {
    return this.store.getters[OnboardingGettersType.GetRegisterMode];
  }

  public get lifeProofing(): boolean {
    return this.store.getters[OnboardingGettersType.LifeProofing];
  }

  public get biometricQR(): boolean {
    return this.store.getters[OnboardingGettersType.BiometricQR];
  }

  public get identityProofing(): boolean {
    return this.store.getters[OnboardingGettersType.IdentityProofing];
  }

  public get showUsers(): boolean {
    return true;
  }

  public get loading(): boolean {
    return this.store.getters[RootGetters.GetRequestStatus];
  }

  public get redirect(): string {
    return this.store.getters[RootGetters.GetRedirect];
  }

  public get filterdata(): GetUsersRequest {
    return this.store.getters[UserSearchGettersType.FilterData];
  }

  public get bookmarks(): string[] {
    return this.store.getters[UserSearchGettersType.Bookmarks];
  }

  public get visitsFilterdata(): GetVisitsRequest {
    return this.store.getters[VisitSearchGettersType.FilterData];
  }

  public get visitsBookmarks(): string[] {
    return this.store.getters[VisitSearchGettersType.Bookmarks];
  }

  public get getConfirmData(): any {
    return this.store.getters[OnboardingGettersType.GetConfirmData];
  }
}
