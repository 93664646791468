import Vue from "vue";
import { AuthActions, AuthActionsType } from "@/legacy/features/authentication/store/contract";
import { OnboardingActions, OnboardingActionsType } from "@/legacy/features/onboarding/store/contract";
import { Store } from "vuex";
import RootState from "@/legacy/store/rootState";
import { Credentials } from "@/legacy/features/authentication/credentials.models";
import { RegisterMode, RegisterType } from "@/legacy/models/shared";
import { SettingsActions, SettingsActionsType } from "@/legacy/store/settings/contract";
import { TenantSettings } from "@/legacy/models/settings";
import { RootActions } from "@/legacy/store/enums";
import { UserSearchActions, UserSearchActionsType } from "@/legacy/features/portal/store/contract";
import { GetUsersRequest } from "@/legacy/features/portal/models/portal.models";
import { GetVisitsRequest } from "@/legacy/features/visits/models/visits.models";
import { VisitSearchActionsType } from "@/legacy/features/visits/store/contract";

export default class AppActions implements AuthActions, OnboardingActions, SettingsActions, UserSearchActions {
  constructor(private readonly store: Store<RootState>) {}

  public cleanAll() {
    Vue.prototype.$container.authRepository.unset();
    this.resetOnboarding();
    this.cancelIdentityPortrayal();
  }

  public storeAccessToken(accesToken: string) {
    this.store.dispatch(AuthActionsType.AccessTokenStored, accesToken);
  }

  public storeCredentials(credentials: Credentials) {
    this.store.dispatch(AuthActionsType.CredentialsStored, credentials);
  }

  public storeOnboardingState(enrolled: boolean) {
    this.store.dispatch(AuthActionsType.OnboardingStateStored, enrolled);
  }

  public removeCredentials() {
    this.store.dispatch(AuthActionsType.CredentialsRemoved);
  }

  public startOnboarding(
    onboardingId: string,
    registerType: RegisterType,
    lifeProofing: boolean,
    biometricQR: boolean,
    supportedDocs: { [key: string]: string[] },
    assistedUserId?: string,
    assistedEmail?: string
  ) {
    this.store.dispatch(OnboardingActionsType.StartOnboarding, {
      onboardingId,
      registerType,
      lifeProofing,
      biometricQR,
      supportedDocs,
      assistedUserId,
      assistedEmail,
    });
  }

  public setRegisterMode(registerMode: RegisterMode) {
    this.store.dispatch(OnboardingActionsType.SetRegisterMode, registerMode);
  }

  public setConfirmData(confirmData: any) {
    this.store.dispatch(OnboardingActionsType.SetConfirmData, confirmData);
  }

  public resetOnboarding() {
    this.store.dispatch(OnboardingActionsType.ResetOnboarding);
  }

  public startIdentityPortrayal(payload: {
    identityPortrayalId: string;
    documentType: string;
    documentCountry: string;
  }) {
    this.store.dispatch(OnboardingActionsType.StartIdentityPortrayal, payload);
  }

  public cancelIdentityPortrayal() {
    this.store.dispatch(OnboardingActionsType.CancelIdentityPortrayal);
  }

  public setSettings(settings: TenantSettings) {
    this.store.dispatch(SettingsActionsType.SetSettings, settings);
  }

  public setRequestLoadingState(loadingState: boolean) {
    this.store.dispatch(RootActions.SetRequestLoading, loadingState);
  }

  public setRedirect(redirect: string) {
    this.store.dispatch(RootActions.SetRedirect, redirect);
  }

  public saveUserSearch(filterdata: GetUsersRequest, bookmarks: string[]): void {
    this.store.dispatch(UserSearchActionsType.SaveSearch, { filterdata, bookmarks });
  }

  public saveVisitSearch(filterdata: GetVisitsRequest, bookmarks: string[]): void {
    this.store.dispatch(VisitSearchActionsType.SaveSearch, { filterdata, bookmarks });
  }
}
