import { BrowserSetting, BrowserSettings, Settings, TenantSettings } from "@/legacy/models/settings";
import axios, { AxiosRequestConfig } from "axios";
import { BaseAssetsUrl } from "@/legacy/models/constants";

export class ConfigurationService {
  public async getConfig(): Promise<TenantSettings> {
    const tenant = getTenant();
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-store",
      },
    };
    const assetsUrl = `${BaseAssetsUrl}${tenant}`;
    const response = await axios.get<Settings>(`${assetsUrl}/settings.json`, config);
    const settings = {
      ...response.data,
      tenant,
      assetsUrl,
    };

    if (!settings.acsIdSchema) {
      settings.acsIdSchema = "string64";
    }

    return settings;
  }

  public async getBrowserSettings(): Promise<BrowserSettings> {
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-store",
      },
    };

    const response = await axios.get<BrowserSetting[]>(`${BaseAssetsUrl}browsers.json`, config);
    const browserSettings = {} as BrowserSettings;
    response.data.forEach(browser => {
      browserSettings[browser.name] = browser;
    });
    return browserSettings;
  }
}

export const getTenant = (): string => {
  let tenant = "gambling";
  const subdir = window.location.host.split(".")[0];
  if (subdir !== "app" && subdir !== "localhost:8080") {
    tenant = subdir;
  }

  return tenant;
};
