import Vue from "vue";
import { PortrayalStorage } from "@/core/portrayal/domain/portrayalStorage";
import { StartedPortrayal } from "@/core/portrayal/domain/portrayalRepository";
import { isDefined } from "@/core/shared/utils";

export const appStorePortrayalStorage: PortrayalStorage = {
  get: () => ({
    portrayalId: Vue.prototype.$appStore.getters.identityPortrayalId,
    lifeProofing: Vue.prototype.$appStore.getters.lifeProofing,
  }),
  set: (portrayal: StartedPortrayal) => {
    Vue.prototype.$appStore.actions.startIdentityPortrayal({
      identityPortrayalId: portrayal.portrayalId,
      documentType: Vue.prototype.$appStore.getters.documentType,
      documentCountry: Vue.prototype.$appStore.getters.documentCountry,
    });
    if (isDefined(portrayal.lifeProofing)) {
      Vue.prototype.$appStore.actions.startOnboarding(
        Vue.prototype.$appStore.getters.onboardingId,
        Vue.prototype.$appStore.getters.registerType,
        portrayal.lifeProofing,
        Vue.prototype.$appStore.getters.biometricQR,
        Vue.prototype.$appStore.getters.supportedDocs,
        Vue.prototype.$appStore.getters.assistedUserId,
        Vue.prototype.$appStore.getters.assistedEmail
      );
    }
  },
  clear: () => {
    Vue.prototype.$appStore.actions.cancelIdentityPortrayal();
  },
};
