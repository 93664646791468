import { asFunction, asValue } from "@/_di/resolvers";
import { ApiClient } from "@/core/shared/api/domain/apiClient";
import { apiClient } from "@/core/shared/api/infrastucture/apiClient";
import { BaseUrl } from "@/legacy/models/constants";
import { getTenant } from "@/legacy/services/configuration.service";
import { Config } from "@/core/shared/config/domain/config";
import { Server } from "@/core/shared/api/domain/server";
import { axiosServer } from "@/core/shared/api/infrastucture/axiosServer";

export const sharedModule = {
  apiClient: asFunction<ApiClient>(apiClient),
  server: asFunction<Server>(axiosServer),
  config: asValue<Config>({ baseURL: BaseUrl!, tenant: getTenant() }),
  storage: asValue<Storage>(localStorage),
};
